import { ExpandLess, ExpandMore } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { AxiosResponse } from "axios";
import _ from "lodash";
import Papa from "papaparse";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AvatarSize, space } from "../Config/theme";
import { LinkedinIndustry } from "../Containers/KeywordMonitoringGroupFilters";
import StyledButton from "../design/components/StyledButton";
import StyledChipInput from "../design/components/StyledChipInput";
import StyledInput from "../design/components/StyledInput";
import StyledInputLabel from "../design/components/StyledInputLabel";
import Api from "../Services/Api";
import {
  LINKEDIN_SENIORITY_OPTIONS,
  ABM_PAGES_PER_DAY_LIMIT,
  Publication
} from "../Utils/Types";
import BetaText from "./BetaText";
import { notify } from "./CustomNotifications";
import Loading from "./Loading";
import styles from "./styles/AccountMonitoringStyle";
import Uploader from "./Uploader";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import StyledPagination from "../design/components/StyledPagination";
import {
  formatNumber,
  handleSingular,
  trimTrailingSlash
} from "../Utils/UserUtils";
import { uploadCSVFile } from "../Utils/Storage";

const api = Api.create();

const INDIVIDUAL_MONITORING_CSV_TEMPLATE = `https://docs.google.com/spreadsheets/d/1x1v6di_y0-csPdUFUwdQpo0JpkUpBRklV8GwyB2jNXc/edit?gid=0#gid=0`;
const ACCOUNT_MONITORING_CSV_TEMPLATE = `https://docs.google.com/spreadsheets/d/1yzIOGxdVVkOm5doCnn3vdgsX65IgY7VoJxC0wpNT4Eo/edit?gid=0#gid=0`;

const monitoringTypes = {
  ACCOUNT: "account",
  INDIVIDUAL: "individual",
  URL: "url"
} as const;

type AccountMonitoringProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
  publication: Publication | null;
} & RouteComponentProps<{ id: string }>;

type AccountMonitoringState = {
  loading: boolean;
  saveInProgress: boolean;
  companies: Companies;
  showGenerateJobTitleButton: boolean;
  generateJobTitleInputText: string;
  generateJobTitleLoading: boolean;
  jobTitle: string[];
  accounts: LinkedinAccount[];
  selectedAccounts: LinkedinAccount[];
  seniorities: Seniority[];
  industries: LinkedinIndustry[];
  geographies: Geography[];
  allLocations: Geography[];
  geographySearch: string;
  industryOptions: LinkedinIndustry[];
  selectedType: (typeof monitoringTypes)[keyof typeof monitoringTypes];
  page: number;
  rowsPerPage: number;
  uploadedAccounts: MonitoredAccounts[];
  totalDocs: number;
  geographyError: string;
  jobTitleError: string;
  seniorityError: string;
  accountsError: string;
  individualAccountMonitoringLimit: number;
  processedCompanyDetails: null | {
    totalCompanies: number;
    completedCompanies: number;
  };
  processedPagesByUrl: null | {
    totalPages: number;
    completedPages: number;
  };
  csvFileUrl: string;
  uploadingCsvFile: boolean;
  searchURL: string;
  searchURLError: string;
};

interface MonitoredAccounts {
  id: string;
  companyName: string;
  owner: string;
  isPersonalAccountMonitoring: boolean;
  name: string;
  vanityName: string;
}

const JOB_TITLE_LIMIT = 40;
const COMPANIES_LIMIT_PER_ACCOUNT = 30 * 10; // Each day we can build 30 companies, so limit accounts to be build under 10 days
const DEFAULT_PERSONAL_ACCOUNT_LIMIT = 5000;

export type Geography = {
  id: string;
  name: string;
};

export type Seniority = {
  name: string;
  value: string;
};

export type Companies = {
  [key: string]: string[];
};

export type LinkedinAccount = {
  connected: boolean;
  name: string;
  userId: string;
  vanityName: string;
  profilePicture: string;
  email: string;
  accountEmails: string;
  chromeExtension?: {
    installed: boolean;
    isScrapingEnabled: boolean;
  };
};

export const USE_DEFAULTS_INFO_TEXT = (
  <>
    You can change defaults in
    <br />
    Settings &gt; Knowledge Base &gt; Buyer Filters
  </>
);

export const JOB_TITLE_INFO_TEXT = `This is a contains operation. Eg: Adding "engineering" will match "VP of Engineering" and "Software Engineering Intern"`;

class AccountMonitoring extends React.Component<
  AccountMonitoringProps,
  AccountMonitoringState
> {
  searchLocationsDebounce: any;
  fileInputRef: any;
  getProcessedAccountFromMonitoringDebounce: any;

  constructor(props: AccountMonitoringProps) {
    super(props);
    this.state = {
      loading: true,
      saveInProgress: false,
      accounts: [],
      selectedAccounts: [],
      companies: {},
      showGenerateJobTitleButton: false,
      generateJobTitleInputText: "",
      generateJobTitleLoading: false,
      jobTitle: [],
      seniorities: [],
      industries: [],
      geographies: [],
      allLocations: [],
      geographySearch: "",
      industryOptions: [],
      selectedType: monitoringTypes.ACCOUNT,
      page: 0,
      rowsPerPage: 10,
      uploadedAccounts: [],
      totalDocs: 0,
      geographyError: "",
      jobTitleError: "",
      seniorityError: "",
      accountsError: "",
      individualAccountMonitoringLimit: DEFAULT_PERSONAL_ACCOUNT_LIMIT,
      processedCompanyDetails: null,
      processedPagesByUrl: null,
      csvFileUrl: "",
      uploadingCsvFile: false,
      searchURL: "",
      searchURLError: ""
    };

    this.searchLocationsDebounce = null;
    this.getProcessedAccountFromMonitoringDebounce = null;
  }

  componentDidMount(): void {
    this.getAccountMonitoringDetails();
    this.getAllLinkedinIndustries();
    this.getUploadedAccountFromAccountMonitoring();

    this.searchLocationsDebounce = _.debounce(this.searchLocations, 1000);
    this.getProcessedAccountFromMonitoringDebounce = _.debounce(
      this.getUploadedAccountFromAccountMonitoring,
      1000
    );
  }

  getAccountMonitoringDetails = () => {
    let { publication } = this.props;

    api.getAccountMonitoringDetails(
      publication?._id || "",
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            individualAccountMonitoringLimit:
              res.data?.individualAccountMonitoringLimit ??
              DEFAULT_PERSONAL_ACCOUNT_LIMIT,
            processedCompanyDetails: res.data?.processedCompanyDetails,
            processedPagesByUrl: res.data?.processedPagesByUrl,
            accounts:
              res.data?.linkedinAccounts?.filter(
                (account: LinkedinAccount) =>
                  account.connected && account.vanityName
              ) || []
          });
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  getUploadedAccountFromAccountMonitoring = () => {
    let { page, rowsPerPage } = this.state;
    let { publication } = this.props;

    api.getUploadedAccountFromAccountMonitoring(
      publication?._id || "",
      rowsPerPage,
      page * rowsPerPage,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            uploadedAccounts: res.data.docs || [],
            totalDocs: res.data.totalDocs || 0
          });
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, we were not able to fetch account activities.",
            "error"
          );
        }
      }
    );
  };

  onFileChange = async (file: any) => {
    let { accounts, selectedType, individualAccountMonitoringLimit } =
      this.state;

    const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 2MB Limit for thumbnail image
    if (file.size > FILE_SIZE_LIMIT) {
      notify.show("Upload a CSV file that's smaller than 10 MB", "error");
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        if (!results.data?.length) {
          notify.show("CSV file is empty", "error");
          return;
        }

        let data = results.data;

        if (
          (selectedType === monitoringTypes.ACCOUNT &&
            !data[0].hasOwnProperty("company")) ||
          (selectedType === monitoringTypes.INDIVIDUAL &&
            !data[0].hasOwnProperty("person")) ||
          !data[0].hasOwnProperty("owner email")
        ) {
          let message =
            selectedType === monitoringTypes.INDIVIDUAL
              ? `Missing required column: ${
                  !data[0].hasOwnProperty("person") ? "person" : "owner email"
                }`
              : `Missing required column: ${
                  !data[0].hasOwnProperty("company") ? "company" : "owner email"
                }`;

          notify.show(message, "error");
          return;
        }

        if (
          selectedType === monitoringTypes.INDIVIDUAL &&
          data.length > individualAccountMonitoringLimit
        ) {
          data.length = individualAccountMonitoringLimit;
        }

        let companies: Companies = {};

        for (let { company, person, "owner email": email } of data) {
          if (
            selectedType === monitoringTypes.ACCOUNT &&
            (!company || company?.includes("linkedin.com/in/"))
          ) {
            continue;
          }

          if (
            selectedType === monitoringTypes.INDIVIDUAL &&
            (!person || !person?.includes("linkedin.com/in/"))
          ) {
            continue;
          }

          if (!email?.trim()) {
            notify.show(
              `Missing email for account ${
                selectedType === monitoringTypes.INDIVIDUAL ? person : company
              }`,
              "error"
            );
            return;
          }

          companies[email] = companies[email] || [];
          companies[email].push(
            selectedType === monitoringTypes.INDIVIDUAL ? person : company
          );
        }

        // remove duplicates
        Object.keys(companies).map((key) => {
          companies[key] = (companies[key] || []).filter(
            (value, index, self) => self.indexOf(value) === index
          );
        });

        if (!this.getCompaniesCount(companies)) {
          notify.show("No accounts found in the CSV file", "error");
          return;
        }

        let connectedAccountsEmails =
          accounts
            ?.map((account) => {
              return account.accountEmails || [];
            })
            ?.flat() || [];

        let invalidEmails = [];

        for (const email of Object.keys(companies)) {
          if (!connectedAccountsEmails.includes(email)) {
            invalidEmails.push(email);
          }
        }

        if (invalidEmails.length) {
          notify.show(
            `The following emails are not connected to any LinkedIn account: ${invalidEmails.join(
              ", "
            )}`,
            "error"
          );
          return;
        }

        this.setState(
          {
            companies,
            uploadingCsvFile: true
          },
          () => this.uploadAccountListCSVFile(file)
        );
      },
      error: (error) => {
        console.log(error);
      }
    });
  };

  uploadAccountListCSVFile = async (file: any) => {
    let { selectedType } = this.state;
    let { publication } = this.props;

    let response = await uploadCSVFile(
      file,
      "accounts",
      `ABM/${publication?._id ? `${publication?._id}/` : ""}${
        selectedType === monitoringTypes.INDIVIDUAL ? "individual" : "company"
      }`
    );

    this.setState({
      csvFileUrl: response?.url || "",
      uploadingCsvFile: false
    });
  };

  isValidSearchUrl = (url: string): boolean => {
    try {
      let { hostname, pathname } = new URL(url);
      pathname = trimTrailingSlash(pathname);

      return (
        hostname === "www.linkedin.com" && pathname === "/sales/search/people"
      );
    } catch {
      return false;
    }
  };

  saveAccountsForMonitoring = () => {
    let {
      selectedAccounts,
      companies,
      jobTitle,
      saveInProgress,
      seniorities,
      industries,
      geographies,
      selectedType,
      csvFileUrl,
      uploadingCsvFile,
      searchURL
    } = this.state;
    let { publication } = this.props;

    if (saveInProgress) {
      return;
    }

    let companiesCount = this.getCompaniesCount(companies);

    if (!companiesCount && selectedType !== monitoringTypes.URL) {
      notify.show(
        `Upload ${
          selectedType === monitoringTypes.ACCOUNT
            ? "company accounts"
            : "individual accounts"
        }`,
        "error"
      );
      return;
    }

    if (selectedType === monitoringTypes.URL) {
      let accountsError = !selectedAccounts.length
        ? "Select LinkedIn account"
        : "";
      let searchURLError = !this.isValidSearchUrl(searchURL)
        ? "Invalid LinkedIn SalesNav URL"
        : "";

      this.setState({
        accountsError,
        searchURLError
      });

      if (accountsError || searchURLError) {
        return;
      }
    }

    if (uploadingCsvFile) {
      notify.show("Please wait for the CSV file to upload", "error");
      return;
    }

    if (selectedType === monitoringTypes.ACCOUNT) {
      let geographyError = "";
      let jobTitleError = "";
      let seniorityError = "";
      let accountsError = "";

      if (!geographies.length) {
        geographyError = "at least one geography";
      }

      if (!jobTitle.length) {
        jobTitleError = "at least one keyword in job title";
      }

      if (!seniorities.length) {
        seniorityError = "at least one seniority";
      }

      if (
        selectedType === monitoringTypes.ACCOUNT &&
        !selectedAccounts.length
      ) {
        accountsError = "at least one LinkedIn account";
      }

      if (geographyError || jobTitleError || seniorityError || accountsError) {
        this.setState({
          geographyError,
          jobTitleError,
          seniorityError,
          accountsError
        });
        return;
      }

      let allowedCompaniesCount =
        selectedAccounts.length * COMPANIES_LIMIT_PER_ACCOUNT;

      if (companiesCount > allowedCompaniesCount) {
        let removeCount = companiesCount - allowedCompaniesCount;
        let companiesLength = Object.keys(companies).length;

        // remove companies from the end
        for (let index = companiesLength - 1; index >= 0; index--) {
          try {
            if (removeCount <= 0) {
              break;
            }

            let email = Object.keys(companies)[index];
            let companiesChunk = companies[email];
            let chunkLength = companiesChunk.length;

            companiesChunk = companiesChunk.slice(0, -removeCount);
            companies[email] = companiesChunk;

            if (!companies[email].length) {
              delete companies[email];
            }

            removeCount -= chunkLength;
          } catch (error) {}
        }
      }
    }

    this.setState({ saveInProgress: true }, () => {
      api.saveAccountsForMonitoring(
        publication?._id || "",
        selectedAccounts?.map((account) => account.vanityName) || [],
        companies,
        jobTitle,
        seniorities,
        industries,
        geographies,
        selectedType,
        csvFileUrl,
        searchURL,
        (res: AxiosResponse) => {
          if (res.status === 200) {
            this.setState(
              {
                saveInProgress: false,
                companies: {},
                geographies: [],
                seniorities: [],
                industries: [],
                selectedAccounts: [],
                jobTitle: [],
                page: 0,
                searchURL: ""
              },
              this.getProcessedAccountFromMonitoringDebounce
            );

            notify.show("Accounts saved successfully", "success");
          } else {
            notify.show(
              res.status === 400 ? res.data : "Oops, something went wrong.",
              "error"
            );
            this.setState({
              saveInProgress: false
            });
          }
        }
      );
    });
  };

  searchLocations = (location: string) => {
    if (!location) {
      return;
    }

    let { publication } = this.props;
    let { accounts, selectedAccounts } = this.state;

    let account =
      selectedAccounts?.[0]?.vanityName || accounts?.[0]?.vanityName;
    if (!account) {
      return;
    }

    api.searchLinkedInLocation(
      publication?._id || "",
      account,
      location,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            allLocations: res.data.locations || []
          });
        } else {
          this.setState({
            allLocations: []
          });
        }
      }
    );
  };

  getCompaniesCount = (companies: Companies) => {
    let companiesCount = 0;

    Object.keys(companies || {}).forEach((key) => {
      companiesCount += companies[key]?.length || 0;
    });

    return companiesCount;
  };

  getCompaniesAndRolesLabel = () => {
    let { companies, selectedType } = this.state;

    let companiesCount = this.getCompaniesCount(companies) || 0;
    if (!companiesCount) {
      return "";
    }

    let typeText =
      selectedType === monitoringTypes.INDIVIDUAL
        ? "individual account"
        : "company";

    if (companiesCount !== 1) {
      typeText = typeText =
        selectedType === monitoringTypes.INDIVIDUAL
          ? "individual accounts"
          : "companies";
    }

    return (
      <Typography
        variant="bodym"
        paragraph
        style={{
          margin: "0 10px",
          marginTop: space.XXS,
          display: "flex",
          alignItems: "center",
          gap: space.XXS
        }}
      >
        <b>{companiesCount}</b> {typeText}
      </Typography>
    );
  };

  getAccountMonitoringTitle = () => {
    let {
      selectedType,
      individualAccountMonitoringLimit,
      processedCompanyDetails,
      processedPagesByUrl
    } = this.state;

    if (selectedType === monitoringTypes.INDIVIDUAL) {
      return (
        <>
          <Typography
            variant="h400"
            paragraph
            style={{ marginBottom: space.XS }}
          >
            Upload Individual Profiles
          </Typography>
          <Typography variant="bodym" paragraph>
            Upload your target individuals. Here is a{" "}
            <a
              href={INDIVIDUAL_MONITORING_CSV_TEMPLATE}
              target="_blank"
              rel="no-referrer"
            >
              CSV template
            </a>{" "}
            you can copy. Limit of{" "}
            {formatNumber(individualAccountMonitoringLimit)} individuals.
          </Typography>
        </>
      );
    }

    if (selectedType === monitoringTypes.URL) {
      let completedDays = Math.ceil(
        (processedPagesByUrl?.completedPages || 0) / ABM_PAGES_PER_DAY_LIMIT
      );
      let totalDaysToComplete = Math.ceil(
        (processedPagesByUrl?.totalPages || 0) / ABM_PAGES_PER_DAY_LIMIT
      );

      return (
        <>
          <Typography
            variant="h400"
            paragraph
            style={{ marginBottom: space.XS }}
          >
            Enter URL
          </Typography>
          <Typography variant="bodym" paragraph>
            Enter your search URL to build a list
          </Typography>

          {Boolean(processedPagesByUrl?.totalPages) && (
            <Typography variant="bodym" paragraph>
              This list will take <b>{totalDaysToComplete}</b> day
              {handleSingular(totalDaysToComplete)} to build{" "}
              <b>
                {completedDays}/{totalDaysToComplete}
              </b>{" "}
              day{handleSingular(completedDays)} complete.
            </Typography>
          )}
        </>
      );
    }

    return (
      <>
        <Typography variant="h400" paragraph style={{ marginBottom: space.XS }}>
          Upload Company Accounts
        </Typography>
        <Typography
          variant="bodym"
          paragraph
          style={{ marginBottom: space.XS }}
        >
          Upload your target company accounts. Here is a{" "}
          <a
            href={ACCOUNT_MONITORING_CSV_TEMPLATE}
            target="_blank"
            rel="no-referrer"
          >
            CSV template
          </a>{" "}
          you can copy.
        </Typography>
        {Boolean(processedCompanyDetails?.totalCompanies) && (
          <Typography variant="bodym" paragraph>
            We've built the list for{" "}
            <b>
              {formatNumber(processedCompanyDetails?.completedCompanies || 0)}/
              {formatNumber(processedCompanyDetails?.totalCompanies)}
            </b>{" "}
            companies so far.
          </Typography>
        )}
      </>
    );
  };

  changeMonitorType = (
    value: (typeof monitoringTypes)[keyof typeof monitoringTypes]
  ) => {
    let { selectedType } = this.state;

    if (selectedType === value) {
      return;
    }

    this.setState({ selectedType: value, companies: {} });
  };

  resetGenerateJobTitle = () => {
    this.setState({
      showGenerateJobTitleButton: false,
      generateJobTitleInputText: "",
      generateJobTitleLoading: false
    });
  };

  suggestBuyerTitles = () => {
    const { generateJobTitleInputText } = this.state;
    const { publication } = this.props;

    this.setState({ generateJobTitleLoading: true });
    api.suggestBuyerTitles(
      publication?._id || "",
      generateJobTitleInputText,
      (res: AxiosResponse) => {
        let suggestedTitles = res.data?.suggestedTitles || [];
        if (!suggestedTitles.length) {
          suggestedTitles = [generateJobTitleInputText];
        }
        this.setState({
          generateJobTitleInputText: "",
          generateJobTitleLoading: false,
          jobTitle: [...this.state.jobTitle, ...suggestedTitles]
        });
      }
    );
  };

  getAllLinkedinIndustries = () => {
    api.getAllLinkedinIndustries((res: AxiosResponse) => {
      const industryOptions = res.data?.industries;
      if (industryOptions?.length) {
        this.setState({ industryOptions });
      }
    });
  };

  onPageChange = (_: unknown, newPage: number) => {
    this.setState(
      { page: newPage },
      this.getProcessedAccountFromMonitoringDebounce
    );
  };

  onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      { page: 0, rowsPerPage: parseInt(event.target.value) },
      this.getProcessedAccountFromMonitoringDebounce
    );
  };

  getTableColumn = () => {
    let {} = this.state;

    const cols: GridColDef<MonitoredAccounts>[] = [
      {
        field: "companyName",
        headerName: "Company",
        flex: 1,
        headerAlign: "left",
        align: "left",
        sortable: false,
        resizable: true,
        renderCell: (params: any) => {
          let row = params.row;

          if (row.isPersonalAccountMonitoring) {
            return (
              <a href={row.companyName} target="_blank" rel="noreferrer">
                <Typography variant="bodym">
                  {row.name || row.vanityName || row.companyName}
                </Typography>
              </a>
            );
          }

          return <Typography variant="bodym">{params.value}</Typography>;
        }
      },
      {
        field: "owner",
        headerName: "Owner",
        flex: 2,
        headerAlign: "left",
        align: "left",
        sortable: false,
        resizable: true,
        renderCell: (params: any) => {
          return <Typography variant="bodym">{params.value}</Typography>;
        }
      }
    ];

    return cols;
  };

  render() {
    let { classes } = this.props;
    let {
      loading,
      selectedAccounts,
      accounts,
      saveInProgress,
      selectedType,
      allLocations,
      seniorities,
      industries,
      showGenerateJobTitleButton,
      generateJobTitleInputText,
      generateJobTitleLoading,
      jobTitle,
      geographies,
      geographySearch,
      industryOptions,
      rowsPerPage,
      page,
      totalDocs,
      uploadedAccounts,
      geographyError,
      jobTitleError,
      seniorityError,
      accountsError,
      searchURL,
      searchURLError
    } = this.state;

    if (loading) {
      return <Loading marginTop={space.L} />;
    }

    return (
      <Box className={classes.container}>
        <Typography variant="h400" paragraph>
          Monitor and Engage with Target Accounts
          <BetaText />
        </Typography>
        <Typography variant="bodym" paragraph>
          Upload target accounts and sellers who own them. When they post,
          engage, or talk about a topic, the account owner will be notified so
          they can engage and nurture.
        </Typography>

        <ToggleButtonGroup size="large" value={selectedType}>
          <ToggleButton
            onClick={() => this.changeMonitorType(monitoringTypes.ACCOUNT)}
            size="medium"
            value={monitoringTypes.ACCOUNT}
          >
            By Account
          </ToggleButton>
          <ToggleButton
            onClick={() => this.changeMonitorType(monitoringTypes.INDIVIDUAL)}
            size="medium"
            value={monitoringTypes.INDIVIDUAL}
          >
            By Individual
          </ToggleButton>
          <ToggleButton
            onClick={() => this.changeMonitorType(monitoringTypes.URL)}
            size="medium"
            value={monitoringTypes.URL}
          >
            By URL
          </ToggleButton>
        </ToggleButtonGroup>
        <Divider style={{ marginTop: space.SMALL }} />

        <Grid
          container
          item
          xs={11}
          md={11}
          lg={7}
          style={{
            marginTop: space.MEDIUM,
            marginLeft: space.MEDIUM,
            display: "flex",
            flexDirection: "column"
            // width: "50%"
          }}
        >
          {this.getAccountMonitoringTitle()}
          {selectedType === monitoringTypes.ACCOUNT && (
            <>
              <div style={{ marginTop: space.L }}>
                <StyledInputLabel label="Geography" />
                <Autocomplete
                  value={geographies?.length ? geographies : []}
                  multiple
                  inputValue={geographySearch || ""}
                  onInputChange={(
                    event: any,
                    newValue: string,
                    reason: string
                  ) => {
                    if (reason === "reset") {
                      return;
                    }

                    this.setState({ geographySearch: newValue }, () => {
                      this.searchLocationsDebounce(event?.target?.value);
                    });
                  }}
                  fullWidth
                  renderInput={(params: any) => (
                    <StyledInput
                      placeholder="United States, Canada"
                      variant="outlined"
                      {...params}
                      size="medium"
                      error={Boolean(geographyError)}
                      helperText={geographyError}
                    />
                  )}
                  options={allLocations || []}
                  onChange={(_: unknown, geographies: Geography[]) => {
                    if (geographyError && geographies?.length) {
                      geographyError = "";
                    }

                    this.setState({ geographies, geographyError });
                  }}
                  getOptionLabel={(option: Geography) => option.name}
                />
              </div>

              <div style={{ marginTop: space.LARGE }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: space.SMALL
                  }}
                >
                  <StyledInputLabel
                    label={
                      <Box display={"flex"} alignItems={"center"}>
                        Keywords in Job Title
                        <Tooltip title={JOB_TITLE_INFO_TEXT} placement="right">
                          <InfoOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
                        </Tooltip>
                      </Box>
                    }
                    style={{ marginBottom: 0 }}
                  />
                  <StyledButton
                    size="small"
                    variant="textprimary"
                    onClick={() =>
                      this.setState({
                        showGenerateJobTitleButton: !showGenerateJobTitleButton
                      })
                    }
                  >
                    Generate Job Titles
                    {showGenerateJobTitleButton ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </StyledButton>
                </Box>
                {showGenerateJobTitleButton && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      marginBottom: space.MEDIUM
                    }}
                  >
                    <Box width={"50%"}>
                      <StyledInput
                        type="text"
                        size="medium"
                        fullWidth
                        name="baseBuyerTitle"
                        label="Base Title"
                        placeholder="Account Evangelist"
                        value={generateJobTitleInputText}
                        onChange={(e) =>
                          this.setState({
                            generateJobTitleInputText: e.target.value
                          })
                        }
                      />
                    </Box>
                    <StyledButton
                      variant="primary"
                      size="medium"
                      disabled={!generateJobTitleInputText}
                      onClick={() => this.suggestBuyerTitles()}
                    >
                      {generateJobTitleLoading && (
                        <CircularProgress
                          size={16}
                          color="inherit"
                          style={{ marginRight: space.XS }}
                        />
                      )}
                      {generateJobTitleLoading ? "Generating..." : "Generate"}
                    </StyledButton>
                  </Box>
                )}
                <StyledChipInput
                  value={jobTitle}
                  onChange={(value: string[]) => {
                    if (value.length > JOB_TITLE_LIMIT) {
                      notify.show(
                        `You can only add ${JOB_TITLE_LIMIT} keywords`,
                        "error"
                      );
                      return;
                    }
                    if (jobTitleError && value?.length) {
                      jobTitleError = "";
                    }

                    this.setState({ jobTitle: value, jobTitleError });
                  }}
                  placeholder="partnerships, finance, sales"
                  inputSize="medium"
                  fullWidth
                  errorMessage={jobTitleError}
                />
              </div>

              <div style={{ marginTop: space.LARGE }}>
                <StyledInputLabel label="Seniority" />
                <Autocomplete
                  multiple={true}
                  options={LINKEDIN_SENIORITY_OPTIONS}
                  value={seniorities}
                  onChange={(_, seniorities) => {
                    if (seniorityError && seniorities?.length) {
                      seniorityError = "";
                    }

                    this.setState({ seniorities, seniorityError });
                  }}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      placeholder="CXO, Vice President"
                      variant="outlined"
                      size="medium"
                      error={Boolean(seniorityError)}
                      helperText={seniorityError}
                    />
                  )}
                />
              </div>

              {/* <div style={{ marginTop: space.LARGE }}>
                <StyledInputLabel label="Industry" />
                <Autocomplete
                  multiple={true}
                  options={industryOptions}
                  value={industries}
                  onChange={(e, values) => {
                    this.setState({ industries: values });
                  }}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      placeholder="Airlines and Aviation, Manufacturing"
                      variant="outlined"
                      size="medium"
                    />
                  )}
                />
              </div> */}

              <div style={{ marginTop: space.LARGE }}>
                <StyledInputLabel
                  label="Account"
                  helperText="Please select as many accounts as possible that have the desktop app installed and have access to SalesNav. It's best to include the account owners."
                />
                <Autocomplete
                  options={accounts}
                  value={selectedAccounts}
                  multiple
                  fullWidth
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(
                    option: LinkedinAccount,
                    value: LinkedinAccount
                  ) => {
                    return option.userId === value.userId;
                  }}
                  renderInput={(params: any) => (
                    <StyledInput
                      {...params}
                      placeholder="John Smith, Julie Adams"
                      variant="outlined"
                      size="medium"
                      error={Boolean(accountsError)}
                      helperText={accountsError}
                    />
                  )}
                  onChange={(event: unknown, newValues: LinkedinAccount[]) => {
                    if (accountsError && newValues?.length) {
                      accountsError = "";
                    }

                    this.setState({
                      selectedAccounts: newValues,
                      accountsError
                    });
                  }}
                  renderOption={(props: any, option: LinkedinAccount) => {
                    return (
                      <Box
                        component="li"
                        key={option.userId}
                        {...props}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Avatar
                          sx={{ ...AvatarSize.sm, display: "flex" }}
                          src={option?.profilePicture}
                        />
                        <Typography
                          variant="bodym"
                          style={{ marginLeft: space.SMALL }}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                    );
                  }}
                />
              </div>
            </>
          )}

          {selectedType === monitoringTypes.URL && (
            <>
              <div style={{ marginTop: space.LARGE }}>
                <StyledInputLabel label="URL" />
                <StyledInput
                  size="large"
                  variant="outlined"
                  fullWidth
                  placeholder="https://www.linkedin.com/sales/search/people?savedSearchId=1895285970&sessionId=XqdZGkx3R%2FWnxdZCz6Nezw%3D%3D"
                  value={searchURL}
                  error={Boolean(searchURLError)}
                  helperText={searchURLError || ""}
                  onChange={(event) => {
                    this.setState({
                      searchURL: event.target.value,
                      searchURLError: ""
                    });
                  }}
                />
              </div>

              <div style={{ marginTop: space.LARGE }}>
                <StyledInputLabel
                  label="Account"
                  helperText="Only select account where this SalesNav link will open"
                />
                <Autocomplete
                  options={accounts}
                  value={selectedAccounts[0] || ""}
                  fullWidth
                  getOptionLabel={(option: any) => option.name || ""}
                  isOptionEqualToValue={(
                    option: LinkedinAccount,
                    value: LinkedinAccount
                  ) => {
                    return option.userId === value.userId;
                  }}
                  renderInput={(params: any) => (
                    <StyledInput
                      {...params}
                      placeholder="John Smith, Julie Adams"
                      variant="outlined"
                      size="medium"
                      error={Boolean(accountsError)}
                      helperText={accountsError}
                    />
                  )}
                  onChange={(event: unknown, value: LinkedinAccount | null) => {
                    this.setState({
                      selectedAccounts: value ? [value] : [],
                      accountsError: value ? "" : accountsError
                    });
                  }}
                  renderOption={(props: any, option: LinkedinAccount) => {
                    return (
                      <Box
                        component="li"
                        key={option.userId}
                        {...props}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Avatar
                          sx={{ ...AvatarSize.sm, display: "flex" }}
                          src={option?.profilePicture}
                        />
                        <Typography
                          variant="bodym"
                          style={{ marginLeft: space.SMALL }}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                    );
                  }}
                />
              </div>
            </>
          )}

          {selectedType !== monitoringTypes.URL && (
            <div style={{ marginTop: space.LARGE }}>
              <StyledInputLabel label="CSV File" />
              {Boolean(selectedAccounts?.length) && (
                <Typography
                  paragraph
                  variant="bodym"
                  style={{ marginBottom: space.SMALL }}
                >
                  We'll upload the first{" "}
                  <b>
                    {formatNumber(
                      selectedAccounts.length * COMPANIES_LIMIT_PER_ACCOUNT
                    )}
                  </b>{" "}
                  companies. Add more accounts to increase your limit.
                </Typography>
              )}

              <Uploader csvOnly={true} onImageUpload={this.onFileChange} />
              {this.getCompaniesAndRolesLabel()}
            </div>
          )}
          <div
            style={{
              marginTop: space.LARGE,
              width: "100%",
              display: "flex",
              justifyContent: "end"
            }}
          >
            <StyledButton
              variant="primary"
              size="medium"
              onClick={() => {
                this.saveAccountsForMonitoring();
              }}
            >
              {saveInProgress && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginRight: space.XS }}
                />
              )}
              {saveInProgress ? "Saving..." : "Save"}
            </StyledButton>
          </div>
        </Grid>
        <Box
          style={{
            marginTop: space.XL
          }}
        >
          <DataGridPro
            disableRowSelectionOnClick
            hideFooterRowCount
            hideFooter
            disableColumnMenu
            rows={uploadedAccounts}
            columns={this.getTableColumn()}
          />
          <StyledPagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
          />
        </Box>
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(AccountMonitoring));
