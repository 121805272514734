import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../Components/styles/react-dates-override.css";
import { shadow, space } from "../Config/theme";
import StyledAutocomplete from "../design/components/StyledAutocomplete";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import StyledInputLabel from "../design/components/StyledInputLabel";
import StyledSwitch from "../design/components/StyledSwitch";
import { ReactComponent as CloseIcon } from "../Images/icon24/close.svg";
import { AUTOCOMPLETE_ALL_OPTION_TEXT } from "./LinkedInShare";
import styles from "./styles/KeywordMonitoringStyles";

export enum TimeFiltersEnum {
  DATE_RANGE = 0,
  LAST_7_DAYS = 1,
  LAST_15_DAYS = 2,
  LAST_30_DAYS = 3
}

type AutocompleteOptionType = {
  name: string;
  value: string | number;
};

export enum LeadTypeEnums {
  COMPETITOR = "Competitor",
  TARGET = "Target",
  PROSPECT = "Prospect",
  INFLUENCER = "Influencer",
  NOT_RELEVANT = "Not Relevant"
}

export const LEAD_TYPE_FILTER_OPTIONS: AutocompleteOptionType[] = [
  { name: LeadTypeEnums.TARGET, value: LeadTypeEnums.TARGET },
  { name: LeadTypeEnums.PROSPECT, value: LeadTypeEnums.PROSPECT },
  { name: LeadTypeEnums.INFLUENCER, value: LeadTypeEnums.INFLUENCER },
  { name: LeadTypeEnums.COMPETITOR, value: LeadTypeEnums.COMPETITOR },
  { name: LeadTypeEnums.NOT_RELEVANT, value: LeadTypeEnums.NOT_RELEVANT }
];

export type KeywordMonitoringPostFilterModelType = {
  leadTypeFilter: AutocompleteOptionType[];
  allLeadTypeOptionsChecked: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateType: TimeFiltersEnum;
  keywordFilter: string[];
  showTargetAccountsOnly: boolean;
};

type KeywordMonitoringPostFilterProps = {
  filterModel: KeywordMonitoringPostFilterModelType;
  onClose: () => void;
  onChange: (newFilters: KeywordMonitoringPostFilterModelType) => void;
  publicationId: string;
  socialSellingKeywords: string[];
  leadTypeFilterOptions: AutocompleteOptionType[];
  classes: Record<keyof ReturnType<typeof styles>, string>;
};

const KeywordMonitoringPostFilters: React.FC<
  KeywordMonitoringPostFilterProps
> = ({
  filterModel,
  onClose,
  onChange,
  publicationId,
  socialSellingKeywords,
  leadTypeFilterOptions,
  classes
}) => {
  const [leadTypeFilter, setLeadTypeFilter] = useState<
    AutocompleteOptionType[]
  >(filterModel.leadTypeFilter || []);

  const [allLeadTypeOptionsChecked, setAllLeadTypeOptionsChecked] = useState(
    filterModel.allLeadTypeOptionsChecked || false
  );

  const [dateType, setDateType] = useState(filterModel.dateType);
  const [dateFilter, setDateFilter] = useState({
    startDate: filterModel.startDate,
    endDate: filterModel.endDate
  });
  const [dateFocusedInput, setDateFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);

  const [keywordFilter, setKeywordFilter] = useState<string[]>(
    filterModel.keywordFilter
  );

  const [showTargetAccountsOnly, setShowTargetAccountsOnly] = useState(
    filterModel.showTargetAccountsOnly
  );

  const onSaveClick = () => {
    onChange({
      leadTypeFilter,
      allLeadTypeOptionsChecked,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      dateType,
      keywordFilter,
      showTargetAccountsOnly
    });
  };

  const onButtonGroupChange = (filterType: TimeFiltersEnum) => {
    if (filterType === dateType) {
      return;
    }

    let startDate = moment();
    let endDate = moment();

    if (filterType === TimeFiltersEnum.LAST_7_DAYS) {
      startDate = moment().subtract(7, "days");
    } else if (filterType === TimeFiltersEnum.LAST_15_DAYS) {
      startDate = moment().subtract(15, "days");
    } else {
      startDate = moment().subtract(30, "days");
    }

    setDateFilter({ startDate, endDate });
    setDateType(filterType);
  };

  return (
    <Dialog
      fullScreen
      open={true}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          width: 448,
          height: "100%",
          right: 0,
          position: "absolute",
          background: "white"
        }
      }}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          "&.MuiDialogTitle-root": {
            typography: "bodyl",
            padding: space.LARGE,
            display: "flex",
            justifyContent: "space-between"
          }
        }}
      >
        <Typography variant="h400">Filter</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          "&.MuiDialogContent-root": {
            display: "flex",
            flexDirection: "column",
            padding: space.LARGE,
            boxShadow: shadow.MEDIUM,
            maxHeight: "100vh",
            overflow: "scroll"
          }
        }}
      >
        <StyledInputLabel label="Date" />
        <ToggleButtonGroup
          fullWidth={true}
          className={classes.durationPicker}
          value={dateType}
        >
          <ToggleButton
            value={TimeFiltersEnum.LAST_7_DAYS}
            onClick={() => {
              onButtonGroupChange(TimeFiltersEnum.LAST_7_DAYS);
            }}
          >
            7 days
          </ToggleButton>

          <ToggleButton
            value={TimeFiltersEnum.LAST_15_DAYS}
            onClick={() => {
              onButtonGroupChange(TimeFiltersEnum.LAST_15_DAYS);
            }}
          >
            15 days
          </ToggleButton>

          <ToggleButton
            value={TimeFiltersEnum.LAST_30_DAYS}
            onClick={() => {
              onButtonGroupChange(TimeFiltersEnum.LAST_30_DAYS);
            }}
          >
            30 days
          </ToggleButton>
        </ToggleButtonGroup>
        <Box
          sx={{
            position: "relative",
            zIndex: 100,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: space.LARGE
          }}
        >
          <DateRangePicker
            startDate={dateFilter.startDate}
            enableOutsideDays={true}
            startDateId="your_unique_start_date_id_2"
            endDate={dateFilter.endDate}
            endDateId="your_unique_end_date_id_2"
            small={true}
            onDatesChange={({ startDate, endDate }) => {
              if (startDate && endDate) {
                const newStartDate = startDate
                  .set("hours", 0)
                  .set("minutes", 0)
                  .set("seconds", 0);

                const newEndDate = endDate
                  .set("hours", 23)
                  .set("minutes", 59)
                  .set("seconds", 59);

                setDateFilter({
                  startDate: newStartDate,
                  endDate: newEndDate
                });
                setDateType(TimeFiltersEnum.DATE_RANGE);
              }
            }}
            focusedInput={dateFocusedInput}
            onFocusChange={(focusedInput) => {
              setDateFocusedInput(focusedInput);
            }}
            openDirection="down"
            horizontalMargin={70}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={() => false}
          />
        </Box>

        <br />
        {leadTypeFilterOptions.find(
          (option) => option.value === LeadTypeEnums.TARGET
        ) && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={space.MEDIUM}
          >
            <StyledInputLabel
              label={"Show only target accounts"}
              style={{ marginBottom: 0 }}
            />
            <StyledSwitch
              size="medium"
              name="showTargetAccountsOnly"
              checked={showTargetAccountsOnly}
              onChange={() => {
                setShowTargetAccountsOnly(!showTargetAccountsOnly);
              }}
              style={{ margin: 0 }}
            />
          </Box>
        )}

        <StyledInputLabel label="Type" />
        <StyledAutocomplete
          value={leadTypeFilter}
          options={leadTypeFilterOptions}
          isCheckboxSelected={(
            option: AutocompleteOptionType,
            index: number
          ) => {
            if (allLeadTypeOptionsChecked) return true;

            return Boolean(
              leadTypeFilter.find((lead) => lead.value === option.value)
            );
          }}
          onChange={(
            e: React.SyntheticEvent,
            values: AutocompleteOptionType[]
          ) => {
            const allFlagSelected = Boolean(
              values.find(
                (value) => value.name === AUTOCOMPLETE_ALL_OPTION_TEXT
              )
            );
            if (allFlagSelected) {
              setAllLeadTypeOptionsChecked(!allLeadTypeOptionsChecked);
              setLeadTypeFilter(
                allLeadTypeOptionsChecked ? [] : leadTypeFilterOptions
              );
            } else {
              setAllLeadTypeOptionsChecked(
                values.length === leadTypeFilterOptions.length
              );
              setLeadTypeFilter(values);
            }
          }}
          size="medium"
          limitTags={4}
          disabled={showTargetAccountsOnly}
          showAvatar={false}
        />

        <br />

        <StyledInputLabel label="Keywords" />
        <Autocomplete
          options={socialSellingKeywords}
          getOptionLabel={(keyword) => keyword}
          value={keywordFilter}
          multiple
          onChange={(e, values) => setKeywordFilter(values)}
          renderInput={(params) => (
            <StyledInput {...params} size="small" variant="outlined" />
          )}
          limitTags={3}
        />
      </DialogContent>
      <DialogActions
        sx={{
          "&.MuiDialogActions-root": {
            px: space.LARGE,
            background: "white"
          }
        }}
      >
        <StyledButton
          size="medium"
          variant="primary"
          onClick={onSaveClick}
          fullWidth
        >
          Save
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(KeywordMonitoringPostFilters);
