import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import _ from "lodash";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import BetaText from "../Components/BetaText";
import { notify } from "../Components/CustomNotifications";
import Loading from "../Components/Loading";
import { space } from "../Config/theme";
import StyledAutocomplete from "../design/components/StyledAutocomplete";
import StyledButton from "../design/components/StyledButton";
import StyledChipInput from "../design/components/StyledChipInput";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledInputLabel from "../design/components/StyledInputLabel";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import { Publication } from "../Utils/Types";
import KnowledgeBaseBuyerFilters from "./KnowledgeBaseBuyerFilters";
import KnowledgeBaseSalesEnablement from "./KnowledgeBaseSalesEnablement";
import { AUTOCOMPLETE_ALL_OPTION_TEXT } from "./LinkedInShare";
import Page404 from "./Page404";
import styles from "./styles/KnowledgeBaseSettingsStyles";

const COMPANY_DETAILS_SAMPLE = `Retool is a powerful low-code platform that allows developers to build internal tools and applications quickly and easily. It is designed to streamline the process of creating user interfaces for accessing and manipulating data from various sources without writing extensive code.

Here are some key features of Retool:

Drag-and-Drop Interface: Retool provides a visual interface where users can drag and drop components like buttons, tables, forms, and charts onto a canvas. This allows for the rapid creation of intuitive user interfaces.

Connectors and Integrations: Retool offers a wide range of pre-built connectors and integrations with popular databases, APIs, and services. These connectors enable seamless communication with data sources such as PostgreSQL, MySQL, MongoDB, Salesforce, Slack, and many others.

Customizable Components: While Retool provides a rich set of pre-built components, developers can also create custom components using JavaScript and React. This allows for greater flexibility in designing and implementing complex UI elements.

Data Manipulation and Transformation: Retool offers a powerful data querying and manipulation interface. Users can write SQL queries, filter and transform data, aggregate results, and perform calculations using JavaScript. This makes it easy to fetch and manipulate data from different sources.

Real-time Collaboration: Retool allows multiple users to collaborate on building applications simultaneously. It supports real-time updates, enabling team members to work together efficiently and see changes reflected instantly.

Security and Permissions: Retool provides robust security features to ensure data protection. It offers granular access controls, allowing administrators to define user roles and permissions for different resources and actions.

Deployment and Hosting: Once an application is built, Retool provides options for deploying it on various platforms. You can host applications on Retool's cloud infrastructure, deploy them on your own servers, or export them as standalone executables.

Version Control: Retool supports version control, enabling developers to track changes, collaborate across teams, and revert to previous versions if needed. This helps maintain code quality and facilitates teamwork.
`;

const SAMPLE_OUTBOUND_MESSAGE = `Cindy, guessing Palash and Kishore's LinkedIn posts drive pipeline since you sell to marketing. Would it help to see which ICP prospects are engaging w/ those posts, competitors, and influencers in your space?
PS Doing this with Disco. They sell to marketing too. Saw a big life in pipe w/ our tool.
--
Noticed that you have over 50 SDRs at Planful focused on cold calling. But only 16 to 21% of decision makers picks up the phone from unknown numbers, so you're fishing in a very small pond. 6,000 CFOs at 50+ employee companies in the US posted on LinkedIn last month. And that indicates a lot of them are lurking because we know 80% of weekly LinkedIn active users are reading the feed. Wondering if you've gotten your team to really think about LinkedIn. We generate about 64% of our own pipeline from LinkedIn. Can share some ways in which you can get your reps to think about this right.
`;

const api = API.create();

type Feature = {
  _id?: string;
  name: string;
  problem: string;
  uniqueness: string;
  benefit: string;
};

type Persona = {
  _id?: string;
  buyer: string;
  priorities: string[];
  features: Feature[];
};

type KnowledgeBaseSettingsProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;

const KnowledgeBaseSettings: React.FC<KnowledgeBaseSettingsProps> = ({
  classes,
  match,
  location
}) => {
  const [loading, setLoading] = React.useState(true);
  const [generatingDescription, setGeneratingDescription] =
    React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [publicationId, setPublicationId] = React.useState(
    match.params.id ? match.params.id : ""
  );
  const [publication, setPublication] = React.useState<Publication | null>(
    null
  );
  const [companyDetails, setCompanyDetails] = React.useState("");
  const [avoidTopics, setAvoidTopics] = React.useState<string[]>([]);
  const [focusTopics, setFocusTopics] = React.useState<string[]>([]);
  const [sampleOutboundMessage, setSampleOutboundMessage] = React.useState("");

  /////////////////////////////////////////

  const [personas, setPersonas] = React.useState<Persona[]>([]);
  const [personasLoading, setPersonasLoading] = React.useState(false);
  const [showAddEditPersonaDialog, setShowAddEditPersonaDialog] =
    React.useState(false);
  const [selectedPersonaId, setSelectedPersonaId] = React.useState<string>();
  const [buyer, setBuyer] = React.useState("");
  const [priorities, setPriorities] = React.useState([""]);
  const [buyerFeatures, setBuyerFeatures] = React.useState<Feature[]>([]);
  const [isAllFeatureChecked, setIsAllFeatureChecked] = React.useState(false);

  /////////////////////////////////////////

  const [features, setFeatures] = React.useState<Feature[]>([]);
  const [featuresLoading, setFeaturesLoading] = React.useState(false);
  const [showAddEditFeatureDialog, setShowAddEditFeatureDialog] =
    React.useState(false);
  const [selectedFeatureId, setSelectedFeatureId] = React.useState<string>();
  const [name, setName] = React.useState("");
  const [problem, setProblem] = React.useState("");
  const [uniqueness, setUniqueness] = React.useState("");
  const [benefit, setBenefit] = React.useState("");
  const [newCompanyDescription, setNewCompanyDescription] = React.useState("");
  const [savingNewCompanyDescription, setSavingNewCompanyDescription] =
    React.useState(false);
  /////////////////////////////////////////

  useEffect(() => {
    if (match.params.id !== publicationId) {
      setLoading(true);
      setError(false);
      setErrorMessage("");
      setPublication(null);
      setPublicationId(match.params.id || "");
    }
  }, [match, publicationId]);

  useEffect(() => {
    getAllData();

    setTimeout(() => {
      const hashId = window.location.hash.replace("#", "");
      if (hashId) {
        document.getElementById(hashId)?.scrollIntoView();
      }
    }, 1500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationId]);

  const getAllData = () => {
    getPublication();
    getFeatures();
    getPersonas();
  };

  /////////////////////////////////////////

  const getPublication = () => {
    if (!publicationId) {
      setError(true);
      setErrorMessage("Workspace not found");
      return;
    }

    api.getPublicationById(publicationId, (res) => {
      if (res.status === 200) {
        setLoading(false);
        setPublication(res.data.publication);
        setCompanyDetails(res.data.publication?.companyDetails || "");
        setAvoidTopics(res.data.publication?.avoidTopics || []);
        setFocusTopics(res.data.publication?.focusTopics || []);
        setSampleOutboundMessage(
          res.data.publication?.sampleOutboundMessage || ""
        );
      } else {
        setError(true);
        setErrorMessage("Unauthorized access");
      }
    });
  };

  const saveCompanyDetails = (
    companyDetails: string,
    avoidTopics: string[],
    focusTopics: string[],
    sampleOutboundMessage: string
  ) => {
    api.saveCompanyDetails(
      publicationId,
      companyDetails,
      avoidTopics,
      focusTopics,
      sampleOutboundMessage,
      (res) => {
        if (res.status === 200) {
          const publicationClone = _.cloneDeep(publication);
          if (!publicationClone) return;
          publicationClone.companyDetails = companyDetails;
          publicationClone.avoidTopics = avoidTopics;
          publicationClone.focusTopics = focusTopics;
          publicationClone.sampleOutboundMessage = sampleOutboundMessage;
          setPublication(publicationClone);
        } else {
          notify.show("Error saving company details", "error");
        }
      }
    );
  };

  /////////////////////////////////////////

  const getPersonas = () => {
    api.getPersonas(publicationId, (res) => {
      if (res.status === 200) {
        setPersonas(res.data);
      } else {
        notify.show("Error getting personas", "error");
      }
    });
  };

  const editPersonaRow = (index: number) => {
    let persona = personas[index];

    setBuyer(persona.buyer);
    setPriorities(persona.priorities);
    setBuyerFeatures(persona.features);
    setShowAddEditPersonaDialog(true);
    setSelectedPersonaId(persona._id);
  };

  const saveOrUpdatePersona = () => {
    if (!buyer) {
      notify.show("Please enter buyer", "error");
      return;
    }
    if (!priorities?.length) {
      notify.show("Please enter atleast one priority", "error");
      return;
    }

    if (selectedPersonaId) {
      let personaIndex = personas.findIndex(
        (persona) => persona._id === selectedPersonaId
      );
      let editedPersona: Persona = {
        ...personas[personaIndex],
        buyer,
        priorities,
        features: buyerFeatures
      };

      personas[personaIndex] = editedPersona;
      setPersonas(personas);
      savePersona(editedPersona);
    } else {
      let newPersona: Persona = {
        buyer,
        priorities,
        features: buyerFeatures
      };
      if (!personas) {
        setPersonas([newPersona]);
      } else {
        setPersonas([...personas, newPersona]);
      }
      savePersona(newPersona);
    }

    setShowAddEditPersonaDialog(false);
    setSelectedPersonaId(undefined);
    setBuyer("");
    setPriorities([""]);
    setBuyerFeatures([]);
  };

  const savePersona = (persona: Persona) => {
    setPersonasLoading(true);
    api.savePersona(
      publicationId,
      { ...persona, features: persona.features.map((feature) => feature._id) },
      (res) => {
        setPersonasLoading(false);
        getPersonas();
        if (res.status === 200) {
          notify.show("Feature saved successfully", "success");
        } else {
          notify.show("Error saving feature", "error");
        }
      }
    );
  };

  const deletePersonaRow = (index: number) => {
    let personaToDelete = personas[index];
    personas.splice(index, 1);
    setPersonas([...personas]);

    deletePersona(personaToDelete._id || "");
  };

  const deletePersona = (personaId: string) => {
    api.deletePersona(publicationId, personaId, (res) => {
      if (res.status === 200) {
        notify.show("Persona deleted successfully", "success");
        getPersonas();
      } else {
        notify.show("Error deleting persona", "error");
      }
    });
  };

  const closeAddEditPersonaDialog = () => {
    setShowAddEditPersonaDialog(false);
    setSelectedPersonaId(undefined);
    setBuyer("");
    setPriorities([""]);
    setBuyerFeatures([]);
    setIsAllFeatureChecked(false);
  };

  const isFeatureSelected = (option: Feature) => {
    if (isAllFeatureChecked) {
      return true;
    }

    const exists = buyerFeatures.find((feature) => feature._id === option._id);

    return exists ? true : false;
  };

  const onBuyerFeatureChange = (
    e: React.BaseSyntheticEvent,
    values: Feature[]
  ) => {
    const allFlagSelected = values.find(
      (status) => status.name === AUTOCOMPLETE_ALL_OPTION_TEXT
    );

    if (allFlagSelected) {
      const populateAllAccounts = !isAllFeatureChecked;
      setBuyerFeatures(populateAllAccounts ? features : []);
      setIsAllFeatureChecked(populateAllAccounts);
    } else {
      setBuyerFeatures(values);
      setIsAllFeatureChecked(values.length === features.length);
    }
  };

  /////////////////////////////////////////

  const getFeatures = () => {
    api.getFeatures(publicationId, (res) => {
      if (res.status === 200) {
        setFeatures(res.data);
        setIsAllFeatureChecked(false);
      } else {
        notify.show("Error getting features", "error");
      }
    });
  };

  const editFeatureRow = (index: number) => {
    let feature = features[index];

    setName(feature.name);
    setProblem(feature.problem);
    setUniqueness(feature.uniqueness);
    setBenefit(feature.benefit);
    setShowAddEditFeatureDialog(true);

    setSelectedFeatureId(feature._id);
  };

  const saveOrUpdateFeature = () => {
    if (!name) {
      notify.show("Please enter name", "error");
      return;
    }
    if (!problem) {
      notify.show("Please enter problem", "error");
      return;
    }
    if (!uniqueness) {
      notify.show("Please enter uniqueness", "error");
      return;
    }
    if (!benefit) {
      notify.show("Please enter benefit", "error");
      return;
    }

    if (selectedFeatureId) {
      let featureIndex = features.findIndex(
        (feature) => feature._id === selectedFeatureId
      );
      let editedFeature: Feature = {
        ...features[featureIndex],
        name,
        problem,
        uniqueness,
        benefit
      };

      features[featureIndex] = editedFeature;
      setFeatures(features);
      saveFeature(editedFeature);
    } else {
      let newFeature: Feature = {
        name,
        problem,
        uniqueness,
        benefit
      };
      if (!features) {
        setFeatures([newFeature]);
      } else {
        setFeatures([...features, newFeature]);
      }
      saveFeature(newFeature);
    }

    setShowAddEditFeatureDialog(false);
    setSelectedFeatureId(undefined);
    setName("");
    setProblem("");
    setUniqueness("");
    setBenefit("");
  };

  const saveFeature = (feature: Feature) => {
    setFeaturesLoading(true);
    api.saveFeature(publicationId, feature, (res) => {
      setFeaturesLoading(false);
      getFeatures();
      if (res.status === 200) {
        notify.show("Feature saved successfully", "success");
      } else {
        notify.show("Error saving feature", "error");
      }
    });
  };

  const generateCompanyDescription = () => {
    setGeneratingDescription(true);
    api.generateCompanyDescription(publicationId, (res) => {
      setGeneratingDescription(false);
      if (res.status === 200) {
        setNewCompanyDescription(res.data.companyDetails);
      } else {
        notify.show("Error generating company description", "error");
      }
    });
  };

  const saveNewCompanyDescription = () => {
    setCompanyDetails(newCompanyDescription);
    setSavingNewCompanyDescription(true);
    api.saveCompanyDetails(
      publicationId,
      newCompanyDescription,
      avoidTopics,
      focusTopics,
      sampleOutboundMessage,
      (res) => {
        if (res.status === 200) {
          notify.show("Company description saved successfully", "success");
          setSavingNewCompanyDescription(false);
          setNewCompanyDescription("");
        } else {
          notify.show("Error saving company description", "error");
        }
      }
    );
  };

  const deleteFeatureRow = (index: number) => {
    let featureToDelete = features[index];
    features.splice(index, 1);
    setFeatures([...features]);

    deleteFeature(featureToDelete._id || "");
  };

  const deleteFeature = (featureId: string) => {
    api.deleteFeature(publicationId, featureId, (res) => {
      if (res.status === 200) {
        notify.show("Feature deleted successfully", "success");
        getFeatures();
      } else {
        notify.show("Error deleting feature", "error");
      }
    });
  };

  const closeAddEditFeatureDialog = () => {
    setShowAddEditFeatureDialog(false);
    setSelectedFeatureId(undefined);
    setName("");
    setProblem("");
    setUniqueness("");
    setBenefit("");
  };

  /////////////////////////////////////////

  if (error) {
    return (
      <Suspense fallback={<div />}>
        <Page404 errorMessage={errorMessage} />
      </Suspense>
    );
  }

  if (loading) {
    return <Loading />;
  }

  /////////////////////////////////////////

  return (
    <div className={classes.container}>
      <Helmet>
        <title>
          Knowledge Base Settings
          {publication ? " - " + publication.name : ""} | Letterdrop
        </title>
      </Helmet>

      <Container>
        <Typography
          variant="h600"
          paragraph
          style={{ paddingTop: space.LARGE }}
        >
          Knowledge Base Settings
        </Typography>

        <div className={classes.innerContainer}>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12}>
              <section id="companydetails">
                <div style={{ marginTop: space.LARGE }}>
                  <Typography variant="h400" paragraph>
                    Company Details
                  </Typography>

                  <Grid container direction="column">
                    <Typography variant="bodym">
                      Describe what your company does, who you serve, and it's
                      offering/features. This will help our AI understand what
                      content ideas are relevant to you.
                    </Typography>
                    <StyledInput
                      multiline
                      rows={8}
                      size="medium"
                      placeholder={COMPANY_DETAILS_SAMPLE}
                      name="companyDetails"
                      value={companyDetails}
                      onChange={(event) => {
                        setCompanyDetails(event.target.value);
                      }}
                      style={{ paddingTop: space.MEDIUM }}
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      mt={space.SMALL}
                    >
                      <StyledButton
                        onClick={() => generateCompanyDescription()}
                        style={{ marginLeft: "auto" }}
                      >
                        {generatingDescription
                          ? "Generating..."
                          : "Suggest Description"}
                      </StyledButton>
                    </Box>

                    <br />
                    <Typography
                      variant="h400"
                      style={{ marginBottom: space.SMALL }}
                    >
                      Sample Outbound
                    </Typography>
                    <Typography
                      variant="bodym"
                      style={{ marginBottom: space.MEDIUM }}
                    >
                      Add sample outbound messages that you've sent to prospects
                      that helped you get a deal.
                    </Typography>
                    <StyledInput
                      multiline
                      rows={8}
                      size="medium"
                      placeholder={SAMPLE_OUTBOUND_MESSAGE}
                      name="sampleOutboundMessage"
                      value={sampleOutboundMessage}
                      onChange={(event) => {
                        setSampleOutboundMessage(event.target.value);
                      }}
                      style={{ paddingTop: space.MEDIUM }}
                    />
                    <br />
                    <Typography
                      variant="h400"
                      style={{ marginBottom: space.SMALL }}
                    >
                      Avoid Topics
                    </Typography>
                    <Typography
                      variant="bodym"
                      style={{ marginBottom: space.MEDIUM }}
                    >
                      These are topics you want to avoid creating content ideas
                      around. Add anything that's not strategic but still might
                      be present in your calls.
                    </Typography>
                    <StyledChipInput
                      value={avoidTopics}
                      onChange={(value: string[]) => {
                        setAvoidTopics(value);
                      }}
                      placeholder=""
                      size="medium"
                      fullWidth
                    />
                    <br />
                    <Typography
                      variant="h400"
                      style={{ marginBottom: space.SMALL }}
                    >
                      Focus Topics
                    </Typography>
                    <Typography
                      variant="bodym"
                      style={{ marginBottom: space.MEDIUM }}
                    >
                      These are topics you want to focus on creating content
                      ideas around. Add anything that's strategic and a current
                      focus to keep your team posting content around certain
                      themes that pop up in customer conversations.
                    </Typography>
                    <StyledChipInput
                      value={focusTopics}
                      onChange={(value: string[]) => {
                        setFocusTopics(value);
                      }}
                      placeholder=""
                      size="medium"
                      fullWidth
                    />
                    {((publication?.companyDetails || "") !== companyDetails ||
                      !_.isEqual(publication?.avoidTopics || [], avoidTopics) ||
                      !_.isEqual(publication?.focusTopics || [], focusTopics) ||
                      (publication?.sampleOutboundMessage || "") !==
                        sampleOutboundMessage) && (
                      <Grid
                        item
                        style={{ paddingTop: space.MEDIUM, textAlign: "end" }}
                      >
                        <StyledButton
                          onClick={() =>
                            saveCompanyDetails(
                              companyDetails,
                              avoidTopics,
                              focusTopics,
                              sampleOutboundMessage
                            )
                          }
                        >
                          Save
                        </StyledButton>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </section>
            </Grid>

            <KnowledgeBaseBuyerFilters
              publicationId={publicationId}
              publication={publication}
            />

            {false && (
              <Grid item xs={12} style={{ marginTop: space.LARGE }}>
                <hr color={colors.horizontalRule} />

                <div style={{ marginTop: space.LARGE }}>
                  <Typography variant="h400" paragraph>
                    Personas and Features <BetaText />
                  </Typography>

                  <Typography variant="bodym">
                    Describe who you sell to and what you sell to give our AI
                    context on your business
                  </Typography>
                </div>

                <Grid container direction="column" mt={space.LARGE}>
                  <Typography variant="h300">Personas</Typography>
                </Grid>
                <Grid container direction="column">
                  {personasLoading && <Loading />}

                  {!personasLoading && personas?.length > 0 && (
                    <TableContainer style={{ marginBottom: space.XS }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Persona</TableCell>
                            <TableCell>Priorities</TableCell>
                            <TableCell>Features</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {personas.map((value, index) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.buyer}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "30%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.priorities?.join(", ")}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "20%"
                                }}
                              >
                                <Grid
                                  style={{
                                    lineHeight: "20px",
                                    maxHeight: "40px",
                                    overflow: "hidden"
                                  }}
                                >
                                  {value.features
                                    ?.map((feature) => feature?.name)
                                    ?.join(", ")}
                                </Grid>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "10%"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <div>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        onClick={() => {
                                          editPersonaRow(index);
                                        }}
                                        size="large"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>

                                  <div>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          deletePersonaRow(index);
                                        }}
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  <Grid item container direction="row" mt={space.SMALL}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StyledButton
                        variant="textprimary"
                        onClick={() => setShowAddEditPersonaDialog(true)}
                        startIcon={<AddCircleIcon />}
                      >
                        Add Buyer
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction="column" mt={space.LARGE}>
                  <Typography variant="h300">Features</Typography>
                </Grid>
                <Grid container direction="column">
                  {featuresLoading && <Loading />}

                  {!featuresLoading && features?.length > 0 && (
                    <TableContainer style={{ marginBottom: space.XS }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Problem</TableCell>
                            <TableCell>Uniqueness</TableCell>
                            <TableCell>Benefit</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {features.map((value, index) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell
                                  style={{
                                    width: "20%"
                                  }}
                                >
                                  <Grid
                                    style={{
                                      lineHeight: "20px",
                                      maxHeight: "40px",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {value.name}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "20%"
                                  }}
                                >
                                  <Grid
                                    style={{
                                      lineHeight: "20px",
                                      maxHeight: "40px",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {value.problem}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "25%"
                                  }}
                                >
                                  <Grid
                                    style={{
                                      lineHeight: "20px",
                                      maxHeight: "40px",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {value.uniqueness}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "25%"
                                  }}
                                >
                                  <Grid
                                    style={{
                                      lineHeight: "20px",
                                      maxHeight: "40px",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {value.benefit}
                                  </Grid>
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "10%"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                  >
                                    <div>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={() => {
                                            editFeatureRow(index);
                                          }}
                                          size="large"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>

                                    <div>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          onClick={() => {
                                            deleteFeatureRow(index);
                                          }}
                                          size="large"
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}

                  <Grid item container direction="row" mt={space.SMALL}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StyledButton
                        variant="textprimary"
                        onClick={() => setShowAddEditFeatureDialog(true)}
                        startIcon={<AddCircleIcon />}
                      >
                        Add Feature
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <KnowledgeBaseSalesEnablement
              publicationId={publicationId}
              publication={publication}
            />
          </Grid>

          {showAddEditPersonaDialog && (
            <StyledDialog
              open={showAddEditPersonaDialog}
              title={
                selectedPersonaId ? "Update a Persona" : "Create a Persona"
              }
              body={
                <div style={{ padding: `0 ${space.MEDIUM}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="buyer"
                      label="Buyer"
                      fullWidth
                      placeholder="VP Marketing"
                      value={buyer}
                      onChange={(e) => setBuyer(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel label="Priorities" />
                    {priorities.map((priority, index) => {
                      return (
                        <Grid
                          container
                          direction="row"
                          key={index}
                          style={{ marginBottom: space.MEDIUM }}
                        >
                          <Grid item xs={11}>
                            <StyledInput
                              fullWidth
                              autoFocus={index !== 0}
                              size="large"
                              value={priority}
                              placeholder="Understand ROI of marketing dollars spend"
                              onChange={(event) => {
                                let newPriorities = [...priorities];
                                newPriorities[index] = event.target.value;
                                setPriorities(newPriorities);
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={1}
                            style={{ paddingLeft: space.SMALL }}
                          >
                            <Tooltip title="Remove Priority">
                              <IconButton
                                onClick={() => {
                                  let newPriorities = [...priorities];
                                  newPriorities.splice(index, 1);
                                  setPriorities(newPriorities);
                                }}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <div style={{ marginTop: 10 }}>
                      <StyledButton
                        variant="textprimary"
                        onClick={() => {
                          setPriorities([...priorities, ""]);
                        }}
                        startIcon={<AddCircleIcon />}
                      >
                        Add Priority
                      </StyledButton>
                    </div>
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInputLabel label="Features" />

                    <StyledAutocomplete
                      value={buyerFeatures}
                      onChange={onBuyerFeatureChange}
                      options={features}
                      isCheckboxSelected={isFeatureSelected}
                      showAvatar={false}
                    />
                  </div>
                </div>
              }
              successButtonName={selectedPersonaId ? "Update" : "Save"}
              successCallback={saveOrUpdatePersona}
              cancelCallback={closeAddEditPersonaDialog}
            />
          )}

          {showAddEditFeatureDialog && (
            <StyledDialog
              open={showAddEditFeatureDialog}
              title={
                selectedFeatureId ? "Update a Feature" : "Create a Feature"
              }
              body={
                <div style={{ padding: `0 ${space.MEDIUM}` }}>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      autoFocus
                      type="text"
                      size="large"
                      name="name"
                      label="Name"
                      fullWidth
                      placeholder="SEO Optimizer"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      type="text"
                      size="large"
                      name="problem"
                      label="Problem"
                      fullWidth
                      placeholder="Unsure how to adapt to Google SGE and AI"
                      value={problem}
                      onChange={(e) => setProblem(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      type="text"
                      size="large"
                      name="uniqueness"
                      label="Uniqueness"
                      fullWidth
                      placeholder="Tools like Clearscope do not help you understand information and stop analysis at keyword level"
                      value={uniqueness}
                      onChange={(e) => setUniqueness(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: space.MEDIUM }}>
                    <StyledInput
                      type="text"
                      size="large"
                      name="benefit"
                      label="Benefit"
                      fullWidth
                      placeholder="Helps you understand information gaps between you and competing pages"
                      value={benefit}
                      onChange={(e) => setBenefit(e.target.value)}
                    />
                  </div>
                </div>
              }
              successButtonName={selectedFeatureId ? "Update" : "Save"}
              successCallback={saveOrUpdateFeature}
              cancelCallback={closeAddEditFeatureDialog}
            />
          )}
        </div>
        {newCompanyDescription && (
          <StyledDialog
            open={newCompanyDescription !== ""}
            title="New Company Description"
            body={
              <Grid container direction="column" spacing={space.MEDIUM}>
                <Grid item>
                  <Typography
                    variant="bodym"
                    style={{
                      whiteSpace: "pre-wrap",
                      marginTop: space.MEDIUM
                    }}
                  >
                    {newCompanyDescription}
                  </Typography>
                </Grid>
              </Grid>
            }
            successButtonName={
              savingNewCompanyDescription ? "Saving..." : "Save"
            }
            successCallback={() => {
              saveNewCompanyDescription();
            }}
            cancelCallback={() => {
              setNewCompanyDescription("");
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default withRouter(withStyles(styles)(KnowledgeBaseSettings));
