// @ts-nocheck
import { ArrowDropDownSharp } from "@mui/icons-material";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import colors, { designColors } from "../Themes/Colors";
import { ReactComponent as ChevronDownIcon } from "../Images/icon24/chevron_down.svg";

export const shadow = {
  SMALL:
    "0px 2px 8px rgba(32, 37, 50, 0.08), 0px 2px 4px rgba(32, 37, 50, 0.03)",
  MEDIUM:
    "0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06)",
  LARGE:
    "0px 2px 12px rgba(26, 26, 26, 0.1), 0px 16px 24px -2px rgba(26, 26, 26, 0.12)",
  MODEL: "0px 8px 40px 0px #00000033, 0px 0px 4px 0px #0000001A"
};

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h900: true;
    h800: true;
    h700: true;
    h600: true;
    h500: true;
    h400: true;
    h300: true;
    h200: true;
    h100: true;
    subtitle: true;
    bodyl: true;
    bodym: true;
    bodys: true;
  }
}

declare module "@mui/material/Autocomplete" {
  interface AutocompletePropsSizeOverrides {
    large: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Bold: React.CSSProperties;
  h2Bold: React.CSSProperties;
}

export const AvatarSize = {
  xl: {
    width: 64,
    height: 64,
    fontSize: "20px"
  },
  l: {
    width: 48,
    height: 48,
    fontSize: "18px"
  },
  m: {
    width: 40,
    height: 40,
    fontSize: "16px"
  },
  sm: {
    width: 32,
    height: 32,
    fontSize: "14px"
  },
  xs: {
    width: 24,
    height: 24,
    fontSize: "12px"
  }
};

export const space = {
  /* FIGMA STANDARD SPACING */

  XXS: "4px",
  XS: "8px",
  SMALL: "12px",
  MEDIUM: "16px",
  LARGE: "24px",
  L: "32px",
  XL: "64px",
  XXL: "128px"
};

export const labelSize = {
  xxl: "h300",
  xl: "h300",
  large: "h300",
  medium: "h200",
  small: "h100"
};

export const SIDE_PANEL_WIDTH = "448px";

const theme = createTheme({
  // shape: {
  //   borderRadius: 12
  // },

  spacing: 4, //theme value for consistent spacing

  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    button: {
      textTransform: "none"
    },

    h900: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "72px",
      lineHeight: "108px",
      fontStyle: "normal"
    },
    h800: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "56px",
      lineHeight: "84px",
      fontStyle: "normal"
    },
    h700: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "40px",
      lineHeight: "60px",
      fontStyle: "normal"
    },
    h600: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "48px",
      fontStyle: "normal"
    },
    h500: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "36px",
      fontStyle: "normal"
    },
    h400: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "32px",
      fontStyle: "normal"
    },
    h300: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      fontStyle: "normal"
    },
    h200: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      fontStyle: "normal"
    },
    h100: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal"
    },
    subtitle: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "32px",
      fontStyle: "normal"
    },
    bodyl: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      fontStyle: "normal",
      letterSpacing: "0.002em"
    },
    bodym: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      fontStyle: "normal",
      letterSpacing: "0.002em"
    },
    bodys: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontWeight: 500,
      fontSize: "12px !important",
      lineHeight: "16px",
      fontStyle: "normal",
      letterSpacing: "0.002em"
    }
  } as ExtendedTypographyOptions,
  palette: {
    background: {
      default: designColors.white
    },
    secondary: {
      main: colors.secondary
    },
    primary: {
      main: colors.primary
    }
  },
  props: {
    MuiTextField: {
      autoComplete: "off"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  components: {
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: shadow.SMALL,
    //       borderRadius: "8px"
    //     }
    //   }
    // },
    MuiSelect: {
      defaultProps: {
        variant: "outlined"
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: colors.buttonText,
            "&:hover": {
              textDecoration: "none",
              backgroundColor: colors.buttonHoverBG
            }
          }
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            color: colors.buttonText,
            border: `1px solid ${colors.buttonBorder}`,
            "&:hover": {
              textDecoration: "none",
              backgroundColor: colors.buttonHoverBG,
              border: `1px solid ${colors.buttonBorder}`
            }
          }
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            color: colors.primary,
            border: `1px solid ${colors.primaryButtonBorder}`,
            "&:hover": {
              textDecoration: "none",
              backgroundColor: colors.primaryButtonBG,
              border: `1px solid ${colors.primary}`
            }
          }
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            color: colors.buttonText,
            backgroundColor: colors.buttonContainerBG,
            "&:hover": {
              textDecoration: "none",
              backgroundColor: colors.buttonContainerHoverBG
            }
          }
        }
      ]
    },
    MuiButtonGroup: {
      variants: [
        {
          props: { color: "primary", size: "small" },
          style: {
            height: 30,
            color: colors.white
          }
        }
      ]
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ChevronDownIcon />
      },
      styleOverrides: {
        inputRoot: {
          padding: "5px 0px"
        },
        listbox: {
          padding: "0px"
        },
        paper: {
          // borderRadius: "0px",
          // boxShadow: "none"
        },
        tag: {
          marginLeft: "6px !important"
        },
        option: ({ ownerState, theme }) => ({
          padding: "10px 16px !important",
          ...(ownerState.size === "small" && {
            ...theme.typography.bodys,
            minHeight: "32px"
          }),
          ...(ownerState.size === "medium" && {
            ...theme.typography.bodym,
            minHeight: "40px"
          }),

          ...(ownerState.size === "large" && {
            ...theme.typography.bodyl,
            minHeight: "48px"
          })
        }),
        noOptions: {
          minHeight: "48px !important"
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: 0,
          margin: "20px 0px"
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid " + designColors.grayScale[40],
          boxSizing: "border-box",
          background: colors.white
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid " + designColors.grayScale[40],
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          "&:hover": {
            background: designColors.white
          }
        },
        root: {
          border: 0,
          "&:last-child td, &:last-child th": { borderBottom: 0 },
          "&:hover": {
            background: designColors.grayScale[0]
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          color: designColors.grayScale[100],
          padding: "12px 16px",
          border: 0,
          "&:hover": {
            color: designColors.grayScale[100]
          },
          "& .MuiTypography-root": {
            fontSize: 14
          }
        },
        head: {
          height: 40,
          fontSize: 12,
          fontWeight: 600,
          flexGrow: 1,
          margin: "0px 10px",
          color: designColors.grayScale[100],
          borderBottom: "1px solid " + designColors.grayScale[40],
          whiteSpace: "nowrap",
          userSelect: "none",
          "&:hover": {
            background: designColors.grayScale[0]
          },
          "& .MuiTypography-root": {
            fontSize: 12
          }
        },
        paddingCheckbox: {
          padding: "6px"
        }
      },
      variants: [
        {
          props: { variant: "secondary" },
          style: {
            fontWeight: 400,
            color: designColors.grayScale[80]
          }
        },
        {
          props: { variant: "important" },
          style: {
            fontWeight: 600,
            color: designColors.grayScale[100]
          }
        },
        {
          props: { variant: "alert" },
          style: {
            fontWeight: 400,
            color: designColors.alert[50]
          }
        },
        {
          props: { variant: "success" },
          style: {
            fontWeight: 400,
            color: designColors.success[100]
          }
        },
        {
          props: { variant: "warning" },
          style: {
            fontWeight: 400,
            color: designColors.warning[50]
          }
        }
      ]
    },
    MuiTableSortLabel: {
      defaultProps: {
        active: true,
        IconComponent: ArrowDropDownSharp
      },
      styleOverrides: {
        root: {
          color: designColors.grayScale[100],
          width: "100%",
          justifyContent: "space-between"
        },
        icon: {
          fontSize: 26,
          fill: designColors.grayScale[100]
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          marginRight: 0,
          "&:before": {
            borderBottom: 0
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: 0
          },
          "&:after": {
            borderBottom: 0
          }
        },
        input: {
          "&:focus": {
            background: "none"
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginRight: "0px"
        }
      }
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small",
        exclusive: true
      },
      styleOverrides: {
        grouped: {
          "&:not(:first-of-type)": {
            borderLeft: "1px solid " + designColors.grayScale[40]
          },
          "&.Mui-selected": {
            borderLeft: "1px solid " + designColors.primary[50]
          },
          "&.Mui-selected + button": {
            borderLeft: "1px solid " + designColors.primary[50]
          }
        },
        firstButton: {
          borderTopRightRadius: "0px !important",
          borderBottomRightRadius: "0px !important"
        },
        middleButton: {
          borderRadius: "0px !important"
        },
        lastButton: {
          borderTopLeftRadius: "0px !important",
          borderBottomLeftRadius: "0px !important"
        },
        root: {
          background: designColors.white
        },
        groupedHorizontal: {
          borderRadius: "6px"
        },
        groupedVertical: {
          borderRadius: "6px"
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: designColors.grayScale[100],
          background: designColors.white,
          textTransform: "capitalize",
          alignItems: "center",
          "&:hover": { background: designColors.primary[0] },
          "&.Mui-selected": {
            background: designColors.primary[50],
            color: designColors.white,
            "&:hover": { background: designColors.primary[50] }
          },
          "&.Mui-disabled": {
            color: designColors.grayScale[60],
            background: designColors.grayScale[5]
          }
        },
        sizeSmall: {
          height: "30px",
          minWidth: "92px",
          fontSize: "12px",
          padding: "7px 16px",
          "& .MuiSvgIcon-root": {
            height: "16px",
            width: "16px"
          }
        },
        sizeMedium: {
          height: "38px",
          minWidth: "119px",
          fontSize: "14px",
          padding: "9px 23px",
          "& .MuiSvgIcon-root": {
            height: "18px",
            width: "18px"
          }
        },
        sizeLarge: {
          height: "46px",
          minWidth: "150px",
          fontSize: "16px",
          padding: "11px 31px",
          "& .MuiSvgIcon-root": {
            height: "20px",
            width: "20px"
          }
        }
      },
      variants: [
        {
          props: { variant: "iconOnly", size: "small" },
          style: { minWidth: "30px", height: "30px" }
        },
        {
          props: { variant: "iconOnly", size: "medium" },
          style: { minWidth: "38px", height: "38px" }
        },
        {
          props: { variant: "iconOnly", size: "large" },
          style: { minWidth: "46px", height: "46px" }
        }
      ]
    },
    MuiList: {
      styleOverrides: {
        padding: {
          padding: 0
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { border: "none" },
          style: { "& .MuiOutlinedInput-notchedOutline": { border: 0 } }
        }
      ]
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          backgroundColor: designColors.primary[100]
        },
        root: {
          backgroundColor: designColors.grayScale[5],
          height: "2px"
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "& > .MuiStepLabel-iconContainer > .MuiSvgIcon-root": {
            color: designColors.primary[50]
          },
          "&.Mui-disabled > .MuiStepLabel-iconContainer > .MuiSvgIcon-root": {
            color: designColors.grayScale[60]
          }
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          "&.Mui-active > .MuiStepConnector-line": {
            borderLeft: "1px solid " + designColors.primary[100]
          }
        },
        line: {
          borderLeftWidth: "1px"
        }
      }
    }
  }
} as ThemeOptions);

export default theme;
