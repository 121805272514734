// @ts-nocheck
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import posthog from "posthog-js";
import qs from "query-string";
import React from "react";
import ReactGA from "react-ga";

import { Helmet } from "react-helmet";
import { notify } from "../Components/CustomNotifications";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import Images from "../Themes/Images";
import queryString from "query-string";
import { setItemInLocalStorage } from "../Utils/StorageHelper";
import { PROFILE_PIC, onboardingSteps, roles } from "../Utils/Types";
import { isValidEmail } from "../Utils/UserUtils";
import styles from "./styles/LoginStyle";

const LoginSplashScreen = React.lazy(
  () => import("../Components/LoginSplashScreen")
);
const api = API.create();

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

class Login extends React.Component {
  constructor(props: any) {
    super(props);

    let qsParse = qs.parse(this.props.location.search);
    let urlEmail = qsParse.email;
    let next_url = qsParse.next_url;

    if (!urlEmail && next_url && qs.parse(next_url.split("?")[1]).email) {
      urlEmail = qs.parse(next_url.split("?")[1]).email;
    }

    let newUser = qsParse.newUser === "true" || qsParse.newUser === true;
    this.state = {
      urlEmail: urlEmail,
      email: urlEmail ? urlEmail : "",
      password: "",
      errorTitle: "",
      errorMessage: "",
      showErrorDialog: false,
      isLogin: !newUser,
      loading: false,
      failedToConnect: false,
      redirecting: false,
      askForPassword: true,
      type: roles.WRITER,
      newUser: newUser,
      webflowAccessToken: "",
      topMessage: "Put your content marketing on autopilot",
      bottomMessage:
        "Everything you need to create, distribute, and measure content across SEO, email, and social"
    };
  }

  componentDidMount = () => {
    let qs = queryString.parse(this.props.location.search);
    if (qs?.code) {
      this.setState({
        webflowAccessToken: qs?.code,
        isLogin: false,
        askForPassword: true,
        topMessage: "The easiest way to publish to Webflow",
        bottomMessage:
          "One-click publishing to your Webflow blog, case study, and resource pages. With tools for collaboration, SEO optimization, approvals, and assisted writing built-in."
      });
    }

    if (this.props.location.state) {
      this.setState({
        failedToConnect: this.props.location.state.failedToConnect
      });
    }

    ReactGA.pageview("login");

    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          this.setState({ redirecting: true });
          let user = result.user;

          if (!user.email) {
            this.logoutAndShowEmailRequired();
            return;
          }

          firebase.auth().currentUser.updateProfile({
            email: user.email
          });

          this.setState({ redirecting: true, loading: true }, () => {
            this.registerUser(user.email);
            return;
          });
        } else if (firebase.auth().currentUser) {
          let user = firebase.auth().currentUser;

          this.setState({ redirecting: true, loading: true }, () => {
            this.registerUser(user.email);
            return;
          });
        } else {
          this.setState({ loading: true }, () => {
            this.autoLoginPostCollaborator();
          });
        }
      })
      .catch(async (error) => {
        if (error.code === "auth/web-storage-unsupported") {
          notify.show(
            "You need to allow cookies to login via Google or Twitter. Enable them for this site or login with your email",
            "error"
          );
          return;
        }
        if (error.code === "auth/account-exists-with-different-credential") {
          let email = error.email;

          const providers = await firebase
            .auth()
            .fetchSignInMethodsForEmail(email);

          if (providers.length > 0) {
            this.setState({
              errorMessage: `Looks like you’ve already signed up with this email via ${providers[0]}. Please login via ${providers[0]}.`,
              errorTitle: "Error",
              showErrorDialog: true
            });
            return;
          }
        }
        console.log(error);
      });
  };

  //Handling shared post auto-login
  autoLoginPostCollaborator = () => {
    try {
      let { next_url, pid } = qs.parse(this.props.location.search);

      if (!pid) {
        this.setState({
          loading: false
        });
        return;
      }

      //If pid (permissionId) exists, handle auto login
      api.autoLoginPostCollaborator(pid, (res: any) => {
        if (res.status === 200) {
          firebase
            .auth()
            .signInWithCustomToken(res.data.customToken)
            .then((user) => {
              setItemInLocalStorage(PROFILE_PIC, res.data.profilePic);

              window.location.replace(next_url);
            })
            .catch((err) => {
              this.setState({
                loading: false
              });
            });
        } else {
          this.setState({
            loading: false
          });
        }
      });
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  logoutAndShowEmailRequired = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        notify.show(
          "We couldn't get an email from your Twitter. Please sign in with Google or email",
          "error"
        );
        window.localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        });
      });
  };

  registerUser = (email: any) => {
    const { type, webflowAccessToken } = this.state;

    posthog.identify(firebase.auth().currentUser.uid, { email: email });

    let guessedTimeZoneFromBrowser =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    api.registerUser(type, guessedTimeZoneFromBrowser, (res: any) => {
      if (res.status === 200) {
        setItemInLocalStorage(PROFILE_PIC, res.data.profilePic);

        let search = qs.parse(this.props.location.search);
        if (search.next_url) {
          window.location.replace(search.next_url);
          return;
        }
        let {
          newUser,
          publicationId,
          publicationsExist,
          isFreelancer,
          isTimeZoneDifferent,
          onboardingStep,
          teamInviteToken,
          email,
          existingWorkspace,
          isPublicationWhitelisted
        } = res.data;

        if (
          !isPublicationWhitelisted &&
          !newUser &&
          !teamInviteToken &&
          !existingWorkspace
        ) {
          firebase
            .auth()
            .signOut()
            .then(() => {
              window.localStorage.clear();
              this.setState({
                redirecting: false,
                loading: false,
                errorMessage:
                  "This account is not whitelisted. Please contact sales@letterdrop.com.",
                errorTitle: "Error",
                showErrorDialog: true
              });
              setItemInLocalStorage(PROFILE_PIC, "");
            });

          return;
        }

        if (teamInviteToken) {
          this.props.history.push(
            `/acceptteaminvite/${teamInviteToken._id}?email=${email}`
          );
        } else if (existingWorkspace) {
          this.props.history.push(
            `/existingworkspace/invite?email=${email}&workspace=${existingWorkspace._id}`
          );
        } else if (
          (type && type === roles.WRITER && newUser) ||
          !publicationsExist
        ) {
          this.props.history.push(
            isFreelancer
              ? "/agency/writer-projects"
              : `/getstarted${
                  webflowAccessToken ? "?code=" + webflowAccessToken : ""
                }`
          );
        } else if (isTimeZoneDifferent) {
          this.props.history.push("/account?backpage=workspaces");
        } else if (publicationId) {
          if (onboardingStep === onboardingSteps.CLIENT_PREFERENCES) {
            this.props.history.push(
              `/getstarted?id=${publicationId}&step=${onboardingSteps.CLIENT_PREFERENCES}`
            );
          } else if (onboardingStep === onboardingSteps.CLIENT_INFO) {
            this.props.history.push(
              `/getstarted?id=${publicationId}&step=${onboardingSteps.CLIENT_INFO}`
            );
          } else if (
            [onboardingSteps.TRIAL_INFO, onboardingSteps.CARD_INFO].includes(
              onboardingStep
            )
          ) {
            this.props.history.push(
              `/getstarted?id=${publicationId}&step=${onboardingSteps.TRIAL_INFO}`
            );
          } else {
            this.props.history.push(
              `${
                webflowAccessToken
                  ? `/webflowcallback?code=${webflowAccessToken}&state=${publicationId}`
                  : `/${publicationId}/create/linkedin/`
              }`
            );
          }
        } else {
          this.props.history.push(
            `/workspaces${
              webflowAccessToken ? "?code=" + webflowAccessToken : ""
            }`
          );
        }
      } else {
        this.setState({
          redirecting: false,
          failedToConnect: true,
          loading: false
        });
      }
    });
  };

  handleLogin = () => {
    const { email, password, urlEmail } = this.state;
    if (email === "" && password === "") {
      this.setState({
        errorMessage: "Enter email and password to login",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    } else if (email === "") {
      this.setState({
        errorMessage: "Enter email",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    } else if (password === "") {
      this.setState({
        errorMessage: "Enter password",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    } else if (urlEmail && email !== urlEmail) {
      this.setState({
        errorMessage:
          "Login with the invited email, " + urlEmail + " to proceed",

        showErrorDialog: true
      });
    } else {
      this.setState({ loading: true });
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              this.registerUser(email);
            })
            .catch((error) => {
              let errorMessage = "";
              let errorTitle = "";
              if (error.code === "auth/user-not-found") {
                // Just sign them up instead
                this.handleSignUp();
                return;
              } else if (error.code === "auth/invalid-email") {
                errorMessage = "That's not a valid email address";
              } else if (error.code === "auth/wrong-password") {
                errorTitle = "Wrong password";
                errorMessage = "You seem to have entered an incorrect password";
              } else if (error.code === "auth/network-request-failed") {
                errorMessage = "Something is wrong with the network. Try again";
              } else {
                errorMessage = error.message;
              }

              this.setState({
                errorMessage: errorMessage,
                loading: false,
                errorTitle: errorTitle ? errorTitle : "Error",
                showErrorDialog: true
              });
            });
        })
        .catch((error) =>
          this.setState({
            errorMessage: error.message,
            loading: false,
            errorTitle: "Error",
            showErrorDialog: true
          })
        );
    }
  };

  forgotPassword = () => {
    const { email } = this.state;

    if (!isValidEmail(email)) {
      return this.setState({
        errorMessage: "Enter valid email to reset password",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          errorMessage: "A password reset email has been sent to you",
          errorTitle: "Check your email",
          showErrorDialog: true
        });
      })
      .catch((error) =>
        this.setState({
          errorMessage: error.message,
          loading: false,
          errorTitle: "Error",
          showErrorDialog: true
        })
      );
  };

  handleSignUp = () => {
    const { email, password } = this.state;
    if (email === "" && password === "") {
      this.setState({
        errorMessage: "Enter email and password to create an account",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    } else if (email === "") {
      this.setState({
        errorMessage: "Enter email",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    } else if (password === "") {
      this.setState({
        errorMessage: "Enter password",
        errorTitle: "We need more information",
        showErrorDialog: true
      });
    } else {
      this.setState({ loading: true });

      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(() => {
              firebase.auth().currentUser.sendEmailVerification();
              this.registerUser(email);
            })
            .catch((error) => {
              let errorMessage = "";
              let errorTitle = "Error";
              if (error.code === "auth/email-already-in-use") {
                errorTitle = "We already have an account with that email";
                errorMessage =
                  "Check your email inbox. We've sent you a login link.";
                this.loginWithEmail(true);
              } else if (error.code === "auth/invalid-email") {
                errorMessage = "That's not a valid email address";
              } else if (error.code === "auth/weak-password") {
                errorMessage =
                  "Your password needs to be at least 6 characters";
              } else {
                errorMessage = error.message;
              }

              this.setState({
                errorMessage,
                loading: false,
                errorTitle,
                showErrorDialog: true
              });
            });
        });
    }
  };

  handleChange = (event: any) => {
    if (event.target.name === "email") {
      this.setState({ email: event.target.value.trim() });
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  loginWithGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  loginWithTwitter = () => {
    var provider = new firebase.auth.TwitterAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  handleEnterKey = (event: any) => {
    let { isLogin } = this.state;
    if (event.key === "Enter") {
      if (isLogin) this.handleLogin();
      else {
        this.handleSignUp();
      }
    }
  };

  loginWithEmail = (forFreelancer = false) => {
    let { email } = this.state;
    if (email === "") {
      this.setState({
        errorMessage: "Enter an email address so we can send you a login link",
        errorTitle: "We need your email address",
        showErrorDialog: true
      });
    } else {
      let REDIRECT_URL = FRONTEND_URL;
      let url = REDIRECT_URL + "/completesignin?email=" + email + "&continue=";

      let search = qs.parse(this.props.location.search);
      if (search.next_url) {
        url = url + search.next_url.split(REDIRECT_URL)[1];
      } else {
        url = url + "/workspaces";
      }

      let actionCodeSettings = {
        url: url,
        handleCodeInApp: true
      };

      firebase
        .auth()

        .sendSignInLinkToEmail(this.state.email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem("emailForSignIn", this.state.email);
          if (forFreelancer) {
            return;
          }
          this.setState({
            errorMessage: "We sent you an email with a link to login",
            errorTitle: "Check your inbox",
            showErrorDialog: true
          });
        })
        .catch((error) => {
          this.setState({
            errorMessage: "That doesn't seem like a valid email",
            errorTitle: "",
            showErrorDialog: true
          });
          console.log("error while sendSignInLinkToEmail ::" + error);
        });
    }
  };

  showNewUserAlert = () => {
    return (
      <Container>
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert elevation={6} severity="info">
            Create new password to complete signup process
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  };

  render() {
    let { classes } = this.props;

    const {
      isLogin,

      redirecting,

      showErrorDialog,

      errorMessage,

      errorTitle,

      loading,

      askForPassword,

      type,

      newUser,

      topMessage,

      bottomMessage
    } = this.state;

    const signupView = (
      <React.Fragment>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs>
            <StyledButton
              fullWidth
              className={classes.submit}
              onClick={this.handleSignUp}
            >
              Create an account
            </StyledButton>
          </Grid>

          <Grid item className={classes.bottomLink}>
            <Link
              variant="body2"
              underline="none"
              className={classes.bottomLink}
              onClick={() =>
                this.setState({ isLogin: true, askForPassword: true })
              }
            >
              {"Already have an account? Login"}
            </Link>
          </Grid>
        </Grid>
      </React.Fragment>
    );

    const loginView = (
      <React.Fragment>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <div className={classes.relativePosition}>
              {loading ? (
                <div className={classes.submit}>
                  <CircularProgress size={24} />
                </div>
              ) : (
                <>
                  <StyledButton
                    className={classes.submit}
                    onClick={() => {
                      askForPassword
                        ? this.handleLogin()
                        : this.loginWithEmail();
                    }}
                  >
                    {askForPassword ? "Login" : "Send login link"}
                  </StyledButton>

                  <StyledButton
                    className={classes.emailLink}
                    variant="secondary"
                    onClick={() => {
                      askForPassword
                        ? this.setState({
                            isLogin: true,
                            askForPassword: false
                          })
                        : this.setState({
                            isLogin: true,
                            askForPassword: true
                          });
                    }}
                  >
                    {askForPassword
                      ? "Email login link"
                      : "Login with password"}
                  </StyledButton>
                </>
              )}
            </div>
          </Grid>

          <Grid item className={classes.bottomLink}>
            <Link
              variant="body2"
              underline="none"
              className={classes.bottomLink}
              onClick={() =>
                this.setState({ isLogin: false, askForPassword: true })
              }
            >
              {"Don't have an account? Create one"}
            </Link>
          </Grid>

          <Grid item xs className={classes.bottomLink}>
            <Link
              onClick={this.forgotPassword}
              variant="body2"
              underline="none"
              className={classes.bottomLink}
            >
              I forgot my password
            </Link>
          </Grid>
        </Grid>
      </React.Fragment>
    );

    if (redirecting) {
      return <div className={classes.redirectingLabel}>Loading...</div>;
    }

    return (
      <div className={classes.container}>
        <Helmet>
          <title>Login | Letterdrop</title>
        </Helmet>
        {newUser && !isLogin && this.showNewUserAlert()}

        <CssBaseline />

        <Grid container direction="row" style={{ height: "100%" }}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <div className={classes.paper}>
              <img src={Images.logo} className={classes.logoText} alt="logo" />

              <form className={classes.form} noValidate>
                <Grid container direction="column">
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    spacing={1}
                    justifyContent="center"
                  >
                    {/* <Grid item>
                      <StyledButton
                        variant="tertiary"
                        // sx={{
                        //   border: "1px solid rgba(0, 0, 0, 0.23)",
                        //   ":hover": {
                        //     border: "1px solid rgba(0, 0, 0, 0.23)"
                        //   }
                        // }}
                        onClick={this.loginWithTwitter}
                        className={classes.loginButton}
                        startIcon={
                          <img
                            src={Images.twitterLogo}
                            alt="twitterlogo"
                            className={classes.iconButton}
                          />
                        }
                      >
                        Login with Twitter
                      </StyledButton>
                    </Grid> */}

                    <Grid item>
                      <StyledButton
                        // sx={{
                        //   border: "1px solid rgba(0, 0, 0, 0.23)",
                        //   ":hover": {
                        //     border: "1px solid rgba(0, 0, 0, 0.23)"
                        //   }
                        // }}
                        variant="tertiary"
                        onClick={this.loginWithGoogle}
                        className={classes.loginButton}
                        startIcon={
                          <img
                            src={Images.googleLogo}
                            alt="googlelogo"
                            className={classes.iconButton}
                          />
                        }
                      >
                        Login with Google
                      </StyledButton>
                    </Grid>
                  </Grid>

                  <Grid item container justifyContent="center">
                    <Grid item>
                      <Typography className={classes.margin10}>or</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <StyledInput
                  size="medium"
                  required
                  fullWidth
                  id="email"
                  // label="Email address"
                  name="email"
                  autoComplete="email"
                  placeholder="tom@letterdrop.com"
                  autoFocus
                  color="primary"
                  style={{
                    marginBottom: 10
                  }}
                  InputLabelProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    className: classes.input
                  }}
                  value={this.state.email}
                  onChange={this.handleChange}
                  onKeyPress={this.handleEnterKey}
                />
                {askForPassword ? (
                  <StyledInput
                    size="medium"
                    required
                    fullWidth
                    color="primary"
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    InputLabelProps={{
                      className: classes.input
                    }}
                    InputProps={{
                      className: classes.input
                    }}
                    value={this.state.password}
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnterKey}
                  />
                ) : (
                  <div />
                )}
                {isLogin ? loginView : signupView}
              </form>

              <Typography variant="body1" className={classes.error}>
                {this.state.failedToConnect
                  ? "Oops! We can't reach our servers right now. Check your network and try again"
                  : ""}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <LoginSplashScreen />
          </Grid>
        </Grid>

        <StyledDialog
          size="sm"
          open={showErrorDialog}
          title={errorTitle}
          body={errorMessage}
          closeCallback={() => {
            this.setState({
              showErrorDialog: false,
              errorMessage: "",
              errorTitle: ""
            });
          }}
          additionalButtons={
            (errorTitle === "Wrong password" && (
              <Grid container direction="row-reverse">
                <Grid item className={classes.marginLeft20}>
                  <StyledButton
                    size="medium"
                    onClick={() => {
                      this.setState(
                        {
                          showErrorDialog: false,
                          errorMessage: "",
                          errorTitle: ""
                        },
                        () => {
                          this.loginWithEmail();
                        }
                      );
                    }}
                    autoFocus
                  >
                    Email me a login link
                  </StyledButton>
                </Grid>

                <Grid item>
                  <StyledButton
                    size="medium"
                    variant="secondary"
                    onClick={() => {
                      this.setState(
                        {
                          showErrorDialog: false,
                          errorMessage: "",
                          errorTitle: ""
                        },
                        () => this.forgotPassword()
                      );
                    }}
                  >
                    Reset password
                  </StyledButton>
                </Grid>
              </Grid>
            )) || (
              <StyledButton
                size="medium"
                onClick={() => {
                  this.setState({
                    showErrorDialog: false,
                    errorMessage: "",
                    errorTitle: ""
                  });
                }}
                autoFocus
              >
                OK
              </StyledButton>
            )
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Login);
