// @ts-nocheck
// a library to wrap and simplify api calls
import apisauce from "./apisauce";
import { type AxiosResponse } from "axios";
import firebase from "firebase/app";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

// our "constructor"
const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": SERVER_URL,
      "Access-Control-Allow-Methods": "GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    // 10 second timeout...
    timeout: 180000
  });

  const createApiCall = async (endpoint: any, params: any, callback: any) => {
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/ai/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createPublicApiCall = (endpoint: any, params: any, callback: any) => {
    api.post("/ai/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const generateHeadlines = (
    publicationId: any,
    postId: any,
    keyword: any,
    workingTitle: any,
    callback: any
  ) => {
    createApiCall(
      "generateheadlines",
      {
        publicationId,
        postId,
        keyword,
        workingTitle
      },
      callback
    );
  };

  const generateParagraphs = (
    publicationId: any,
    postId: any,
    sectionHeader: any,
    firstLineOfParagraph: any,
    title: any,
    callback: any
  ) => {
    createApiCall(
      "generateparagraphs",
      {
        publicationId,
        postId,
        sectionHeader,
        firstLineOfParagraph,
        title
      },
      callback
    );
  };

  const generateIdeaFromKeyword = (
    publicationId: any,
    keyword: any,
    audience: any,
    generateMore: any,
    callback: any
  ) => {
    createApiCall(
      "generateideafromkeyword",
      { publicationId, keyword, audience, generateMore },
      callback
    );
  };

  const generateSectionHeaders = (
    publicationId: any,
    title: any,
    subtitle: any,
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "generatesectionheaders",
      { publicationId, title, subtitle, keyword, postId },
      callback
    );
  };

  const generateSectionOutline = (
    publicationId: any,
    title: any,
    header: any,
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "generatesectionoutline",
      { publicationId, title, header, keyword, postId },
      callback
    );
  };

  const generateParagraphFromOutline = (
    publicationId: any,
    header: any,
    points: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "generateparagraphfromoutline",
      { publicationId, header, points, postId },
      callback
    );
  };

  const generateIntroConclusion = (
    publicationId: any,
    title: any,
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "generateintroconclusion",
      {
        publicationId,
        title,
        keyword,
        postId
      },
      callback
    );
  };

  const generateStyleCorrection = (
    publicationId: any,
    correctionName: any,
    inputText: any,
    correctionRuleId: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "generatestylecorrection",
      {
        publicationId,
        correctionName,
        inputText,
        correctionRuleId,
        postId
      },
      callback
    );
  };

  const generateLinkedInComments = (body: any, comment: any, callback: any) => {
    createPublicApiCall(
      "generatelinkedincomments",
      {
        body,
        comment
      },
      callback
    );
  };

  const generatePodcastSummary = (
    publicationId: any,
    postId: any,
    url: any,
    type: any,
    regenerate: any,
    instructions: any,
    metadata: any,
    urlMetadata: any,
    callback: any
  ) => {
    createApiCall(
      "generatepodcastsummary",
      {
        publicationId,
        postId,
        url,
        type,
        regenerate,
        instructions,
        metadata,
        urlMetadata
      },
      callback
    );
  };

  const generateTextSummary = (
    {
      publicationId,
      text,
      url,
      type,
      regenerate,
      instructions,
      metadata,
      postText
    }: any,
    callback: any
  ) => {
    createApiCall(
      "generatetextsummary",
      {
        publicationId,
        text,
        url,
        type,
        regenerate,
        instructions,
        metadata,
        postText
      },
      callback
    );
  };

  const getPodcastTranscript = (
    type: PodcastType,
    url: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getPodcastRawTranscript", { type, url }, callback);
  };

  const generateStyleGuideSuggestion = (
    publicationId: any,
    postId: any,
    regenerate: any,
    callback: any
  ) => {
    createApiCall(
      "generatestyleguidesuggestion",
      {
        publicationId,
        postId,
        regenerate
      },
      callback
    );
  };

  return {
    generateHeadlines,
    generateParagraphs,
    generateIdeaFromKeyword,
    generateSectionHeaders,
    generateSectionOutline,
    generateParagraphFromOutline,
    generateIntroConclusion,
    generateStyleCorrection,
    generateLinkedInComments,
    generatePodcastSummary,
    generateStyleGuideSuggestion,
    generateTextSummary,
    getPodcastTranscript
  };
};

export default {
  create
};
