// @ts-nocheck
// a library to wrap and simplify api calls
import apisauce from "./apisauce";
import firebase from "firebase/app";

import { isValidObjectId } from "../Utils/numberUtils";
import {
  LinkedInAccount,
  SalesCallInsightsData
} from "../Containers/LinkedInShare";
import { AxiosResponse } from "axios";
import {
  LinkedInUser,
  SortOrderEnum,
  channelTypes,
  filterValue,
  marketingCampaign,
  teamRoles
} from "../Utils/Types";
import { Moment } from "moment";
import { optionType } from "../Containers/SocialDrafts";
import {
  Companies,
  Geography,
  Seniority
} from "../Components/AccountMonitoring";
import {
  KeywordMonitoringGroupType,
  keywordNotificationSettings
} from "../Containers/KeywordMonitoringGroups";
import {
  IFilterOptions,
  IUsersFilterOptions
} from "../Containers/LinkedInActivityLog";
import { LinkedinIndustry } from "../Containers/KeywordMonitoringGroupFilters";
import { CampaignOutboundConfig } from "../Components/LeadCampaignPosts";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let GRAIN_REDIRECT_URL = process.env.REACT_APP_GRAIN_REDIRECT_URL;
let GRAIN_CLIENT_ID = process.env.REACT_APP_GRAIN_CLIENT_ID;
let GRAIN_BASEURL = (redirectURL, redirectPage) =>
  `https://grain.com/_/public-api/oauth2/authorize?client_id=${GRAIN_CLIENT_ID}&redirect_uri=${redirectURL}&response_type=code&state=${redirectPage}`;

// our "constructor"
const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": SERVER_URL,
      "Access-Control-Allow-Methods": "GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    // 10 second timeout...
    timeout: 720000 // 12 minute
  });

  const isValidPublicationId = (params: any, callback: any) => {
    let { publicationId = null } = params;
    //check if publicationId is valid ObjectId with regex else return error to callback
    let regex = /^[0-9a-fA-F]{24}$/;
    if (publicationId && !regex.test(publicationId)) {
      callback({ status: 400, data: "Invalid publication" });
      return false;
    }
    return true;
  };

  const createApiCall = async (endpoint: any, params: any, callback: any) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/app/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createSocialApiCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/social/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createUnAuthenticatedCall = (
    endpoint: any,
    params: any,
    callback: any,
    route = "app"
  ) => {
    api.post("/" + route + "/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createTwitterApiCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/twitter/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createWordpressApiCall = (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/wordpress/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createLinkedInApiCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/linkedin/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createOutreachApiCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/outreach/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  //dont need firebase validation, this endpoint/function is made public to access from external
  const createLinkedInPublicApiCall = (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    api.post("/linkedin/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createGrainApiCall = (endpoint: any, params: any, callback: any) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.get("/auth/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createZoomApiCall = (endpoint: any, params: any, callback: any) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("zoom/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createFacebookApiCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/facebook/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createInstagramCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/instagram/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createApiCallWithFormData = (
    endpoint: any,
    data: any,
    callback: any
  ) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        data.append("idToken", idToken);
        api.post("/" + endpoint, data).then((res) => {
          callback(res);
        });
      });
  };

  const createWorkflowCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/workflow/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createSEOCall = async (endpoint: any, params: any, callback: any) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/seo/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createPlagiarismCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/plagiarism/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createMediumCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/medium/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createDevtoCall = async (endpoint: any, params: any, callback: any) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/devto/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createHashnodeCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/hashnode/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createWebFlowCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/webflow/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const createDashboardApiCall = (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/dashboard/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createHubSpotApiCall = (endpoint: any, params: any, callback: any) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/hubspot/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createSalesforceApiCall = (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/salesforce/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createCometchatApiCall = (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }

    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/cometchat/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const createRedditApiCall = async (
    endpoint: any,
    params: any,
    callback: any
  ) => {
    if (!isValidPublicationId(params, callback)) {
      return;
    }
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/reddit/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const registerUser = (type: any, timezone: any, callback: any) => {
    createApiCall("registerclient", { type, timezone }, callback);
  };

  const updateTimezone = (timezone: any, callback: any) => {
    createApiCall("updatetimezone", { timezone }, callback);
  };

  const getClient = (callback: any) => {
    createApiCall("getclient", {}, callback);
  };

  const findClientByMail = (email: any, callback: any) => {
    createUnAuthenticatedCall("findclientbymail", { email }, callback);
  };

  const requestInvite = (handle: any, handleType: any, callback: any) => {
    createApiCall("requestinvite", { handle, handleType }, callback);
  };

  const getSessionId = (
    plan: any,
    planId: any,
    clientReferenceId: any,
    clientPreference: string,
    callback: any
  ) => {
    createApiCall(
      "getsessionid",
      { plan, planId, clientReferenceId, clientPreference },
      callback
    );
  };

  const saveBillingConfiguration = (
    paidRooms: any,
    price: any,
    callback: any
  ) => {
    createApiCall("savebillingconfiguration", { paidRooms, price }, callback);
  };

  const verifyInviteCode = (code: any, callback: any) => {
    createApiCall("verifyinvitecode", { code }, callback);
  };

  const checkForExistingWorkspace = (
    isUserCreatingNewWorkspace,
    callback: any
  ) => {
    createApiCall(
      "checkforexistingworkspace",
      {
        isUserCreatingNewWorkspace
      },
      callback
    );
  };

  const askForWorkspaceInvite = (callback: any) => {
    createApiCall("askforworkspaceinviteforclient", {}, callback);
  };

  const createWorkspaceInviteForClient = (
    workspaceInviteId: string,
    callback: any
  ) => {
    createApiCall(
      "createWorkspaceInviteForClient",
      { workspaceInviteId },
      callback
    );
  };

  const savePublication = (
    id: string,
    name: string,
    domain: string,
    logo: string,
    referral: boolean,
    website: string,
    resetModuleAccess: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "savepublication",
      {
        id,
        name,
        domain,
        logo,
        referral,
        website,
        resetModuleAccess
      },
      callback
    );
  };

  const getAllPublications = (
    search: any,
    publicationFilter: any,
    category: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "getallpublications",
      { search, publicationFilter, category },
      callback
    );
  };

  const savePost = (
    id: any,
    title: any,
    subtitle: any,
    text: any,
    type: any,
    status: any,
    action: any,
    scheduledDate: any,
    isPrivate: any,
    editorHTML: any,
    clients: any,
    callback: any
  ) => {
    createApiCall(
      "savepost",
      {
        id,
        title,
        subtitle,
        text,
        type,
        status,
        action,
        scheduledDate,
        isPrivate,
        editorHTML,
        clients
      },
      callback
    );
  };

  const saveAsDraft = (title: any, publicationId: any, callback: any) => {
    createApiCall("saveasdraft", { title, publicationId }, callback);
  };

  const getPosts = (
    status: any,
    publicationId: any,
    limit: any,
    offset: any,
    searchKeyword: any,
    destinationList: any,
    compositeSEORating: any,
    sortOrder: SortOrderEnum,
    callback: any
  ) => {
    createApiCall(
      "getposts",
      {
        status,
        publicationId,
        limit,
        offset,
        searchKeyword,
        destinationList,
        compositeSEORating,
        sortOrder
      },
      callback
    );
  };

  const getSocialPosts = (
    status: any,
    media: any,
    publicationId: any,
    limit: any,
    offset: any,
    searchKeyword: any,
    sortOrder: SortOrderEnum,
    accounts: any,
    callback: any
  ) => {
    createSocialApiCall(
      "getsocialposts",
      {
        status,
        media,
        publicationId,
        limit,
        offset,
        searchKeyword,
        sortOrder,
        accounts
      },
      callback
    );
  };

  const getLinkedInAccountsByPublicationId = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSocialApiCall(
      "getlinkedinaccountsbypublicationid",
      { publicationId },
      callback
    );
  };

  const saveSocialDraft = (publicationId: any, media: any, callback: any) => {
    createSocialApiCall("savesocialdraft", { publicationId, media }, callback);
  };

  const deleteSocialPost = (
    mediaId: any,
    media: any,
    publicationId: any,
    callback: any
  ) => {
    createSocialApiCall(
      "deletesocialpost",
      { mediaId, media, publicationId },
      callback
    );
  };

  const getPost = (id: any, callback: any) => {
    createApiCall("getpost", { id }, callback);
  };

  const getPublicationPostStats = (
    publicationId: any,
    keyword: any,
    limit: any,
    offset: any,
    postTitleSort: any,
    publishedOnSort: any,
    deliveredSort: any,
    openRateSort: any,
    ctrSort: any,
    pageViewSort: any,
    sortTable: any,
    callback: any
  ) => {
    createApiCall(
      "getpublicationpoststats",
      {
        publicationId,
        keyword,
        limit,
        offset,
        postTitleSort,
        publishedOnSort,
        deliveredSort,
        openRateSort,
        ctrSort,
        pageViewSort,
        sortTable
      },
      callback
    );
  };

  const getPublicationPostImprovements = (
    publicationId: any,
    type: any,
    limit: any,
    offset: any,
    searchQuery: any,
    isTargetKeywordBased: any,
    statusFilters: any,
    callback: any
  ) => {
    createApiCall(
      "getpublicationpostimprovements",
      {
        publicationId,
        type,
        limit,
        offset,
        searchQuery,
        isTargetKeywordBased,
        statusFilters
      },
      callback
    );
  };

  const updatePostImprovementSuggestionStatus = (
    postImprovementId: any,
    status: any,
    callback: any
  ) => {
    createApiCall(
      "updatepostimprovementsuggestionstatus",
      {
        postImprovementId,
        status
      },
      callback
    );
  };

  const getPageCountForContentRefreshTabs = (
    publicationId: any,
    searchQuery: any,
    callback: any
  ) => {
    createApiCall(
      "getpagecountforcontentrefreshtabs",
      {
        publicationId,
        searchQuery
      },
      callback
    );
  };

  const getPublicationPostCount = (publicationId: any, callback: any) => {
    createApiCall("getpublicationpostcount", { publicationId }, callback);
  };

  const getPostWebStat = (
    postId: string,
    publicationId: string,
    startDate: string,
    endDate: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getpostwebstat",
      { postId, publicationId, startDate, endDate },
      callback
    );
  };

  const getPublicationForReferral = (publicationId: any, callback: any) => {
    createUnAuthenticatedCall(
      "getpublicationforreferral",
      { publicationId },
      callback
    );
  };

  const addReferralSubscriber = (
    publicationId: any,
    email: any,
    ref: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "addreferralsubscriber",
      { publicationId, email, ref },
      callback
    );
  };

  const getEmbedDetail = (post: any, callback: any) => {
    createApiCall("getembeddetail", { post }, callback);
  };

  const getUnsubscribeData = (subscriberId: any, callback: any) => {
    createApiCall("getunsubscribedata", { subscriberId }, callback);
  };

  const saveProfile = (name: any, fromOnboarding: any, callback: any) => {
    createApiCall("saveprofile", { name, fromOnboarding }, callback);
  };

  const saveProfilePicture = (profilePic: any, callback: any) => {
    createApiCall("saveprofilepicture", { profilePic }, callback);
  };

  const getPublication = (domain: any, callback: any) => {
    createUnAuthenticatedCall("getpublication", { domain }, callback);
  };

  const saveCard = (stripeToken: any, email: any, callback: any) => {
    createUnAuthenticatedCall(
      "savecard",
      {
        stripeToken: stripeToken,
        email: email
      },
      callback
    );
  };

  const unpublishPost = (id: any, callback: any) => {
    createApiCall("unpublishpost", { id }, callback);
  };

  const unschedulePost = (id: any, callback: any) => {
    createApiCall("unschedulepost", { id }, callback);
  };

  const duplicatePost = (id: any, callback: any) => {
    createApiCall("duplicatepost", { id }, callback);
  };

  const addPageView = (postId: any, callback: any) => {
    createUnAuthenticatedCall("addpageview", { postId }, callback, "feed");
  };

  const getPublications = (callback: any) => {
    createApiCall("getPublications", {}, callback);
  };

  const saveEmailDetails = (
    id: any,
    fromName: any,
    physicalAddress: any,
    copyright: any,
    emailFooter: any,
    callback: any
  ) => {
    createApiCall(
      "saveemaildetails",
      { id, fromName, physicalAddress, copyright, emailFooter },
      callback
    );
  };

  const saveStyleGuide = (
    publicationId: any,
    styleGuide: any,
    callback: any
  ) => {
    createApiCall("savestyleguide", { publicationId, styleGuide }, callback);
  };

  const postToWebflowStaging = (
    publicationId: any,
    postId: any,
    collectionId: any,
    callback: any
  ) => {
    createWebFlowCall(
      "posttowebflowstaging",
      { publicationId, postId, collectionId },
      callback
    );
  };

  const importFromWebflow = (
    publicationId: string,
    destination: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWebFlowCall(
      "importfromwebflow",
      { publicationId, destination },
      callback
    );
  };

  const getPipelinesFromHubspot = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall("getPipelines", { publicationId }, callback);
  };

  const setHubspotPipeline = (
    publicationId: string,
    pipelines: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall("setPipeline", { publicationId, pipelines }, callback);
  };

  const getCRMcontactsConfigFromHubspot = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall("getCRMcontactsConfig", { publicationId }, callback);
  };

  const saveCRMcontactsConfigFromHubspot = (
    publicationId: string,
    hubId: string,
    contactsPropertiesMapping: any,
    linkedInActivityPush: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall(
      "saveCRMcontactsConfig",
      {
        publicationId,
        contactsPropertiesMapping,
        linkedInActivityPush,
        hubId
      },
      callback
    );
  };

  const getPipelineFieldsFromSalesforce = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSalesforceApiCall("getPipelineFields", { publicationId }, callback);
  };

  const setSalesforcePipelineFields = (
    publicationId: string,
    pipelineField: string,
    pipelines: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createSalesforceApiCall(
      "setPipelineFields",
      { publicationId, pipelineField, pipelines },
      callback
    );
  };

  const getContactFieldsFromSalesforce = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSalesforceApiCall("get-contact-fields", { publicationId }, callback);
  };

  const getSalesforceConfig = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSalesforceApiCall(
      "get-salesforce-config",
      { publicationId },
      callback
    );
  };

  const setSalesforceContactFieldMapping = (
    publicationId: string,
    mapping: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createSalesforceApiCall(
      "set-contact-field-mapping",
      { publicationId, contactFieldMapping: mapping },
      callback
    );
  };

  const setLinkedInActivityPushToSalesforce = (
    publicationId: string,
    linkedInActivityPush: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createSalesforceApiCall(
      "set-linkedin-activity-push",
      { publicationId, linkedInActivityPush },
      callback
    );
  };

  const importFromHubspot = (
    publicationId: string,
    blogId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall(
      "importfromhubspot",
      { publicationId, blogId },
      callback
    );
  };

  const importFromWordpress = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWordpressApiCall("importfromwordpress", { publicationId }, callback);
  };

  const publishPost = (
    id: any,
    coverImage: any,
    coverImageOriginal: any,
    type: any,
    scheduledDate: any,
    isPrivate: any,
    socialThumbnail: any,
    socialTitle: any,
    socialDescription: any,
    slug: any,
    tags: any,
    publishedOn: any,
    showPostCoverPhoto: any,
    emailVerified: any,
    blogPostOnly: any,
    customFields: any,
    isFromApproval: any,
    callback: any
  ) => {
    createApiCall(
      "publish",
      {
        id,
        coverImage,
        type,
        scheduledDate,
        isPrivate,
        socialThumbnail,
        socialTitle,
        socialDescription,
        slug,
        tags,
        publishedOn,
        showPostCoverPhoto,
        emailVerified,
        coverImageOriginal,
        blogPostOnly,
        customFields,
        isFromApproval
      },
      callback
    );
  };

  const saveReviewDetails = (
    id: any,
    coverImage: any,
    type: any,
    scheduledDate: any,
    isPrivate: any,
    socialThumbnail: any,
    socialTitle: any,
    socialDescription: any,
    tags: any,
    showPostCoverPhoto: any,
    coverImageOriginal: any,
    replyTo: any,
    blogPostOnly: any,
    isMetaDescriptionDirty: any,
    isSlugDirty: any,
    slug: any,
    customFields: any,
    isPublishedElsewhere: any,
    callback: any
  ) => {
    createApiCall(
      "savereviewdetails",
      {
        id,
        coverImage,
        type,
        scheduledDate,
        isPrivate,
        socialThumbnail,
        socialTitle,
        socialDescription,
        tags,
        showPostCoverPhoto,
        coverImageOriginal,
        replyTo,
        blogPostOnly,
        isMetaDescriptionDirty,
        isSlugDirty,
        slug,
        customFields,
        isPublishedElsewhere
      },
      callback
    );
  };

  const getPublicationById = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getpublicationbyid", { publicationId }, callback);
  };

  const getPublicationPublicData = (publicationId: any, callback: any) => {
    createApiCall("getpublicationpublicdata", { publicationId }, callback);
  };

  const getWebflowAndBlogDetails = (publicationId: any, callback: any) => {
    createApiCall("getwebflowandblogdetails", { publicationId }, callback);
  };

  const autoPopulateWebflowCollectionFields = (
    publicationId: any,
    collectionFields: any,
    mappedFields: any,
    customFields: any,
    collectionId: string,
    callback: any
  ) => {
    createApiCall(
      "autopopulatewebflowcollectionfields",
      {
        publicationId,
        collectionFields,
        mappedFields,
        customFields,
        collectionId
      },
      callback
    );
  };

  const getReviewDetails = (id: any, callback: any) => {
    createApiCall("getreviewdetails", { id }, callback);
  };

  const savePublicationPicture = (id: any, logo: any, callback: any) => {
    createApiCall("savepublicationpicture", { id, logo }, callback);
  };

  const savePublicationField = (
    id: any,
    object: any,
    value: any,
    callback: any
  ) => {
    createApiCall("savepublicationfield", { id, object, value }, callback);
  };

  const enableWelcomeEmail = (
    sendWelcomeEmail: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "enablewelcomeemail",
      { sendWelcomeEmail, publicationId },
      callback
    );
  };

  const saveTitle = (id: any, title: any, subTitle: any, callback: any) => {
    createApiCall("savetitle", { id, title, subTitle }, callback);
  };

  const saveEmailDetailsField = (
    id: any,
    object: any,
    value: any,
    callback: any
  ) => {
    createApiCall("saveemaildetailsfield", { id, object, value }, callback);
  };

  const saveSignupViaEmail = (id: any, value: any, callback: any) => {
    createApiCall("savesignupviaemail", { id, value }, callback);
  };

  const saveCustomDomain = (publicationId: any, domain: any, callback: any) => {
    createApiCall("savedomain", { publicationId, domain }, callback);
  };
  const saveEmailDomain = (
    publicationId: any,
    username: any,
    senderDomain: any,
    callback: any
  ) => {
    createApiCall(
      "saveemaildomain",
      { publicationId, username, senderDomain },
      callback
    );
  };

  const deletePost = (id: any, callback: any) => {
    createApiCall("deletepost", { id }, callback);
  };

  const getEmailDomainStatus = (publicationId: any, callback: any) => {
    createApiCall("getemaildomainstatus", { publicationId }, callback);
  };

  const getConnectedAccount = (publicationId: any, callback: any) => {
    createTwitterApiCall("connectedaccount", { publicationId }, callback);
  };

  const updateTwitterScheduledDate = (
    tweetId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "updatescheduleddate",
      { tweetId, scheduledDate, publicationId },
      callback
    );
  };

  const updateFacebookScheduledDate = (
    facebookPostId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createFacebookApiCall(
      "updatescheduleddate",
      { facebookPostId, scheduledDate, publicationId },
      callback
    );
  };

  const updateInstagramScheduledDate = (
    instagramPostId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createInstagramCall(
      "updatescheduleddate",
      { instagramPostId, scheduledDate, publicationId },
      callback
    );
  };

  const updateRedditScheduledDate = (
    redditPostId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createRedditApiCall(
      "updatescheduleddate",
      { redditPostId, scheduledDate, publicationId },
      callback
    );
  };

  const updateLinkedInScheduledDate = (
    linkedInPostId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "updatescheduleddate",
      { linkedInPostId, scheduledDate, publicationId },
      callback
    );
  };

  const updatePlaceholderScheduledDate = (
    placeholderId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "updateplaceholderscheduleddate",
      { placeholderId, scheduledDate, publicationId },
      callback
    );
  };

  const updateScheduledPostDate = (
    postId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "updatescheduledpostddate",
      { postId, scheduledDate, publicationId },
      callback
    );
  };

  const updateScheduledProjectDate = (
    postId: any,
    topicId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "updatescheduledprojectdate",
      { postId, topicId, scheduledDate, publicationId },
      callback
    );
  };

  const recalculateProjectDates = (
    postId: any,
    topicId: any,
    scheduledDate: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "recalculateprojectdates",
      { postId, topicId, scheduledDate, publicationId },
      callback
    );
  };

  const connectToTwitter = (
    publicationId: any,
    fallbackPage: any,
    params = {}
  ) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/twitter/connecttotwitter?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId +
            "&fallback=" +
            fallbackPage +
            "&params=" +
            encodeURIComponent(JSON.stringify(params)),
          "_self"
        );
      });
  };
  //dont need firebase validation, this endpoint/function is made public to access from external
  const connectToLinkedIn = (
    publicationId: any,
    fallback: any,
    postId: any,
    params: any = {}
  ) => {
    window.open(
      SERVER_URL +
        "/linkedin/connectolinkedin?publicationId=" +
        publicationId +
        "&fallback=" +
        fallback +
        "&postId=" +
        postId +
        "&params=" +
        encodeURIComponent(JSON.stringify(params)),
      "_self"
    );
  };

  const connectToFacebook = (
    publicationId: any,
    fallback: any,
    postId: any,
    params = {}
  ) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/facebook/connect?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId +
            "&fallback=" +
            fallback +
            "&postId=" +
            postId +
            "&params=" +
            encodeURIComponent(JSON.stringify(params)),
          "_self"
        );
      });
  };

  const initiateGrainIntegration = (
    publicationId: string,
    redirectPage?: "integrationsettings" | "getstarted"
  ) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          GRAIN_BASEURL(
            GRAIN_REDIRECT_URL + `/${publicationId}`,
            redirectPage || "integrationsettings"
          ),
          "_self"
        );
      });
  };

  const createGrainAccessToken = (
    code: any,
    publicationId: any,
    callback: any
  ) => {
    createGrainApiCall("grain/callback", { code, publicationId }, callback);
  };

  const createZoomAccessToken = (
    code: any,
    publicationId: any,
    callback: any
  ) => {
    createZoomApiCall("get-access-token", { code, publicationId }, callback);
  };

  const createLinkedInAccessToken = (
    oauth_code: any,
    parameters: any,
    callback: any
  ) => {
    createLinkedInPublicApiCall(
      "createlinkedinaccesstoken",
      { oauth_code, parameters },
      callback
    );
  };

  const setLinkedInApprovalEmail = (
    publicationId: string,
    email: string,
    accountId: string,
    callback: any
  ) => {
    createLinkedInPublicApiCall(
      "setlinkedinapprovalemail",
      { publicationId, email, accountId },
      callback
    );
  };

  const createTwitterAccessToken = (
    oauth_token: any,
    oauth_verifier: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "createaccesstoken",
      { oauth_token, oauth_verifier },
      callback
    );
  };

  const saveFacebookAccessToken = (
    oauth_token: any,
    parameters: any,
    callback: any
  ) => {
    createFacebookApiCall(
      "savefacebookaccesstoken",
      { oauth_token, parameters },
      callback
    );
  };

  const connectClientToTwitter = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL + "/twitter/connectotwitterforclient?idToken=" + idToken,
          "_self"
        );
      });
  };

  const createTwitterAccessTokenforClient = (
    oauth_token: any,
    oauth_verifier: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "createaccesstokenforclient",
      { oauth_token, oauth_verifier },
      callback
    );
  };

  const addAnalytics = (
    post: any,
    referrer: any,
    isEmail: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "addanalytics",
      { post, referrer, isEmail },
      callback,
      "analytics"
    );
  };

  const getSourcesForPost = (
    limit: any,
    offset: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "getsourcesforpost",
      {
        postId,
        limit,
        offset
      },
      callback
    );
  };

  const validateHtml = (
    html: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("validateHtml", { html }, callback);
  };

  const getHeaderDetails = (publicationId: any, callback: any) => {
    createApiCall("getheaderdetails", { publicationId }, callback);
  };

  const getComments = (postId: any, callback: any) => {
    createUnAuthenticatedCall("getcomments", { postId }, callback);
  };

  const saveComment = (postId: any, comment: any, callback: any) => {
    createApiCall("savecomment", { postId, comment }, callback);
  };

  const getReplies = (commentId: any, callback: any) => {
    createUnAuthenticatedCall("getreplies", { commentId }, callback);
  };

  const saveCommentReply = (commentId: any, reply: any, callback: any) => {
    createApiCall("savecommentreply", { commentId, reply }, callback);
  };

  const getLatestPosts = (postId: any, callback: any) => {
    createUnAuthenticatedCall("getlatestposts", { postId }, callback);
  };

  const refreshToken = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("refreshtoken", { publicationId }, callback);
  };

  const saveWebhookUrls = (
    publicationId: string,
    engagedLeadUrls: string[],
    scrapedLeadUrls: string[],
    keywordMonitoredPostUrls: string[],
    publishedUrls: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "savewebhookurls",
      {
        publicationId,
        engagedLeadUrls,
        scrapedLeadUrls,
        keywordMonitoredPostUrls,
        publishedUrls
      },
      callback
    );
  };

  const testWebhookUrl = (
    publicationId: string,
    url: string,
    type: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "testWebhookUrl",
      {
        publicationId,
        url,
        type
      },
      callback
    );
  };

  const saveFeedbackSettings = (
    publicationId: any,
    message: any,
    formUrl: any,
    formType: any,
    callback: any
  ) => {
    createApiCall(
      "savefeedbacksettings",
      { publicationId, message, formUrl, formType },
      callback
    );
  };

  const addCollaborator = (postId: any, email: any, callback: any) => {
    createApiCall("addcollaborator", { postId, email }, callback);
  };

  const saveTemplate = (
    templateId: any,
    title: any,
    subtitle: any,
    text: any,
    editorHTML: any,
    publicationId: any,
    templateType: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "savetemplate",
      {
        templateId,
        title,
        subtitle,
        text,
        editorHTML,
        publicationId,
        templateType,
        postId
      },
      callback
    );
  };

  const deleteIdeaTemplate = (ideaTemplateId: any, callback: any) => {
    createApiCall(
      "deleteideatemplate",
      {
        ideaTemplateId
      },
      callback
    );
  };

  const setDefaultIdeaTemplate = (
    publicationId: any,
    ideaTemplateId: any,
    isDefault: any,
    callback: any
  ) => {
    createApiCall(
      "setdefaultideatemplate",
      {
        publicationId,
        ideaTemplateId,
        isDefault
      },
      callback
    );
  };

  const saveIdeaTemplate = (
    publicationId: any,
    ideaTemplateId: any,
    name: any,
    status: any,
    defaultValues: any,
    callback: any
  ) => {
    createApiCall(
      "saveideatemplate",
      {
        publicationId,
        ideaTemplateId,
        name,
        status,
        defaultValues
      },
      callback
    );
  };

  const getAllTemplates = (publicationId: any, callback: any) => {
    createApiCall("getalltemplates", { publicationId }, callback);
  };

  const getIdeaTemplates = (publicationId: any, callback: any) => {
    createApiCall("getideatemplates", { publicationId }, callback);
  };

  const getTemplate = (templateId: any, callback: any) => {
    createApiCall("gettemplate", { templateId }, callback);
  };

  const deleteTemplate = (templateId: any, callback: any) => {
    createApiCall("deletetemplate", { templateId }, callback);
  };

  const createTemplatePost = (
    publicationId: any,
    templateParam: any,
    postId: any,
    category: any,
    callback: any
  ) => {
    createApiCall(
      "createposttemplate",
      { publicationId, templateParam, postId, category },
      callback
    );
  };

  const fetchTemplateData = (
    templateDetails: any,
    category: any,
    callback: any
  ) => {
    createApiCall("fetchtemplatedata", { templateDetails, category }, callback);
  };

  const checkIsOwnPublication = (publicationId: any, callback: any) => {
    createApiCall("checkisownpublication", { publicationId }, callback);
  };

  const getSourcesForPublication = (publicationId: any, callback: any) => {
    createApiCall(
      "getsourcesforpublication",
      {
        publicationId
      },
      callback
    );
  };

  const getConnectedLinkedinAccounts = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "getConnectedLinkedinAccounts",
      { publicationId },
      callback
    );
  };

  const getThirdPartyPosts = (
    publicationId: string,
    searchQuery: string,
    showFirstPartyPosts: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-third-party-posts",
      { publicationId, searchQuery, showFirstPartyPosts },
      callback
    );
  };

  const getApolloSequences = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall("get-apollo-sequences", { publicationId }, callback);
  };

  const getOutreachSequences = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createOutreachApiCall("get-sequences", { publicationId }, callback);
  };

  const getApolloEmailAccounts = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-apollo-email-accounts",
      { publicationId },
      callback
    );
  };

  const getOutreachEmailAccounts = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createOutreachApiCall("get-mailboxes", { publicationId }, callback);
  };

  const addContactToApolloSequences = (
    publicationId: string,
    sequenceId: string,
    emailAccountId: string,
    accounts: LinkedInUser[],
    outboundTemplateEmailId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "add-contact-to-apollo-sequences",
      {
        publicationId,
        sequenceId,
        emailAccountId,
        accounts,
        outboundTemplateEmailId
      },
      callback
    );
  };

  const addContactToOutreachSequences = (
    publicationId: string,
    sequenceId: string,
    emailAccountId: string,
    userId: string,
    accounts: LinkedInUser[],
    outboundTemplateEmailId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createOutreachApiCall(
      "add-contact-to-sequence",
      {
        publicationId,
        sequenceId,
        emailAccountId,
        userId,
        accounts,
        outboundTemplateEmailId
      },
      callback
    );
  };

  const actOnLeads = (
    publicationId: string,
    accounts: LinkedInUser[],
    linkedInAccount: string,
    outboundTemplateDmId: string,
    outboundTemplateEmailId: string,
    slackNotificationGroupId?: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "act-on-leads",
      {
        publicationId,
        accounts,
        linkedInAccount,
        outboundTemplateDmId,
        outboundTemplateEmailId,
        slackNotificationGroupId
      },
      callback
    );
  };

  const getScrapeLimit = (
    vanityName: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall("get-engagement-limits", { vanityName }, callback);
  };

  const getLinkedInConnectedAccountDetails = (
    publicationId: string,
    vanityName: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-connected-account-details",
      { publicationId, vanityName },
      callback
    );
  };

  const getLinkedInConnectedAccountActivityLog = (
    publicationId: string,
    vanityName: string,
    page: number,
    limit: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-connected-account-activity-log",
      { publicationId, vanityName, page, limit },
      callback
    );
  };

  const loadConnectedAccounts = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("loadconnectedaccounts", { publicationId }, callback);
  };

  const getLinkedinAccountLocations = (
    searchTerm: string,
    locationType: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "getLinkedinAccountLocations",
      { searchTerm, locationType },
      callback
    );
  };

  const disconnectAccount = (
    publicationId: string,
    accountType: any,
    userId: any,
    isCRM: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "disconnectaccount",
      { publicationId, accountType, userId, isCRM },
      callback
    );
  };

  const savePublishToSocialFromPreviousPost = (
    publicationId: any,
    accountType: any,
    value: any,
    callback: any
  ) => {
    createApiCall(
      "savepublishtosocialfrompreviouspost",
      { publicationId, accountType, value },
      callback
    );
  };

  const saveFontChanges = (
    element: any,
    value: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "savefontchanges",
      { element, value, publicationId },
      callback
    );
  };

  const resetWelcomeEmail = (publicationId: any, callback: any) => {
    createApiCall("resetwelcomeemail", { publicationId }, callback);
  };

  const resetContentMapTemplate = (publicationId: any, callback: any) => {
    createApiCall("resetcontentmap", { publicationId }, callback);
  };

  const resetStyleGuide = (publicationId: any, callback: any) => {
    createApiCall("resetstyleguide", { publicationId }, callback);
  };

  const getFilteredPosts = (
    query: any,
    publicationId: any,
    headerPostId: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "getfilteredposts",
      { query, publicationId, headerPostId },
      callback
    );
  };

  const getPostPictures = (postId: any, callback: any) => {
    createApiCall("getpostpictures", { postId }, callback);
  };

  const searchUnsplash = (searchQuery: any, callback: any) => {
    createApiCall("searchimage", { searchQuery }, callback);
  };

  const markAsDownload = (downloadUrl: any, callback: any) => {
    createApiCall("unsplashdownload", { downloadUrl }, callback);
  };

  const savePreamble = (id: any, text: any, htmlText: any, callback: any) => {
    createApiCall("savepreamble", { id, text, htmlText }, callback);
  };

  const saveTemplatePreamble = (
    id: any,
    text: any,
    htmlText: any,
    callback: any
  ) => {
    createApiCall("savetemplatepreamble", { id, text, htmlText }, callback);
  };

  const removePreamble = (id: any, callback: any) => {
    createApiCall("removepreamble", { id }, callback);
  };

  const enablePostFeedback = (
    toggle: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall("enablepostfeedback", { toggle, publicationId }, callback);
  };

  const toggleSendBlogNewsletter = (
    sendBlogNewsletter: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "togglesendblognewsletter",
      { sendBlogNewsletter, publicationId },
      callback
    );
  };

  const saveContentMapSettings = (
    key: any,
    value: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "savecontentmapsettings",
      { key, value, publicationId },
      callback
    );
  };

  const toggleFeedbackVote = (type: any, publicationId: any, callback: any) => {
    createApiCall("togglefeedbackvote", { type, publicationId }, callback);
  };

  const saveFeedbackVotes = (
    feedback: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall("savefeedbackvotes", { feedback, publicationId }, callback);
  };

  const resetFeedbackVotes = (publicationId: any, callback: any) => {
    createApiCall("resetfeedbackvotes", { publicationId }, callback);
  };

  const openThankYouTemplate = (publicationId: any, callback: any) => {
    createApiCall("openthankyoutemplate", { publicationId }, callback);
  };

  const resetThankYouTemplate = (publicationId: any, callback: any) => {
    createApiCall("resetthankyoutemplate", { publicationId }, callback);
  };

  const loadThankYouTemplate = (domain: any, email: any, callback: any) => {
    createUnAuthenticatedCall(
      "loadthankyoutemplate",
      { domain, email },
      callback
    );
  };

  const getCollaborators = (postId: any, callback: any) => {
    createApiCall("getcollaborators", { postId }, callback);
  };

  const savePostAuthor = (postId: any, postAuthorId: any, callback: any) => {
    createApiCall("savepostauthor", { postId, postAuthorId }, callback);
  };

  const addImportRequest = (publicationId: any, url: any, callback: any) => {
    createApiCall("addImportRequest", { publicationId, url }, callback);
  };

  const savePublicationDeliverability = (
    publicationId: any,
    detectInactiveReaders: any,
    callback: any
  ) => {
    createApiCall(
      "savepublicationdeliverability",
      { publicationId, detectInactiveReaders },
      callback
    );
  };

  const getInstagramData = (mediaUrl: any, callback: any) => {
    createUnAuthenticatedCall("getinstagramdata", { mediaUrl }, callback);
  };

  const saveCheckoutSubscription = (
    sessionId: any,
    planId: any,
    plan: any,
    callback: any
  ) => {
    createApiCall(
      "savecheckoutsubscription",
      { sessionId, planId, plan },
      callback
    );
  };

  const changeFeaturedPost = (postId: any, changeFlag: any, callback: any) => {
    createApiCall("changefeaturedpost", { postId, changeFlag }, callback);
  };

  const connectToWebflow = (publicationId: any, reconnect: string = false) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          `${SERVER_URL}/webflow/connectwebflow?publicationId=${publicationId}&idToken=${idToken}&reconnect=${reconnect}`,
          "_self"
        );
      });
  };

  const getWebflowAccessToken = (
    code: any,
    publicationId: any,
    isReconnect: boolean,
    callback: any
  ) => {
    createWebFlowCall(
      "getaccesstoken",
      { code, publicationId, isReconnect },
      callback
    );
  };

  const saveSelectedWebflowCollection = (
    publicationId: any,
    selectedSite: any,
    collectionId: any,
    siteName: any,
    published: any,
    importPosts: any,
    mappedFields: any,
    blogs: any,
    collectionName: any,
    accountId: any,
    callback: any
  ) => {
    createWebFlowCall(
      "saveselectedcollection",
      {
        publicationId,
        collectionId,
        siteName,
        selectedSite,
        published,
        importPosts,
        mappedFields,
        blogs,
        collectionName,
        accountId
      },
      callback
    );
  };

  const getGoogleFonts = (callback: any) => {
    createApiCall("getgooglefonts", {}, callback);
  };

  const saveSocialLinks = (
    publicationId: any,
    socialLinks: any,
    callback: any
  ) => {
    createApiCall("savesociallinks", { publicationId, socialLinks }, callback);
  };

  const getMapData = (id: any, type: any, idToken: any, callback: any) => {
    createUnAuthenticatedCall("getmapdata", { id, type, idToken }, callback);
  };

  const migrate = (
    file: any,
    publicationId: any,
    products: any,
    migrationKey: any,
    callback: any
  ) => {
    let data = new FormData();
    data.append("file", file);
    data.append("publicationId", publicationId);
    data.append("products", products);
    data.append("migrationKey", migrationKey);
    createApiCallWithFormData("stripe/migrate", data, callback);
  };

  const subscribeToFreePlan = (
    publicationId: any,
    email: any,
    idToken: any,
    isLoggedIn: any,
    ref: any,
    postId: any,
    embedSubscribeInput: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "subscribetofreeplan",
      {
        publicationId,
        email,
        idToken,
        isLoggedIn,
        ref,
        postId,
        embedSubscribeInput
      },
      callback
    );
  };

  const saveEmailNotifications = (
    publicationId: any,
    notificationType: any,
    isActive: any,
    callback: any
  ) => {
    createApiCall(
      "saveemailnotifications",
      { publicationId, notificationType, isActive },
      callback
    );
  };

  const sendTeamInvitation = (
    publicationId: string,
    email: string,
    role: teamRoles,
    topicId: string | null,
    resendingInvite?: boolean = false,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "sendteaminvite",
      { publicationId, email, role, topicId, resendingInvite },
      callback
    );
  };

  const getTeamInviteURL = (email: any, callback: any) => {
    createApiCall("getteaminviteurl", { email }, callback);
  };

  const checkIsMobile = (callback: any) => {
    createUnAuthenticatedCall("checkismobile", {}, callback);
  };

  const acceptTeamInvite = (
    token: string,
    email: string,
    idToken: string | null,
    callback: (res: AxiosResponse) => void
  ) => {
    createUnAuthenticatedCall(
      "acceptteaminvite",
      { token, email, idToken },
      callback
    );
  };

  const teamInviteExists = (
    token: string,
    email: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createUnAuthenticatedCall("teamInviteExists", { token, email }, callback);
  };

  const getTeammates = (publicationId: any, callback: any) => {
    createApiCall("getteammates", { publicationId }, callback);
  };

  const getAuthorsAndDefaultAuthor = (publicationId: any, callback: any) => {
    createApiCall("getauthorsanddefaultauthor", { publicationId }, callback);
  };

  const saveDefaultAuthor = (
    publicationId: any,
    defaultAuthorId: any,
    callback: any
  ) => {
    createApiCall(
      "savedefaultauthor",
      { publicationId, defaultAuthorId },
      callback
    );
  };

  const saveDefaultSyntaxHighlightingLanguage = (
    publicationId: any,
    defaultSyntaxHighlightingLanguage: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "savedefaultsyntaxhighlightinglanguage",
      { publicationId, defaultSyntaxHighlightingLanguage, postId },
      callback
    );
  };

  const getTeammembersAndApprovers = (publicationId: any, callback: any) => {
    createApiCall("getteammembersandapprovers", { publicationId }, callback);
  };

  const removeTeammate = (
    publicationId: any,
    teammateId: any,
    callback: any
  ) => {
    createApiCall("removeteammate", { publicationId, teammateId }, callback);
  };

  const getPublicationCreatedOn = (publicationId: any, callback: any) => {
    createApiCall("getpublicationcreatedon", { publicationId }, callback);
  };

  const convertAtMentionToOrganization = (
    body: any,
    accountId: any,
    publication: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "convertatmentiontoorganization",
      { body, accountId, publication },
      callback
    );
  };

  const getCalendarEvents = (
    publicationId: any,
    startDate: any,
    endDate: any,
    topicId: any,
    selectedPublications: any,
    allPublicationSelected: any,
    expandedDeadlines: any,
    channelFilters = [],
    statusFilters = [],
    assigneeFilters = [],
    labelFilters = [],
    campaignFilters: filterValue[] = [],
    callback: any
  ) => {
    createApiCall(
      "getcalendarevents",
      {
        publicationId,
        startDate,
        endDate,
        topicId,
        selectedPublications,
        allPublicationSelected,
        expandedDeadlines,
        channelFilters,
        statusFilters,
        assigneeFilters,
        labelFilters,
        campaignFilters
      },
      callback
    );
  };

  const getOverviewDetails = (publicationId: any, callback: any) => {
    createApiCall("getoverviewdetails", { publicationId }, callback);
  };

  const saveGetStartedEvents = (
    publicationId: any,
    getStartedEvents: any,
    callback: any
  ) => {
    createApiCall(
      "savegetstartedevents",
      { publicationId, getStartedEvents },
      callback
    );
  };

  const getUpcomingActions = (
    publicationId: any,
    visibility: any,
    callback: any
  ) => {
    createDashboardApiCall(
      "getupcomingactions",
      { publicationId, visibility },
      callback
    );
  };

  const getUpcomingPosts = (publicationId: any, callback: any) => {
    createDashboardApiCall("getupcomingposts", { publicationId }, callback);
  };

  const getRecentlyPublishedPost = (publicationId: any, callback: any) => {
    createDashboardApiCall(
      "getrecentlypublishedpost",
      { publicationId },
      callback
    );
  };

  const getRequestPostDetails = (publicationId: any, callback: any) => {
    createApiCall("getrequestpostdetails", { publicationId }, callback);
  };

  const savePostRequest = (
    publicationId: any,
    postRequestId: any,
    targetPublishDate: any,
    tags: any,
    owner: any,
    description: any,
    draftPostId: any,
    callback: any
  ) => {
    createApiCall(
      "savepostrequest",
      {
        publicationId,
        postRequestId,
        targetPublishDate,
        tags,
        owner,
        description,
        draftPostId
      },
      callback
    );
  };

  const saveCalenderEvent = (
    eventId: any,
    title: any,
    date: any,
    publicationId: any,
    owner: any,
    createNewEvent: any,
    oldRruleString: any,
    newRruleString: any,
    topicId: any,
    reviewDeadline: any,
    postId: any,
    contentMapDeadline: any,
    freelancerInvited: any,
    approvers: any,
    placeholderEvent: any,
    callback: any
  ) => {
    createApiCall(
      "savecalenderevent",
      {
        eventId,
        title,
        date,
        publicationId,
        owner,
        createNewEvent,
        oldRruleString,
        newRruleString,
        topicId,
        reviewDeadline,
        postId,
        contentMapDeadline,
        freelancerInvited,
        approvers,
        placeholderEvent
      },
      callback
    );
  };

  const deleteCalenderEvent = (eventId: any, rule: any, callback: any) => {
    createApiCall(
      "deletecalenderevent",
      {
        eventId,
        rule
      },
      callback
    );
  };

  const saveAccentColor = (publicationId: any, color: any, callback: any) => {
    createApiCall("saveaccentcolor", { publicationId, color }, callback);
  };

  const getTimeToOpenGraphData = (
    id: any,
    type: any,
    idToken: any,

    callback: any
  ) => {
    createUnAuthenticatedCall(
      "gettimetoopengraphdata",
      { id, idToken, type },
      callback
    );
  };

  const getEmailEngagementStats = (
    publicationId: any,
    startDate: any,
    endDate: any,
    type: any,
    callback: any
  ) => {
    createApiCall(
      "getemailengagementstats",
      { publicationId, startDate, endDate, type },
      callback
    );
  };

  const getCustomFields = (
    publicationId: any,
    customFieldsType: any,
    callback: any
  ) => {
    createApiCall(
      "getcustomfields",
      { publicationId, customFieldsType },
      callback
    );
  };

  const saveCustomFields = (
    publicationId: any,
    customFields: any,
    customFieldsType: any,
    callback: any
  ) => {
    createApiCall(
      "savecustomfields",
      { publicationId, customFields, customFieldsType },
      callback
    );
  };

  const saveProjectCustomFields = (
    publicationId: any,
    customFields: any,
    callback: any
  ) => {
    createWorkflowCall(
      "saveprojectcustomfields",
      { publicationId, customFields },
      callback
    );
  };

  const getProjectCustomFields = (publicationId: any, callback: any) => {
    createWorkflowCall("getprojectcustomfields", { publicationId }, callback);
  };

  const uploadExternalImage = (
    sourceUrl: any,
    helperId: any,
    callback: any
  ) => {
    createApiCall("uploadexternalimage", { sourceUrl, helperId }, callback);
  };

  const getEmbed = (url: any, options: any, callback: any) => {
    createApiCall("getembed", { url, options }, callback);
  };

  const saveSubscribeText = (publicationId: any, text: any, callback: any) => {
    createApiCall("savesubscribetext", { publicationId, text }, callback);
  };

  const getTopics = (
    publicationId: any,
    status: any,
    limit: any,
    offset: any,
    searchKeyword: any,
    sortBy: any,
    filterBy: any,
    filterByValue: any,
    replaceTopics: any,
    difficultySort: any,
    createdOnSort: any,
    thumbsUpCountSort: any,
    searchVolumeSort: any,
    domainAuthoritySort: any,
    searchIntentSort: any,
    funnelStageSort: any,
    conversionLikelihoodSort: any,
    topFormatsSort: any,
    customSortByField: any,
    customSortDirection: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettopics",
      {
        publicationId,
        status,
        limit,
        offset,
        searchKeyword,
        sortBy,
        filterBy,
        filterByValue,
        replaceTopics,
        difficultySort,
        createdOnSort,
        thumbsUpCountSort,
        searchVolumeSort,
        domainAuthoritySort,
        searchIntentSort,
        funnelStageSort,
        conversionLikelihoodSort,
        topFormatsSort,
        customSortByField,
        customSortDirection
      },
      callback
    );
  };

  const getTopicsDetail = (
    publicationId: any,
    status: any,
    limit: any,
    offset: any,
    searchKeyword: any,
    sortBy: any,
    filterBy: any,
    filterByValue: any,
    replaceTopics: any,
    difficultySort: any,
    createdOnSort: any,
    thumbsUpCountSort: any,
    searchVolumeSort: any,
    domainAuthoritySort: any,
    searchIntentSort: any,
    funnelStageSort: any,
    conversionLikelihoodSort: any,
    topFormatsSort: any,
    customSortByField: any,
    customSortDirection: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettopicsdetail",
      {
        publicationId,
        status,
        limit,
        offset,
        searchKeyword,
        sortBy,
        filterBy,
        filterByValue,
        replaceTopics,
        difficultySort,
        createdOnSort,
        thumbsUpCountSort,
        searchVolumeSort,
        domainAuthoritySort,
        searchIntentSort,
        funnelStageSort,
        conversionLikelihoodSort,
        topFormatsSort,
        customSortByField,
        customSortDirection
      },
      callback
    );
  };

  const getProposedTopics = (
    publicationId: any,
    limit: any,
    offset: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getproposedtopics",
      {
        publicationId,
        limit,
        offset
      },
      callback
    );
  };

  const getTopic = (topicId: any, callback: any) => {
    createWorkflowCall("gettopic", { topicId }, callback);
  };

  const getDraftTopicDetails = (publicationId: any, callback: any) => {
    createWorkflowCall("getdrafttopicdetails", { publicationId }, callback);
  };

  const getDraftIdeaTemplateDetails = (publicationId: any, callback: any) => {
    createWorkflowCall(
      "getdraftideatemplatedetails",
      { publicationId },
      callback
    );
  };

  const getTopicComments = (
    topicId: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettopiccomments",
      { topicId, publicationId },
      callback
    );
  };

  const saveTopic = (
    topicId: any,
    title: any,
    description: any,
    descriptionText: any,
    labels: any,
    campaign: marketingCampaign,
    publicationId: any,
    keyword: any,
    status: any,
    files: any,
    customFields: any,
    selectedChannel: any,
    selectedIdeaTemplateId: any,
    anonymousClient = {},
    callback: any
  ) => {
    createWorkflowCall(
      "savetopic",
      {
        topicId,
        title,
        description,
        descriptionText,
        labels,
        campaign,
        publicationId,
        keyword,
        status,
        files,
        customFields,
        selectedChannel,
        selectedIdeaTemplateId,
        anonymousClient
      },
      callback
    );
  };

  const createTopicFromPost = (
    title: any,
    publicationId: any,
    selectedChannel: any,
    postId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "createtopicfrompost",
      {
        title,
        publicationId,
        selectedChannel,
        postId
      },
      callback
    );
  };

  const saveTopicTemplate = (
    templateId: any,
    templateName: any,
    title: any,
    description: any,
    descriptionText: any,
    labels: any,
    publicationId: any,
    keyword: any,
    status: any,
    files: any,
    customFields: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savetopictemplate",
      {
        templateId,
        templateName,
        title,
        description,
        descriptionText,
        labels,
        publicationId,
        keyword,
        status,
        files,
        customFields
      },
      callback
    );
  };

  const updateTopicDetails = (
    topicId: any,
    author: any,
    contentStages: any,
    labels: any,
    callback: any
  ) => {
    createWorkflowCall(
      "updatetopicdetails",
      {
        topicId,
        author,
        contentStages,
        labels
      },
      callback
    );
  };

  const voteTopic = (topicId: any, anonymousClient: any, callback: any) => {
    createWorkflowCall("votetopic", { topicId, anonymousClient }, callback);
  };

  const archiveTopic = (topicId: any, callback: any) => {
    createWorkflowCall("archivetopic", { topicId }, callback);
  };

  const moveProjectToIdeas = (topicId: any, callback: any) => {
    createWorkflowCall("moveprojecttoideas", { topicId }, callback);
  };

  const unarchiveTopic = (topicId: any, callback: any) => {
    createWorkflowCall("unarchivetopic", { topicId }, callback);
  };

  const deleteTopic = (topicId: any, callback: any) => {
    createWorkflowCall("deletetopic", { topicId }, callback);
  };

  const changePostToInReview = (postid: any, callback: any) => {
    createApiCall("changeposttoinreview", { postid }, callback);
  };

  const undeletePost = (postid: any, callback: any) => {
    createApiCall("undeletepost", { postid }, callback);
  };

  const sendReviewCommentToAuthor = (postId: any, callback: any) => {
    createWorkflowCall("sendreviewcommenttoauthor", { postId }, callback);
  };

  const saveTopicComment = (
    topicId: any,
    comment: any,
    files: any,
    timezone: any,
    status: any,
    id: any,
    anonymousClient: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savetopiccomment",
      { topicId, comment, files, timezone, status, id, anonymousClient },
      callback
    );
  };

  const getDashboardOverview = (publicationId: any, callback: any) => {
    createDashboardApiCall("getdashboarddetails", { publicationId }, callback);
  };

  const getEmailEngagementForDashboard = (
    publicationId: any,
    callback: any
  ) => {
    createDashboardApiCall("getemailengagement", { publicationId }, callback);
  };

  const saveAudioPostsToggle = (
    publicationId: any,
    audioPostsToggle: any,
    callback: any
  ) => {
    createApiCall(
      "saveaudiopoststoggle",
      { publicationId, audioPostsToggle },
      callback
    );
  };

  const saveFooter = (id: any, text: any, htmlText: any, callback: any) => {
    createApiCall("savefooter", { id, text, htmlText }, callback);
  };

  const saveTemplateFooter = (
    id: any,
    text: any,
    htmlText: any,
    callback: any
  ) => {
    createApiCall("savetemplatefooter", { id, text, htmlText }, callback);
  };

  const removeFooter = (id: any, callback: any) => {
    createApiCall("removefooter", { id }, callback);
  };

  const getDashboardPageViews = (publicationId: any, callback: any) => {
    createDashboardApiCall(
      "getdashboardpageviews",
      { publicationId },
      callback
    );
  };

  const getDashboardPageViewGraph = (publicationId: any, callback: any) => {
    createDashboardApiCall(
      "getdashboardpageviewgraph",
      { publicationId },
      callback
    );
  };

  const getDashboardEmailsOpen = (publicationId: any, callback: any) => {
    createDashboardApiCall(
      "getdashboardemailsopen",
      { publicationId },
      callback
    );
  };

  const getDashboardEmailsOpenGraph = (publicationId: any, callback: any) => {
    createDashboardApiCall(
      "getdashboardemailsopengraph",
      { publicationId },
      callback
    );
  };

  const getProjectBoardCards = (
    publicationId: any,
    assignees: any,
    assignedBy: any,
    approvers: any,
    dueDate: any,
    searchKeyword: any,
    fromAgency: any,
    allPublicationSelected: any,
    publicationFilter: any,
    campaignFilter: filterValue[],
    showCards: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getprojectboardcards",
      {
        publicationId,
        assignees,
        assignedBy,
        approvers,
        dueDate,
        searchKeyword,
        fromAgency,
        allPublicationSelected,
        publicationFilter,
        campaignFilter,
        showCards
      },
      callback
    );
  };

  const updateTopicStatus = (
    topicId: any,
    status: any,
    activity: any,
    callback: any
  ) => {
    createWorkflowCall(
      "updatetopicstatus",
      { topicId, status, activity },
      callback
    );
  };

  const saveTopicTitle = (
    topicId: any,
    publicationId: any,
    title: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savetopictitle",
      { topicId, publicationId, title },
      callback
    );
  };

  const sendContentMapFeedback = (
    topicId: any,
    feedback: any,
    callback: any
  ) => {
    createWorkflowCall(
      "sendcontentmapfeedback",
      { topicId, feedback },
      callback
    );
  };

  const downloadExport = (requestId: any, idToken: any, callback: any) => {
    createApiCall("downloadexport", { requestId, idToken }, callback);
  };

  const downloadRecipient = (requestId: any, idToken: any, callback: any) => {
    createApiCall("downloadrecipient", { requestId, idToken }, callback);
  };

  const getPageAccessDetails = (callback: any) => {
    createApiCall("getpageaccessdetails", {}, callback);
  };

  const changeTeammateRole = (
    publicationId: any,
    teammateId: any,
    role: any,
    callback: any
  ) => {
    createApiCall(
      "changeteammaterole",
      { publicationId, teammateId, role },
      callback
    );
  };

  const saveTweetText = (postId: any, tweet: any, callback: any) => {
    createApiCall("savetweettext", { postId, tweet }, callback);
  };

  const saveAutoPostToSocialToggle = (
    publicationId: any,
    postId: any,
    autoPostToSocial: any,
    type: any,
    callback: any
  ) => {
    createApiCall(
      "saveautoposttosocialtoggle",
      { publicationId, postId, autoPostToSocial, type },
      callback
    );
  };
  const savePostSocialContent = (
    postId: any,
    contentObject: any,
    callback: any
  ) => {
    createApiCall("savepostsocialcontent", { postId, contentObject }, callback);
  };

  const getContentMap = (id: any, callback: any) => {
    createWorkflowCall("getcontentmap", { id }, callback);
  };

  const saveContentMapChanges = (
    id: any,
    text: any,
    fields: any,
    publicationId: any,
    fieldForRevisionHistory: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savecontentmap",
      {
        id,
        text,
        fields,
        publicationId,
        fieldForRevisionHistory
      },
      callback
    );
  };

  const requestApprovalForContentMap = (id: any, callback: any) => {
    createWorkflowCall(
      "sendcontentmapforapproval",
      {
        id
      },
      callback
    );
  };

  const approveContentMap = (id: any, callback: any) => {
    createWorkflowCall(
      "approvecontentmap",
      {
        id
      },
      callback
    );
  };

  const sendWPIntegrationRequest = (publicationId: any, callback: any) => {
    createWordpressApiCall(
      "sendwpintegrationrequest",
      { publicationId },
      callback
    );
  };

  const getAllStatsNumbers = (publicationId: any, callback: any) => {
    createDashboardApiCall("getallstatsnumbers", { publicationId }, callback);
  };

  const getFreelancerProjects = (callback: any) => {
    createWorkflowCall("getfreelancerprojects", {}, callback);
  };

  const getProjectChecklist = (topicId: any, callback: any) => {
    createWorkflowCall("getprojectchecklist", { topicId }, callback);
  };

  const saveDownloadLink = (postId: any, url: any, callback: any) => {
    createUnAuthenticatedCall("savedownloadlink", { postId, url }, callback);
  };

  const acceptFreelancerInvite = (
    publicationId: any,
    topicId: any,
    callback: any
  ) => {
    createApiCall(
      "acceptfreelancerinvite",
      { publicationId, topicId },
      callback
    );
  };

  const connectToMedium = (publicationId: any, fallback: any, postId: any) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/medium/connect?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId +
            "&fallback=" +
            fallback +
            "&postId=" +
            postId,
          "_self"
        );
      });
  };

  const verifyAndGetMediumDetails = (
    publicationId: any,
    token: any,
    callback: any
  ) => {
    createMediumCall("verifyandgetdetails", { publicationId, token }, callback);
  };

  const getMediumDetails = (id: any, callback: any) => {
    createMediumCall("getdetails", { id }, callback);
  };

  const saveMediumDetails = (publicationId: any, user: any, callback: any) => {
    createMediumCall("savedetails", { publicationId, user }, callback);
  };

  const saveMediumAuthor = (
    publicationId: any,
    accessToken: any,
    callback: any
  ) => {
    createMediumCall("saveauthor", { publicationId, accessToken }, callback);
  };

  const saveMediumPost = (
    mediumPost: any,
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createMediumCall(
      "savepost",
      { mediumPost, publicationId, postId },
      callback
    );
  };

  const saveDevtoPost = (
    devtoPostId: any,
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createDevtoCall(
      "savepost",
      { devtoPostId, publicationId, postId },
      callback
    );
  };

  const getDevtoPost = (publicationId: any, postId: any, callback: any) => {
    createDevtoCall("getpost", { publicationId, postId }, callback);
  };

  const deleteDevtoPost = (
    devtoPostId: any,
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createDevtoCall(
      "deletepost",
      { devtoPostId, publicationId, postId },
      callback
    );
  };

  const saveDevtoDetails = (publicationId: any, apiKey: any, callback: any) => {
    createDevtoCall("savedetails", { publicationId, apiKey }, callback);
  };

  const saveHashnodePost = (
    hashnodePostId: any,
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createHashnodeCall(
      "savepost",
      { hashnodePostId, publicationId, postId },
      callback
    );
  };

  const getHashnodePost = (publicationId: any, postId: any, callback: any) => {
    createHashnodeCall("getpost", { publicationId, postId }, callback);
  };

  const deleteHashnodePost = (
    hashnodePostId: any,
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createHashnodeCall(
      "deletepost",
      { hashnodePostId, publicationId, postId },
      callback
    );
  };

  const saveHashnodeDetails = (
    publicationId: any,
    apiKey: any,
    username: any,
    host: any,
    callback: any
  ) => {
    createHashnodeCall(
      "savedetails",
      { publicationId, apiKey, username, host },
      callback
    );
  };

  const startFetchingKeywordData = (
    searchKeyword: any,
    relatedKeywordPage: any,
    relatedKeywordRowsPerPage: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "startfetchingkeyworddata",
      {
        searchKeyword,
        relatedKeywordPage,
        relatedKeywordRowsPerPage,
        publicationId
      },
      callback
    );
  };

  const getOrganicPosts = (
    searchKeyword: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getorganicposts",
      { searchKeyword, publicationId },
      callback
    );
  };

  const getBacklinksForOrganicPost = (
    postURL: any,
    searchKeyword: any,
    researchType: any,
    domain: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getbacklinksfororganicpost",
      { postURL, searchKeyword, researchType, domain, publicationId },
      callback
    );
  };

  const getRelatedKeywordsWithPagination = (
    searchKeyword: any,
    relatedKeywordPage: any,
    relatedKeywordRowsPerPage: any,
    sort: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getrelatedkeywordswithpagination",
      {
        searchKeyword,
        relatedKeywordPage,
        relatedKeywordRowsPerPage,
        sort,
        publicationId
      },
      callback
    );
  };

  const getKeywordQuestions = (
    searchKeyword: any,
    page: any,
    rowsPerPage: any,
    sort: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getkeywordquestions",
      { searchKeyword, page, rowsPerPage, sort, publicationId },
      callback
    );
  };

  const getKeywordVariations = (
    searchKeyword: any,
    page: any,
    rowsPerPage: any,
    sort: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getkeywordvariations",
      { searchKeyword, page, rowsPerPage, sort, publicationId },
      callback
    );
  };

  const getKeywordRelated = (
    searchKeyword: any,
    page: any,
    rowsPerPage: any,
    sort: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getkeywordrelated",
      { searchKeyword, page, rowsPerPage, sort, publicationId },
      callback
    );
  };

  const seoContentAnalysis = (
    keyword: any,
    postId: any,
    publicationId: any,
    reanalyze: any,
    callback: any
  ) => {
    createSEOCall(
      "analyze",
      { keyword, postId, publicationId, reanalyze },
      callback
    );
  };

  const assignPostKeywords = (
    fileURL: string | null,
    publicationId: string,
    analyzeSEO: boolean,
    analyzeAllPosts: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "assignpostkeywords",
      { fileURL, publicationId, analyzeSEO, analyzeAllPosts },
      callback
    );
  };

  const assignPostKeywordFromSlug = (
    publicationId: string,
    analyzeSEO: boolean,
    analyzeAllPosts: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "assignpostkeywordfromslug",
      { publicationId, analyzeSEO, analyzeAllPosts },
      callback
    );
  };

  const checkIfFeaturedSnippetIsCovered = (
    keyword: string,
    postId: string,
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "checkiffeaturedsnippetiscovered",
      { keyword, postId, publicationId },
      callback
    );
  };

  const seoLinksAnalysis = (
    keyword: any,
    postId: any,
    publicationId: any,
    reanalyze: any,
    callback: any
  ) => {
    createSEOCall(
      "linkanalysis",
      { keyword, postId, publicationId, reanalyze },
      callback
    );
  };

  const getKeywordSimilarity = (
    publicationId: any,
    keyword: any,
    description: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall(
      "getkeywordsimilarity",
      { publicationId, keyword, description, postId },
      callback
    );
  };

  const seoLinksScore = (processId: any, postId: any, callback: any) => {
    createSEOCall("getlinksscore", { processId, postId }, callback);
  };

  const getUpdatedLinksCount = (
    postId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall("getUpdatedLinksCount", { postId }, callback);
  };

  const getInternalLinkSuggestions = (
    processId: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall(
      "getinternallinksuggestions",
      { processId, postId },
      callback
    );
  };

  const updateAssignmentReviewed = (topicId: any, callback: any) => {
    createWorkflowCall("updateassignmentreviewed", { topicId }, callback);
  };

  const updateStyleGuideReviewed = (
    topicId: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "updatestyleguidereviewed",
      { topicId, publicationId },
      callback
    );
  };

  const connectToHubSpot = (publicationId: string, isCRM: boolean = false) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/hubspot/connect?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId +
            "&isCRM=" +
            isCRM,
          "_self"
        );
      });
  };

  const connectToWordPress = (
    publicationId: any,
    fallback: any,
    params = {}
  ) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/wordpress/connect?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId +
            "&fallback=" +
            fallback +
            "&params=" +
            encodeURIComponent(JSON.stringify(params)),
          "_self"
        );
      });
  };

  const saveWordpressPassword = (
    publicationId: string,
    url: string,
    username: string,
    applicationPassword: string,
    success: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "savewordpresspassword",
      { publicationId, url, username, applicationPassword, success },
      callback
    );
  };

  const connectToReddit = (
    publicationId: any,
    fallback: any,
    postId = "",
    params = {}
  ) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        window.open(
          SERVER_URL +
            "/reddit/connect?idToken=" +
            idToken +
            "&publicationId=" +
            publicationId +
            "&postId=" +
            postId +
            "&fallback=" +
            fallback +
            "&params=" +
            encodeURIComponent(JSON.stringify(params)),
          "_self"
        );
      });
  };

  const createHubSpotAccessToken = (
    code: string,
    publicationId: string,
    isCRM: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall(
      "createaccesstoken",
      { code, publicationId, isCRM },
      callback
    );
  };

  const saveHubspotIntegrationDetails = (
    publicationId: any,
    blog: any,
    hubspotId: any,
    callback: any
  ) => {
    createHubSpotApiCall(
      "savehubspotintegrationdetails",
      {
        publicationId,
        blog,
        hubspotId
      },
      callback
    );
  };

  const getAllContactsPropertiesHubspotCRM = (
    publicationId: string,
    hubId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createHubSpotApiCall(
      "getAllContactsPropertiesCRM",
      {
        publicationId,
        hubId
      },
      callback
    );
  };

  const saveExternalSite = (publicationId: any, blogs: any, callback: any) => {
    createApiCall("saveexternalsite", { publicationId, blogs }, callback);
  };

  const deleteExternalSite = (
    publicationId: any,
    blogId: any,
    callback: any
  ) => {
    createApiCall("deleteexternalsite", { publicationId, blogId }, callback);
  };

  const saveExternalSitemap = (
    publicationId: any,
    sitemapUrl: any,
    callback: any
  ) => {
    createApiCall(
      "saveexternalsitemap",
      { publicationId, sitemapUrl },
      callback
    );
  };

  const getCometChatUserDetails = (publicationId: any, callback: any) => {
    createCometchatApiCall(
      "getcometchatuserdetails",
      { publicationId },
      callback
    );
  };

  const checkPostOriginality = (
    postId: any,
    publicationId: any,
    callback: any
  ) => {
    createPlagiarismCall(
      "checkpostoriginality",
      { postId, publicationId },
      callback
    );
  };

  const getScanDetails = (postId: any, callback: any) => {
    createPlagiarismCall("getscandetails", { postId }, callback);
  };

  const getKeywordsAndTrafficForDomain = (
    domain: any,
    page: any,
    rowsPerPage: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getkeywordsandtrafficfordomain",
      { domain, page, rowsPerPage, publicationId },
      callback
    );
  };

  const getTopKeywordsForDomain = (
    domain: any,
    topKeywordsPage: any,
    topKeywordsRowsPerPage: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettopkeywordsfordomain",
      { domain, topKeywordsPage, topKeywordsRowsPerPage, publicationId },
      callback
    );
  };

  const getTopPagesForDomain = (
    domain: any,
    topPagesPage: any,
    topPagesRowsPerPage: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettoppagesfordomain",
      { domain, topPagesPage, topPagesRowsPerPage, publicationId },
      callback
    );
  };

  const getTopKeywordsWithPagination = (
    domain: any,
    topKeywordsPage: any,
    topKeywordsRowsPerPage: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettopkeywordswithpagination",
      { domain, topKeywordsPage, topKeywordsRowsPerPage },
      callback
    );
  };

  const getTopPagesWithPagination = (
    domain: any,
    topPagesPage: any,
    topPagesRowsPerPage: any,
    callback: any
  ) => {
    createWorkflowCall(
      "gettoppageswithpagination",
      { domain, topPagesPage, topPagesRowsPerPage },
      callback
    );
  };

  const getDomainRank = (domain: any, publicationId: any, callback: any) => {
    createWorkflowCall("getdomainrank", { domain, publicationId }, callback);
  };

  const getDomainBacklinks = (
    domain: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getdomainbacklinks",
      { domain, publicationId },
      callback
    );
  };

  const getCollectionFields = (
    publicationId: string,
    collectionId: string,
    accountId: string,
    siteId: string,
    callback: any
  ) => {
    createWebFlowCall(
      "getcollectionfields",
      { publicationId, collectionId, accountId, siteId },
      callback
    );
  };

  const addContentMapFields = (
    publicationId: any,
    type: any,
    instruction: any,
    label: any,
    placeholder: any,
    callback: any
  ) => {
    createWorkflowCall(
      "addcontentmapfields",
      {
        publicationId,
        type,
        instruction,
        label,
        placeholder
      },
      callback
    );
  };

  const savePublicationContentMapFields = (
    publicationId: any,
    fields: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savepublicationcontentmapfields",
      {
        publicationId,
        fields
      },
      callback
    );
  };

  const checkAndCreateUserByEmail = (email: any, callback: any) => {
    createUnAuthenticatedCall("checkandcreateuserbyemail", { email }, callback);
  };

  const createRedditAccessToken = (code: any, id: any, callback: any) => {
    createRedditApiCall("getaccesstoken", { code, id }, callback);
  };

  const getSubreddits = (
    publicationId: any,
    searchQuery: any,
    userId: any,
    postId: any,
    callback: any
  ) => {
    createRedditApiCall(
      "getsubreddits",
      { publicationId, searchQuery, userId, postId },
      callback
    );
  };

  const getPublicationDomainBacklinks = (
    publicationId: any,
    rowsPerPage: any,
    currentPage: any,
    type: any,
    callback: any
  ) => {
    createSEOCall(
      "getpublicationdomainbacklinks",
      { publicationId, rowsPerPage, currentPage, type },
      callback
    );
  };

  const getPostURLBacklinksData = (
    postId: any,
    rowsPerPage: any,
    currentPage: any,
    callback: any
  ) => {
    createSEOCall(
      "getposturlbacklinksdata",
      { postId, rowsPerPage, currentPage },
      callback
    );
  };

  const getTwitterDetails = (id: any, callback: any) => {
    createTwitterApiCall("gettwitterdetails", { id }, callback);
  };

  const saveScheduleTweetDraft = (
    topicId: any,
    date: any,
    publicationId: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "savescheduletweetdraft",
      { topicId, date, publicationId },
      callback
    );
  };

  const saveTweetDetails = (
    tweetId: any,
    publicationId: any,
    postId: any,
    tweet: any,
    images: any,
    twitterUserId: any,
    twitterProfilePic: any,
    twitterUserName: any,
    twitterScreenName: any,
    publishType: any,
    scheduledDate: any,
    utm: any,
    samples: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "savetweetdetails",
      {
        tweetId,
        publicationId,
        postId,
        tweet,
        images,
        twitterUserId,
        twitterProfilePic,
        twitterUserName,
        twitterScreenName,
        publishType,
        scheduledDate,
        utm,
        samples
      },
      callback
    );
  };

  const scheduleTweet = (
    publicationId: any,
    postId: any,
    tweets: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "scheduletweet",
      {
        publicationId,
        postId,
        tweets
      },
      callback
    );
  };

  const requestTweetReview = (
    publicationId: any,
    tweets: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "requesttweetreview",
      {
        publicationId,
        tweets
      },
      callback
    );
  };

  const approveTweet = (publicationId: any, tweets: any, callback: any) => {
    createTwitterApiCall(
      "approvetweet",
      {
        publicationId,
        tweets
      },
      callback
    );
  };

  const deleteTweet = (
    publicationId: any,
    tweetId: any,
    postId: any,
    callback: any
  ) => {
    createTwitterApiCall(
      "deletetweet",
      { tweetId, publicationId, postId },
      callback
    );
  };

  const deleteRedditPost = (
    publicationId: any,
    redditPostId: any,
    postId: any,
    callback: any
  ) => {
    createRedditApiCall(
      "deleteredditpost",
      { redditPostId, publicationId, postId },
      callback
    );
  };

  const getRedditDetails = (id: any, callback: any) => {
    createRedditApiCall("getredditdetails", { id }, callback);
  };

  const saveRedditPost = (
    redditPostId: any,
    publicationId: any,
    postId: any,
    title: any,
    redditPost: any,
    userId: any,
    redditProfilePic: any,
    redditUserName: any,
    publishType: any,
    scheduledDate: any,
    subreddits: any,
    utm: any,
    callback: any
  ) => {
    createRedditApiCall(
      "saveredditpost",
      {
        redditPostId,
        publicationId,
        postId,
        title,
        redditPost,
        userId,
        redditProfilePic,
        redditUserName,
        publishType,
        scheduledDate,
        subreddits,
        utm
      },
      callback
    );
  };

  const scheduleRedditPosts = (
    publicationId: any,
    postId: any,
    redditPosts: any,
    callback: any
  ) => {
    createRedditApiCall(
      "scheduleredditposts",
      {
        publicationId,
        postId,
        redditPosts
      },
      callback
    );
  };

  const getFacebookShareDetails = (id: any, callback: any) => {
    createFacebookApiCall("getfacebooksharedetails", { id }, callback);
  };

  const saveFacebookPostDetails = (
    facebookPostId: any,
    publicationId: any,
    postId: any,
    text: any,
    images: any,
    account: any,
    publishType: any,
    scheduledOn: any,
    isTextDirty: any,
    accountName: any,
    utm: any,
    callback: any
  ) => {
    createFacebookApiCall(
      "savefacebookpostdetails",
      {
        facebookPostId,
        publicationId,
        postId,
        text,
        images,
        account,
        publishType,
        scheduledOn,
        isTextDirty,
        accountName,
        utm
      },
      callback
    );
  };

  const shareToFacebook = (
    publicationId: any,
    postId: any,
    facebookPosts: any,
    callback: any
  ) => {
    createFacebookApiCall(
      "sharetofacebook",
      {
        publicationId,
        postId,
        facebookPosts
      },
      callback
    );
  };

  const deleteFacebookPost = (
    publicationId: any,
    facebookPostId: any,
    postId: any,
    callback: any
  ) => {
    createFacebookApiCall(
      "deletefacebookpost",
      { publicationId, facebookPostId, postId },
      callback
    );
  };

  const saveScheduleLinkedInDraft = (
    topicId: any,
    date: any,
    publicationId: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "saveschedulelinkedindraft",
      { topicId, date, publicationId },
      callback
    );
  };

  const getLinkedInDetails = (
    id: any,
    socialPostText: string = "",
    externalApproverId: string,
    callback: any
  ) => {
    createLinkedInApiCall(
      "getdetails",
      { id, socialPostText, externalApproverId },
      callback
    );
  };

  const getOrgPostAutoLikeConfig = (publicationId: any, callback: any) => {
    createLinkedInApiCall(
      "getorgpostautolikeconfig",
      { publicationId },
      callback
    );
  };

  const saveOrgPostAutoLikeConfig = (
    publicationId: any,
    organization: any,
    autoLikeAccounts: any,
    notifyInSlack: any,
    reactionType: any,
    autoLikePostTiming: string,
    callback: any
  ) => {
    createLinkedInApiCall(
      "saveorgpostautolikeconfig",
      {
        publicationId,
        reactionType,
        organization,
        autoLikeAccounts,
        notifyInSlack,
        autoLikePostTiming
      },
      callback
    );
  };

  const getLinkedInAccounts = (
    publicationId: string,
    keyword: string,
    account: string,
    callback: any
  ) => {
    createLinkedInApiCall(
      "getLinkedInAccounts",
      { publicationId, keyword, account },
      callback
    );
  };

  const getLinkedinPost = (
    linkedinPostId: string,
    linkedinUserId: string,
    linkedinCommentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInPublicApiCall(
      "getlinkedinpost",
      { linkedinPostId, linkedinUserId, linkedinCommentId },
      callback
    );
  };

  const getAddCommentsAndLikesRequest = (
    addCommentsAndLikesRequestId: string,
    linkedinUserId: string,
    linkedinCommentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInPublicApiCall(
      "getaddcommentsandlikesrequest",
      {
        addCommentsAndLikesRequestId,
        linkedinUserId,
        linkedinCommentId
      },
      callback
    );
  };

  const postExternalComment = (
    publicationId: string,
    linkedinPostId: string,
    linkedinUserId: string,
    comment: string,
    linkedinCommentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInPublicApiCall(
      "postexternalcomment",
      {
        publicationId,
        linkedinPostId,
        linkedinUserId,
        comment,
        linkedinCommentId
      },
      callback
    );
  };

  const postExternalCommentWithoutPost = (
    publicationId: string,
    addCommentsAndLikesRequestId: string,
    linkedinUserId: string,
    comment: string,
    linkedinCommentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInPublicApiCall(
      "postexternalcommentwithoutpost",
      {
        publicationId,
        addCommentsAndLikesRequestId,
        linkedinUserId,
        comment,
        linkedinCommentId
      },
      callback
    );
  };

  const repost = (
    publicationId: string,
    linkedinPostId: string,
    linkedinUserId: string,
    comment: string,
    linkedinCommentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInPublicApiCall(
      "repost",
      {
        publicationId,
        linkedinPostId,
        linkedinUserId,
        comment,
        linkedinCommentId
      },
      callback
    );
  };

  const repostWithoutPost = (
    publicationId: string,
    addCommentsAndLikesRequestId: string,
    linkedinUserId: string,
    comment: string,
    linkedinCommentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInPublicApiCall(
      "repostwithoutpost",
      {
        publicationId,
        addCommentsAndLikesRequestId,
        linkedinUserId,
        comment,
        linkedinCommentId
      },
      callback
    );
  };

  const requestLinkedInReview = (
    publicationId: any,
    linkedInPosts: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "requestlinkedinreview",
      { publicationId, linkedInPosts },
      callback
    );
  };

  const requestLinkedInReviewMultipleAccount = (
    publicationId: string,
    linkedInPosts: any,
    accounts: any,
    approvers: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "requestlinkedinreviewmultipleaccount",
      { publicationId, linkedInPosts, accounts, approvers },
      callback
    );
  };

  const approveLinkedInPost = (
    publicationId: any,
    linkedInPosts: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "approvepost",
      { publicationId, linkedInPosts },
      callback
    );
  };

  const approveLinkedInPostExternal = (
    linkedInPostId: string,
    userId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "approvelinkedinpostexternal",
      { linkedInPostId, userId },
      callback
    );
  };

  const approvePostAsTeammatePost = (
    postAsTeammateRequestId: string,
    userId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "approve-post-as-teammate-post",
      { postAsTeammateRequestId, userId },
      callback
    );
  };

  const saveLinkedInShareDetails = (
    id: any,
    linkedInPostId: any,
    account: any,
    body: any,
    publishType: any,
    scheduleDate: any,
    comments: any,
    mediaFile: any,
    utm: any,
    includeCoverPhoto: any,
    bodyPreview: any,
    commentsPreview: any,
    automateLikeAccounts: any,
    automateLikeReaction: any,
    accountsToAutoComment: any,
    autoCommentTemplate: any,
    includeEmbed: any,
    embed: any,
    samples: any,
    approvalRequiredForComments: boolean,
    autoLikePostTiming: string,
    externalApproverId: string,
    accounts: any,
    approvers: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "savesharedetails",
      {
        id,
        linkedInPostId,
        account,
        body,
        publishType,
        scheduleDate,
        comments,
        mediaFile,
        utm,
        includeCoverPhoto,
        bodyPreview,
        commentsPreview,
        automateLikeAccounts,
        automateLikeReaction,
        accountsToAutoComment,
        autoCommentTemplate,
        includeEmbed,
        embed,
        samples,
        approvalRequiredForComments,
        autoLikePostTiming,
        externalApproverId,
        accounts,
        approvers
      },
      callback
    );
  };

  const importTopicIdeas = (
    file: any,
    fileUrl: any,
    fileName: any,
    publicationId: any,
    callback: any
  ) => {
    let data = new FormData();
    data.append("file", file);
    data.append("fileName", fileName);
    data.append("fileUrl", fileUrl);
    data.append("publicationId", publicationId);
    createApiCallWithFormData("workflow/importtopicideas", data, callback);
  };

  const importFileForIndexing = (
    publicationId: string,
    fileUrl: string,
    fileType: string,
    callback: (res: AxiosResponse) => void
  ) => {
    let data = new FormData();
    data.append("fileUrl", fileUrl);
    data.append("publicationId", publicationId);
    data.append("fileType", fileType);
    createApiCallWithFormData("workflow/importfileforindexing", data, callback);
  };

  const importKeywords = (
    file: any,
    fileUrl: any,
    fileName: any,
    publicationId: any,
    callback: any
  ) => {
    let data = new FormData();
    data.append("file", file);
    data.append("fileName", fileName);
    data.append("fileUrl", fileUrl);
    data.append("publicationId", publicationId);
    createApiCallWithFormData("seo/importkeywords", data, callback);
  };

  const setTopicPosition = (updateQueries: any, callback: any) => {
    createWorkflowCall("settopicposition", { updateQueries }, callback);
  };

  const deleteSocialMediaFromCalendar = (
    id: any,
    publicationId: any,
    type: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "deletesocialmedia",
      { id, publicationId, type, postId },
      callback
    );
  };

  const getSocialMediaCalendarEvents = (
    publicationId: any,
    startDate: any,
    endDate: any,
    selectedPublications: any,
    allPublicationSelected: any,
    channelFilters: any,
    statusFilters: any,
    assigneeFilters: any,
    labelFilters: any,
    campaignFilters: filterValue[],
    callback: any
  ) => {
    createApiCall(
      "getsocialmediacalendarevents",
      {
        publicationId,
        startDate,
        endDate,
        selectedPublications,
        allPublicationSelected,
        channelFilters,
        statusFilters,
        assigneeFilters,
        labelFilters,
        campaignFilters
      },
      callback
    );
  };

  const getScheduledPostDates = (
    publicationId: any,
    id: any,
    account: any,
    type: any,
    callback: any
  ) => {
    createApiCall(
      "getscheduledpostdates",
      {
        publicationId,
        id,
        account,
        type
      },
      callback
    );
  };

  const shareToLinkedIn = (
    publicationId: any,
    postId: any,
    linkedInPosts: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "sharetolinkedin",
      {
        publicationId,
        postId,
        linkedInPosts
      },
      callback
    );
  };

  const deleteLinkedInPost = (
    publicationId: any,
    linkedInPostId: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "deletelinkedinpost",
      { publicationId, linkedInPostId },
      callback
    );
  };

  const generateTopicsFromProgrammaticTopic = (
    publicationId: string,
    programmaticTopics: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "generatetopicsfromprogrammatictopic",
      {
        publicationId,
        programmaticTopics
      },
      callback
    );
  };

  const loadProgrammaticTopics = (
    publicationId: string,
    offset: number,
    limit: number,
    filters: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "loadprogrammatictopics",
      {
        publicationId,
        offset,
        limit,
        filters
      },
      callback
    );
  };

  const loadGeneratedProgrammaticTopics = (
    publicationId: string,
    offset: number,
    limit: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "loadgeneratedprogrammatictopics",
      {
        publicationId,
        offset,
        limit
      },
      callback
    );
  };

  const generateOutlinesFromTopicKeywords = (
    publicationId: string,
    programmaticPostIds: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "generateoutlinesfromtopickeywords",
      {
        publicationId,
        programmaticPostIds
      },
      callback
    );
  };

  const discardSuggestedPosts = (
    publicationId: string,
    programmaticPostIds: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "discardsuggestedposts",
      {
        publicationId,
        programmaticPostIds
      },
      callback
    );
  };

  const getKeywordStats = (
    publicationId: any,
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall(
      "getkeywordstats",
      { publicationId, keyword, postId },
      callback
    );
  };

  const getOrganicPostsForContentOutline = (
    publicationId: any,
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall(
      "getorganicpostsforcontentoutline",
      { publicationId, keyword, postId },
      callback
    );
  };

  const getTopPagesHeadings = (
    publicationId: any,
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall(
      "gettoppagesheadings",
      { publicationId, keyword, postId },
      callback
    );
  };

  const getTopPagesHeadingsFromURL = (
    urls: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall("gettoppagesheadingsfromurls", { urls, postId }, callback);
  };

  const getContentOutlineFromURL = (urls: any, postId: any, callback: any) => {
    createSEOCall("getcontentoutlinefromurl", { urls, postId }, callback);
  };

  const checkKeywordStats = (urls: any, postId: any, callback: any) => {
    createSEOCall("checkkeywordstats", { urls, postId }, callback);
  };

  const getInstagramDetails = (id: any, callback: any) => {
    createInstagramCall("getdetails", { id }, callback);
  };

  const saveInstagramShareDetails = (
    id: any,
    instagramPostId: any,
    account: any,
    accountName: any,
    images: any,
    caption: any,
    publishType: any,
    scheduledOn: any,
    utm: any,
    callback: any
  ) => {
    createInstagramCall(
      "save",
      {
        id,
        instagramPostId,
        account,
        accountName,
        images,
        caption,
        publishType,
        scheduledOn,
        utm
      },
      callback
    );
  };

  const shareToInstagram = (
    publicationId: any,
    postId: any,
    instagramPosts: any,
    callback: any
  ) => {
    createInstagramCall(
      "share",
      { publicationId, postId, instagramPosts },
      callback
    );
  };

  const deleteInstagramPost = (
    publicationId: any,
    instagramPostId: any,
    callback: any
  ) => {
    createInstagramCall("delete", { publicationId, instagramPostId }, callback);
  };

  const turnEmailNotificationOff = (
    publicationId: any,
    category: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "turnemailnotificationsoff",
      { publicationId, category },
      callback
    );
  };

  const getBlogPostPreview = (postId: any, callback: any) => {
    createApiCall("getblogpostpreview", { postId }, callback);
  };

  const getTopCalendarEvents = (
    searchQuery: any,
    publicationId: any,
    allPublicationSelected: any,
    selectedPublications: any,
    currentDate: any,
    callback: any
  ) => {
    createApiCall(
      "gettopcalendarevents",
      {
        searchQuery,
        publicationId,
        allPublicationSelected,
        selectedPublications,
        currentDate
      },
      callback
    );
  };

  const saveTopicVideoUrl = (topicId: any, videoUrl: any, callback: any) => {
    createWorkflowCall("savetopicvideourl", { topicId, videoUrl }, callback);
  };

  const saveIdeaTemplateVideoUrl = (
    ideaTemplateId: any,
    videoUrl: any,
    callback: any
  ) => {
    createWorkflowCall(
      "saveideatemplatevideourl",
      { ideaTemplateId, videoUrl },
      callback
    );
  };

  const loomJWS = (postId: any, callback: any) => {
    createApiCall("loomjws", { postId }, callback);
  };

  const assignBulkTopics = (publicationId: any, events: any, callback: any) => {
    createWorkflowCall("assignbulktopics", { publicationId, events }, callback);
  };

  const createAssignBulkTopics = (
    publicationId: any,
    ideas: any,
    assignIdeas: any,
    callback: any
  ) => {
    createWorkflowCall(
      "createassignbulktopics",
      { publicationId, ideas, assignIdeas },
      callback
    );
  };

  const archiveBulkTopics = (topicIds: any, callback: any) => {
    createWorkflowCall("archivebulktopics", { topicIds }, callback);
  };

  const unarchiveBulkTopics = (topicIds: any, callback: any) => {
    createWorkflowCall("unarchivebulktopics", { topicIds }, callback);
  };

  const postHeaders = (postId: any, callback: any) => {
    createApiCall("postheaders", { postId }, callback);
  };

  const replaceSocialMediaText = (
    postId: any,
    publicationId: any,
    text: any,
    callback: any
  ) => {
    createApiCall(
      "replacesocialmediatext",
      { postId, publicationId, text },
      callback
    );
  };

  const getTopicStatus = (topicId: any, callback: any) => {
    createWorkflowCall("gettopicstatus", { topicId }, callback);
  };

  const getTeammatesForCalendar = (
    publicationId: any,
    selectedPublications: any,
    allPublicationSelected: any,
    callback: any
  ) => {
    createApiCall(
      "getteammatescalendar",
      {
        publicationId,
        selectedPublications,
        allPublicationSelected
      },
      callback
    );
  };

  const autoLoginPostCollaborator = (pid: any, callback: any) => {
    createUnAuthenticatedCall("autologinpostcollaborator", { pid }, callback);
  };

  const getKeywordDataLocation = (publicationId: any, callback: any) => {
    createApiCall("getkeyworddatalocation", { publicationId }, callback);
  };

  const saveKeywordDataLocation = (
    publicationId: any,
    location: any,
    callback: any
  ) => {
    createApiCall(
      "savekeyworddatalocation",
      { publicationId, location },
      callback
    );
  };

  const getAppNotificationCount = (publicationId: any, callback: any) => {
    createApiCall("getappnotificationcount", { publicationId }, callback);
  };

  const getAppNotification = (publicationId: any, callback: any) => {
    createApiCall("getappnotification", { publicationId }, callback);
  };

  const setAppNotificationRead = (
    publicationId: any,
    notificationId: any,
    callback: any
  ) => {
    createApiCall(
      "setappnotificationread",
      { publicationId, notificationId },
      callback
    );
  };

  const setAllAppNotificationRead = (publicationId: any, callback: any) => {
    createApiCall("setallappnotificationread", { publicationId }, callback);
  };

  const checkUserHaveSavedCard = (publicationId: any, callback: any) => {
    createApiCall("checkuserhavesavedcard", { publicationId }, callback);
  };

  const saveBlogPostDestination = (
    publicationId: any,
    postId: any,
    destination: any,
    slug: any,
    callback: any
  ) => {
    createApiCall(
      "saveblogpostdestination",
      {
        publicationId,
        postId,
        destination,
        slug
      },
      callback
    );
  };

  const switchWebflowPostIdForPreview = (
    publicationId: any,
    postId: any,
    prevDestinationId: any,
    callback: any
  ) => {
    createWebFlowCall(
      "switchwebflowpostidforpreview",
      {
        publicationId,
        postId,
        prevDestinationId
      },
      callback
    );
  };

  const postBulkAction = (
    publicationId: any,
    posts: any,
    type: any,
    callback: any
  ) => {
    createApiCall(
      "postbulkaction",
      {
        publicationId,
        posts,
        type
      },
      callback
    );
  };

  const getConnectedBlogs = (publicationId: any, callback: any) => {
    createApiCall("getconnectedblogs", { publicationId }, callback);
  };

  const initializeTermsResearch = (
    keyword: any,
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall(
      "initializetermsresearch",
      { keyword, publicationId, postId },
      callback
    );
  };

  const getExtractedTermsForKeyword = (
    keyword: any,
    postId: any,
    callback: any
  ) => {
    createSEOCall("getextractedtermsforkeyword", { keyword, postId }, callback);
  };

  const getWebflowReferences = (postId: any, refetch: any, callback: any) => {
    createApiCall("getwebflowreferences", { postId, refetch }, callback);
  };

  const getContentStages = (publicationId: any, callback: any) => {
    createWorkflowCall("getcontentstages", { publicationId }, callback);
  };

  const saveContentStages = (
    publicationId: any,
    contentStages: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savecontentstages",
      { publicationId, contentStages },
      callback
    );
  };

  const updateContentStages = (
    publicationId: any,
    contentStages: any,
    oldStatus: any,
    newStatus: any,
    callback: any
  ) => {
    createWorkflowCall(
      "updatecontentstages",
      { publicationId, contentStages, oldStatus, newStatus },
      callback
    );
  };

  const deleteContentStage = (
    publicationId: any,
    stage: any,
    callback: any
  ) => {
    createWorkflowCall(
      "deletecontentstage",
      { publicationId, stage },
      callback
    );
  };

  const saveAuthor = (
    publicationId: any,
    name: any,
    profilePic: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "saveauthor",
      { publicationId, name, profilePic, postId },
      callback
    );
  };

  const getTopicForCalendar = (topicId: any, callback: any) => {
    createWorkflowCall("gettopicforcalendar", { topicId }, callback);
  };

  const saveTopicCalenderData = (
    publicationId: any,
    topicId: any,
    title: any,
    owner: any,
    assignedAndDeadlines: any,
    postId: any,
    freelancerInvited: any,
    isForTracking: boolean,
    callback: any
  ) => {
    createWorkflowCall(
      "savetopiccalenderdata",
      {
        publicationId,
        topicId,
        title,
        owner,
        assignedAndDeadlines,
        postId,
        freelancerInvited,
        isForTracking
      },
      callback
    );
  };
  const createArticleByOutline = (
    publicationId: any,
    postId: any,
    contentMapId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "createarticlebyoutline",
      {
        publicationId,
        postId,
        contentMapId
      },
      callback
    );
  };

  const saveContentStagesDeadlines = (
    publicationId: any,
    stages: any,
    callback: any
  ) => {
    createWorkflowCall(
      "savecontentstagesdeadlines",
      {
        publicationId,
        stages
      },
      callback
    );
  };

  const toggleOutlineGenerationAI = (
    publicationId: any,
    generateOutlineAndArticleByAI: any,
    callback: any
  ) => {
    createWorkflowCall(
      "toggleoutlinegenerationai",
      {
        publicationId,
        generateOutlineAndArticleByAI
      },
      callback
    );
  };

  const styleCorrectionRequest = (
    publicationId: any,
    postId: any,
    correctionRuleId: any,
    ruleName: any,
    text: any,
    callback: any
  ) => {
    createWorkflowCall(
      "stylecorrectionrequest",
      {
        publicationId,
        postId,
        correctionRuleId,
        ruleName,
        text
      },
      callback
    );
  };

  const getStyleCorrectionRequest = (
    correctionRequestId: any,
    postId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getstylecorrectionrequest",
      {
        correctionRequestId,
        postId
      },
      callback
    );
  };

  const resolveCorrectionRequest = (
    correctionRequestId: any,
    postId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "resolvecorrectionrequest",
      {
        correctionRequestId,
        postId
      },
      callback
    );
  };

  const getAIStyleCorrectionRules = (postId: any, callback: any) => {
    createWorkflowCall("getaistylecorrectionrules", { postId }, callback);
  };

  const getRuleDescriptionAndExamples = (
    correctionRuleId: any,
    postId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getruledescriptionandexamples",
      { correctionRuleId, postId },
      callback
    );
  };

  const deleteComment = (commentId: any, callback: any) => {
    createWorkflowCall("deletecomment", { commentId }, callback);
  };

  const updateComment = (
    commentId: any,
    commentText: any,
    files: any,
    status: any,
    callback: any
  ) => {
    createWorkflowCall(
      "updateComment",
      { commentId, commentText, files, status },
      callback
    );
  };

  const setDefaultCalendarTime = (
    publicationId: any,
    hours: any,
    minutes: any,
    type: any,
    callback: any
  ) => {
    createApiCall(
      "savedefaultcalendartime",
      { publicationId, hours, minutes, type },
      callback
    );
  };

  const saveWidgetTemplate = (
    code: any,
    text: any,
    widgetName: any,
    widgetDescription: string,
    publicationId: any,
    templateId: any,
    postId: any,
    customFields: any,
    callback: any
  ) => {
    createApiCall(
      "savewidgettemplate",
      {
        code,
        text,
        widgetName,
        widgetDescription,
        publicationId,
        templateId,
        postId,
        customFields
      },
      callback
    );
  };

  const saveSummaryTemplate = (
    name: any,
    instructions: any,
    summaryTemplateType: any,
    publicationId: any,
    templateId: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "savesummarytemplate",
      {
        name,
        instructions,
        summaryTemplateType,
        publicationId,
        templateId,
        postId
      },
      callback
    );
  };

  const checkIfUserHasSavedPaymentMethod = (
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "checkifuserhassavedpaymentmethod",
      { publicationId },
      callback
    );
  };

  const getWidgetTemplates = (
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createApiCall("getWidgetTemplates", { publicationId, postId }, callback);
  };

  const getSummaryTemplates = (
    publicationId: any,
    postId: any,
    summaryTemplateType: any,
    callback: any
  ) => {
    createApiCall(
      "getsummarytemplates",
      { publicationId, postId, summaryTemplateType },
      callback
    );
  };

  const getSummaryTranscripts = (
    publicationId: any,
    postId: any,
    callback: any
  ) => {
    createApiCall("getsummarytranscripts", { publicationId, postId }, callback);
  };

  const deleteWidgetTemplate = (
    publicationId: any,
    id: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "deleteWidgetTemplate",
      { publicationId, id, postId },
      callback
    );
  };
  const deleteSummaryTemplate = (
    publicationId: any,
    templateId: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "deletesummarytemplate",
      { publicationId, templateId, postId },
      callback
    );
  };

  const initiateGmeetIntegration = (
    publicationId: any,
    email: any,
    state: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "initiate-gmeet-integration",
      { publicationId, email, state },
      callback
    );
  };

  const createGoogleMeetAccessToken = (
    code: any,
    state: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("create-gmeet-access-token", { code, state }, callback);
  };

  const connectToGong = (
    publicationId: string,
    accessKey: string,
    accessKeySecret: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "connecttogong",
      { publicationId, accessKey, accessKeySecret },
      callback
    );
  };

  const connectToSalesCall = (
    publicationId: string,
    apiKey: string,
    secret: string,
    integrationType: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "connecttosalescall",
      { publicationId, apiKey, secret, integrationType },
      callback
    );
  };

  const toggleSendCallAnalysisMail = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "togglesendcallanalysismail",
      { publicationId },
      callback
    );
  };

  const toggleSalesCallAnalysisMail = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "togglesalescallanalysismail",
      { publicationId },
      callback
    );
  };

  const checkUserHavePlan = (publicationId: any, callback: any) => {
    createApiCall("checkuserhaveplan", { publicationId }, callback);
  };

  const getClientWithBillingPortalSession = (callback: any) => {
    createApiCall("getclientwithbillingportalsession", {}, callback);
  };

  const getPostURLListByPublicationId = (
    publicationId: any,
    searchString: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "getposturllistbypublicationid",
      { publicationId, searchString, postId },
      callback
    );
  };

  const askQuestion = (
    question: any,
    publicationId: any,
    transcriptQALogId: any,
    callback: any
  ) => {
    createApiCall(
      "askquestion",
      { question, publicationId, transcriptQALogId },
      callback
    );
  };

  const updatePostAccessConfig = (
    postId: any,
    isPublic: any,
    isCommentOnlyAccess: any,
    callback: any
  ) => {
    createApiCall(
      "updatepostaccessconfig",
      { postId, isPublic, isCommentOnlyAccess },
      callback
    );
  };
  const updateCalendarAccessConfig = (
    publicationId: any,
    isCalendarPublic: any,
    callback: any
  ) => {
    createApiCall(
      "updatecalendaraccessconfig",
      { publicationId, isCalendarPublic },
      callback
    );
  };

  const updateIdeasAccessConfig = (
    publicationId: any,
    isIdeasPublic: any,
    callback: any
  ) => {
    createApiCall(
      "updateideasaccessconfig",
      { publicationId, isIdeasPublic },
      callback
    );
  };

  const getSearchWidget = (publicationId: any, callback: any) => {
    createApiCall("getsearchwidget", { publicationId }, callback);
  };

  const setupChatGPT = (
    siteURL: any,
    email: any,
    publicationId: any,
    doNotNotify: any,
    callback: any
  ) => {
    createUnAuthenticatedCall(
      "setupchatgpt",
      { siteURL, email, publicationId, doNotNotify },
      callback
    );
  };

  const generateArticle = (
    data: any,
    postId: any,
    form = [],
    callback: any
  ) => {
    createApiCall("generatearticle", { data, postId, form }, callback);
  };

  const getGeneratedArticle = (referenceId: any, callback: any) => {
    createApiCall("getgeneratedarticle", { referenceId }, callback);
  };

  const getFrameworkHistory = (postId: any, callback: any) => {
    createApiCall("getframeworkhistory", { postId }, callback);
  };

  const getChannelDetails = (publicationId: any, callback: any) => {
    createWorkflowCall("getchanneldetails", { publicationId }, callback);
  };

  const updatePaymentFailedNotification = (
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "updatepaymentfailednotification",
      { publicationId },
      callback
    );
  };

  const generateSlug = (
    postId: any,
    title: any,
    keyword: any,
    callback: any
  ) => {
    createApiCall("generateslug", { postId, title, keyword }, callback);
  };

  const generateMetaDescription = (
    postId: any,
    title: any,
    keyword: any,
    body: any,
    callback: any
  ) => {
    createApiCall(
      "generatemetadescription",
      { postId, title, keyword, body },
      callback
    );
  };

  const getGongAnalysisData = (
    publicationId: any,
    limit: any,
    offset: any,
    selectedFilters = [],
    searchQuery = "",
    startDate: any,
    endDate: any,
    callback: any
  ) => {
    createApiCall(
      "getgonganalysisdata",
      {
        publicationId,
        limit,
        offset,
        selectedFilters,
        searchQuery,
        startDate,
        endDate
      },
      callback
    );
  };

  const getGongAskedQuestions = (
    publicationId: any,
    askQuery: string = "",
    askFilters: {
      dealStages: string[];
      dealOwners: string[];
      accountNames: string[];
      dealCreatedDateRange: [null | Date, null | Date];
      dealCloseDateRange: [null | Date, null | Date];
    },
    callback: any
  ) => {
    createApiCall(
      "getgongaskedquestions",
      {
        publicationId,
        askQuery,
        filters: askFilters
      },
      callback
    );
  };

  const getAllAskedQuestions = (
    publicationId: any,
    limit: any,
    offset: any,
    callback: any
  ) => {
    createApiCall(
      "getallaskedquestions",
      {
        publicationId,
        limit,
        offset
      },
      callback
    );
  };

  const deleteAskedQuestion = (
    publicationId: any,
    questionIds: any,
    callback: any
  ) => {
    createApiCall(
      "deleteaskedquestion",
      {
        publicationId,
        questionIds
      },
      callback
    );
  };

  const startDraftGenerationFromThemes = (
    publicationId: any,
    themeId: any,
    callback: any
  ) => {
    createApiCall(
      "startdraftgenerationfromthemes",
      {
        publicationId,
        themeId
      },
      callback
    );
  };

  const getGongAnalysisInsightThemes = (
    publicationId: any,
    limit: any,
    offset: any,
    searchQuery = "",
    startDate: any,
    endDate: any,
    callback: any
  ) => {
    createApiCall(
      "getgonganalysisinsightthemes",
      {
        publicationId,
        limit,
        offset,
        searchQuery,
        startDate,
        endDate
      },
      callback
    );
  };

  const hideBadSuggestion = (
    publicationId: any,
    insightId: any,
    callback: any
  ) => {
    createApiCall("hidebadsuggestion", { publicationId, insightId }, callback);
  };

  const createdIdeaFromInsight = (
    publicationId: any,
    insightId: any,
    callback: any
  ) => {
    createApiCall(
      "createdideafrominsight",
      { publicationId, insightId },
      callback
    );
  };

  const getInternalLinks = (
    publicationId: any,
    limit: any,
    offset: any,
    linkSource: any,
    postId: any,
    showPosts: any,
    postStatusFilters: any,
    searchBy: string,
    callback: any
  ) => {
    createApiCall(
      "getinternallinks",
      {
        publicationId,
        limit,
        offset,
        linkSource,
        postId,
        showPosts,
        postStatusFilters,
        searchBy
      },
      callback
    );
  };

  const publishAllPost = (publicationId: any, callback: any) => {
    createApiCall("publishallpost", { publicationId }, callback);
  };

  const publishAllPostForUnhealthyLinks = (
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "publishallpostforunhealthylinks",
      { publicationId },
      callback
    );
  };

  const refreshInternalLinks = (publicationId: any, callback: any) => {
    createApiCall("refreshinternallinks", { publicationId }, callback);
  };

  const getUnhealthyLinks = (
    publicationId: any,
    limit: any,
    offset: any,
    type: any,
    postId: any,
    postStatusFilters: string[],
    callback: any
  ) => {
    createApiCall(
      "getunhealthylinks",
      { publicationId, limit, offset, type, postId, postStatusFilters },
      callback
    );
  };

  const publishAllPostForFindAndReplaceKeywords = (
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "publishallpostforfindandreplacekeywords",
      { publicationId },
      callback
    );
  };

  const getFindAndReplaceKeywords = (
    publicationId: any,
    limit: any,
    offset: any,
    filter: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "getfindandreplacekeywords",
      { publicationId, limit, offset, postId, filter },
      callback
    );
  };

  const acceptFindAndReplaceKeywords = (
    publicationId: any,
    findAndReplaceKeywords: any,
    replace: any,
    caseSensitive: any,
    limit: any,
    offset: any,
    filter: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "acceptfindandreplacekeywords",
      {
        publicationId,
        findAndReplaceKeywords,
        replace,
        limit,
        offset,
        filter,
        postId,
        caseSensitive
      },
      callback
    );
  };

  const refreshFindAndReplaceKeywords = (
    publicationId: any,
    keyword: any,
    caseSensitive: any,
    callback: any
  ) => {
    createApiCall(
      "refreshfindandreplacekeywords",
      { publicationId, keyword, caseSensitive },
      callback
    );
  };

  const deleteFindAndReplaceKeywords = (publicationId: any, callback: any) => {
    createApiCall("deletefinaandreplacekeywords", { publicationId }, callback);
  };

  const refreshUnhealthyLinks = (publicationId: any, callback: any) => {
    createApiCall("refreshunhealthylinks", { publicationId }, callback);
  };

  const acceptInternalLinks = (
    publicationId: any,
    internalLinks: any,
    limit: any,
    offset: any,
    linkSource: any,
    postId: any,
    showPosts: any,
    postStatusFilters: any,
    searchBy: string,
    callback: any
  ) => {
    createApiCall(
      "acceptinternallinks",
      {
        publicationId,
        internalLinks,
        limit,
        offset,
        linkSource,
        postId,
        showPosts,
        postStatusFilters,
        searchBy
      },
      callback
    );
  };

  const undoAcceptedInternalLink = (
    publicationId: any,
    internalLinkid: any,
    limit: any,
    offset: any,
    linkSource: any,
    postId: any,
    showPosts: any,
    postStatusFilters: any,
    searchBy: string,
    callback: any
  ) => {
    createApiCall(
      "undoacceptedinternallink",
      {
        publicationId,
        internalLinkid,
        limit,
        offset,
        linkSource,
        postId,
        showPosts,
        postStatusFilters,
        searchBy
      },
      callback
    );
  };

  const acceptUnhealthyLinks = (
    publicationId: any,
    unhealthyLinks: any,
    limit: any,
    offset: any,
    type: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "acceptunhealthylinks",
      {
        publicationId,
        unhealthyLinks,
        limit,
        offset,
        type,
        postId
      },
      callback
    );
  };

  const undoAcceptedLinkHealth = (
    publicationId: any,
    linkHealthId: any,
    limit: any,
    offset: any,
    type: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "undoacceptedlinkhealth",
      {
        publicationId,
        linkHealthId,
        limit,
        offset,
        type,
        postId
      },
      callback
    );
  };

  const rejectUnhealthyLinks = (
    publicationId: any,
    unhealthyLinks: any,
    limit: any,
    offset: any,
    type: any,
    postId: any,
    callback: any
  ) => {
    createApiCall(
      "rejectunhealthylinks",
      {
        publicationId,
        unhealthyLinks,
        limit,
        offset,
        type,
        postId
      },
      callback
    );
  };

  const rejectInternalLinks = (
    publicationId: any,
    internalLinks: any,
    limit: any,
    offset: any,
    linkSource: any,
    postId: any,
    showPosts: any,
    postStatusFilters: any,
    searchBy: string,
    callback: any
  ) => {
    createApiCall(
      "rejectinternallinks",
      {
        publicationId,
        internalLinks,
        limit,
        offset,
        linkSource,
        postId,
        showPosts,
        postStatusFilters,
        searchBy
      },
      callback
    );
  };

  const generateLinkedinPostFromTopic = (
    publicationId: string,
    referenceId: string,
    body: string,
    topic: string,
    callback: (response: AxiosResponse) => void
  ) => {
    createApiCall(
      "generate-linkedin-post-from-topic",
      { publicationId, referenceId, body, topic },
      callback
    );
  };

  const generateSocialPostFromLink = (
    publicationId: any,
    link: any,
    regenerate: any,
    type: any,
    postId: any,
    identifier = "",
    samples = [],
    callback: any
  ) => {
    createApiCall(
      "generatesocialpostfromlink",
      {
        publicationId,
        link,
        regenerate,
        type,
        postId,
        identifier,
        samples
      },
      callback
    );
  };
  const saveSample = (publicationId: any, sample: any, callback: any) => {
    createApiCall("savesample", { publicationId, sample }, callback);
  };

  const getSamples = (publicationId: any, filter: any, callback: any) => {
    createApiCall("getsamples", { publicationId, filter }, callback);
  };

  const deleteSample = (publicationId: any, sampleId: any, callback: any) => {
    createApiCall("deletesample", { publicationId, sampleId }, callback);
  };

  const saveOutboundTemplate = (
    publicationId: string,
    outboundTemplate: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveoutboundtemplate",
      { publicationId, outboundTemplate },
      callback
    );
  };

  const getOutboundTemplates = (
    publicationId: string,
    filter: channelTypes.LINKEDIN | channelTypes.EMAIL | null,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getoutboundtemplates", { publicationId, filter }, callback);
  };

  const deleteOutboundTemplate = (
    publicationId: any,
    outboundTemplateId: any,
    callback: any
  ) => {
    createApiCall(
      "deleteoutboundtemplate",
      { publicationId, outboundTemplateId },
      callback
    );
  };

  const saveBuyerFilters = (
    publicationId: string,
    buyerFilters: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveBuyerFilters",
      { publicationId, buyerFilters },
      callback
    );
  };

  const saveCompanyDetails = (
    publicationId: string,
    companyDetails: string,
    avoidTopics: string[],
    focusTopics: string[],
    sampleOutboundMessage: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "savecompanydetails",
      {
        publicationId,
        companyDetails,
        avoidTopics,
        focusTopics,
        sampleOutboundMessage
      },
      callback
    );
  };

  const getCompanyDetails = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getcompanydetails", { publicationId }, callback);
  };

  const saveProductCategory = (
    publicationId: string,
    productCategory: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveproductcategory",
      { publicationId, productCategory },
      callback
    );
  };

  const getProductCategory = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getproductcategory", { publicationId }, callback);
  };

  const saveStyleGuideRules = (
    publicationId: any,
    styleGuideRules: any,
    callback: any
  ) => {
    createApiCall(
      "savestyleguiderules",
      { publicationId, styleGuideRules },
      callback
    );
  };

  const getStyleGuideRules = (publicationId: any, callback: any) => {
    createApiCall("getstyleguiderules", { publicationId }, callback);
  };

  const getReportEEAT = (postId: any, keyword: any, callback: any) => {
    createSEOCall("getreporteeat", { postId, keyword }, callback);
  };

  const getSuggestionsEEAT = (postId: any, suggestions: any, callback: any) => {
    createSEOCall("getsuggestionseeat", { postId, suggestions }, callback);
  };
  const getRewrittenArticleWithKeywords = (
    postId: any,
    keywords: any,
    referenceId: any,
    callback: any
  ) => {
    createSEOCall(
      "getsuggestionswithkeywords",
      { postId, keywords, referenceId },
      callback
    );
  };

  const getFAQ = (
    publicationId: any,
    postId: any,
    keyword: any,
    callback: any
  ) => {
    createSEOCall("getfaq", { publicationId, postId, keyword }, callback);
  };

  const getCoveredFaqs = (keyword: any, postId: any, callback: any) => {
    createSEOCall("getcoveredfaqs", { keyword, postId }, callback);
  };

  const getKeywordSuggestion = (
    postId: any,
    keyword: any,
    type: any,
    callback: any
  ) => {
    createSEOCall("addkeywordinparagraph", { postId, type, keyword }, callback);
  };

  const getTLDR = (postId: any, callback: any) => {
    createSEOCall("gettldr", { postId }, callback);
  };

  const getKeywordSuggestionInHeaders = (
    postId: any,
    keyword: any,
    callback: any
  ) => {
    createSEOCall("addkeywordinheadings", { postId, keyword }, callback);
  };

  const renameImage = (imageUrl: any, newName: any, id: any, callback: any) => {
    createApiCall("renameimage", { imageUrl, newName, id }, callback);
  };

  const generateSocialPostFromBlog = (
    publicationId: string,
    postId: string,
    blogMarkup: string,
    regenerate: boolean,
    socialType: string,
    instructions: string,
    identifier: string = "",
    samples: string[] = [],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "generatesocialpostfromblog",
      {
        publicationId,
        postId,
        blogMarkup,
        regenerate,
        socialType,
        instructions,
        identifier,
        samples
      },
      callback
    );
  };

  const suggestLinkedinTemplatesFromPost = (
    data: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "suggestlinkedintemplatesfrompost",
      { ...data },
      callback
    );
  };

  const extractSpeakerTagFromTranscript = (
    data,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "extractspeakertagfromtranscript",
      { ...data },
      callback
    );
  };

  const generateCompanyDetails = (
    data,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall("generatecompanydetails", { ...data }, callback);
  };

  const generateLinkedinPostsFromTranscript = (
    data,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "generatelinkedinpostsfromtranscript",
      { ...data },
      callback
    );
  };

  const regenerateLinkedinPostFromTranscript = (
    data,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "regeneratelinkedinpostfromtranscript",
      { ...data },
      callback
    );
  };

  const generateImageCaptionAndAltText = (imageUrl: any, callback: any) => {
    createApiCall("generateimagecaptionandalttext", { imageUrl }, callback);
  };

  const saveInstructions = (
    publicationId: any,
    postId: any,
    socialType: any,
    instructions: any,
    callback: any
  ) => {
    createApiCall(
      "saveinstructions",
      { publicationId, postId, socialType, instructions },
      callback
    );
  };

  const getKeywordSuggestions = (
    keyword: any,
    postId: any,
    publicationId: any,
    callback: any
  ) => {
    createWorkflowCall(
      "getkeywordsuggestions",
      { keyword, postId, publicationId },
      callback
    );
  };

  const initializeTopQuestionsExtraction = (
    publicationId: any,
    postId: any,
    keyword: any,
    outlineType: any,
    topicId: any,
    reanalyze: boolean,
    outlineBrief: string,
    persona: string[],
    features: string[],
    links: string[],
    outlinePages: string[],
    callback: any
  ) => {
    createSEOCall(
      "initializetopquestionsextraction",
      {
        publicationId,
        postId,
        keyword,
        outlineType,
        topicId,
        reanalyze,
        outlineBrief,
        persona,
        features,
        links,
        outlinePages
      },
      callback
    );
  };

  const getTopQuestions = (keyword: any, postId: any, callback: any) => {
    createSEOCall("gettopquestions", { keyword, postId }, callback);
  };

  const initializeInformationGainExtraction = (
    publicationId: string,
    postId: string,
    keyword: string,
    writerIntent: string,
    reanalyze: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "initializeinformationgainextraction",
      {
        publicationId,
        postId,
        keyword,
        writerIntent,
        reanalyze
      },
      callback
    );
  };

  const getInformationGain = (
    keyword: string,
    publicationId: string,
    postId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "getinformationgain",
      { keyword, publicationId, postId },
      callback
    );
  };

  const getCoveredTablestakes = (keyword: any, postId: any, callback: any) => {
    createSEOCall("getcoveredtablestakes", { keyword, postId }, callback);
  };

  const generateContentFromTopic = (
    urls: any,
    question: any,
    keyword: any,
    publicationId: any,
    referenceId: any,
    callback: any
  ) => {
    createSEOCall(
      "generatecontentfromtopic",
      { urls, question, keyword, publicationId, referenceId },
      callback
    );
  };

  const generateAnswerForPAA = (
    question: string,
    keyword: string,
    referenceId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "generateAnswerForPAA",
      { question, keyword, referenceId },
      callback
    );
  };

  const deleteFileAfterTimeout = (url: any, timeout: any, callback: any) => {
    createUnAuthenticatedCall(
      "deleteFileAfterTimeout",
      { url, timeout },
      callback
    );
  };

  const getSearchIntentResults = (
    publicationId: any,
    postId: any,
    keyword: any,
    callback: any
  ) => {
    createApiCall(
      "getsearchintentresults",
      { keyword, publicationId, postId },
      callback
    );
  };

  const getAllEngagedAccounts = (
    publicationId: string,
    pipelineFilter: string,
    searchTerm: string,
    limit: number,
    offset: number,
    callback: (res: axiosResponse) => void
  ) => {
    createApiCall(
      "getAllEngagedAccounts",
      { publicationId, pipelineFilter, limit, offset, searchTerm },
      callback
    );
  };

  const getAllEngagedAccountsGraphData = (
    publicationId: string,
    startDate: any,
    endDate: any,
    callback: (res: axiosResponse) => void
  ) => {
    createApiCall(
      "getallengagedaccountsgraphdata",
      { publicationId, startDate, endDate },
      callback
    );
  };

  const getAggregatedContentRevenue = (
    publicationId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    limit: number,
    offset: number,
    callback: (res: axiosResponse) => void
  ) => {
    createApiCall(
      "getAggregatedContentRevenue",
      { publicationId, startDate, endDate, limit, offset },
      callback
    );
  };

  const getTotalContentRevenue = (
    publicationId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    callback: (res: axiosResponse) => void
  ) => {
    createApiCall(
      "gettotalcontentrevenue",
      { publicationId, startDate, endDate },
      callback
    );
  };

  const getTotalLinkedinContentRevenueFromBQ = (
    publicationId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    callback: (res: axiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-total-linkedin-content-revenue",
      { publicationId, startDate, endDate },
      callback
    );
  };

  const getFeaturedSnippet = (
    publicationId: string,
    keyword: string,
    callback: (res: axiosResponse) => void
  ) => {
    createApiCall("getfeaturedsnippet", { keyword, publicationId }, callback);
  };

  const boostLinkedInPost = (
    url: any,
    commentURN: any,

    draftMessage: any,
    automateLikeAccounts: any,
    accountsToAutoComment: any,
    individualComments: any,
    publicationId: any,
    reactionType: any,
    autoLikePostTiming: string,
    callback: any
  ) => {
    createLinkedInApiCall(
      "boostlinkedinpost",
      {
        url,
        commentURN,
        draftMessage,
        automateLikeAccounts,
        accountsToAutoComment,
        publicationId,
        reactionType,
        individualComments,
        autoLikePostTiming
      },
      callback
    );
  };

  const getEEATQualityRating = (postId: any, keyword: any, callback: any) => {
    createApiCall("geteeatqualityrating", { postId, keyword }, callback);
  };
  const initializeEEATQualityRating = (
    postId: any,
    keyword: any,
    callback: any
  ) => {
    createApiCall("initializeeeatqualityrating", { postId, keyword }, callback);
  };

  const generateMetQualityRating = (
    postId: any,
    keyword: any,
    callback: any
  ) => {
    createApiCall("generatemetqualityrating", { postId, keyword }, callback);
  };

  const deleteWebflowAccessToken = (
    publicationId: any,
    accountId: any,
    callback: any
  ) => {
    createWebFlowCall(
      "deletewebflowaccesstoken",
      { publicationId, accountId },
      callback
    );
  };

  const importFromSitemap = (
    sitemap: any,
    url: any,
    publicationId: any,
    callback: any
  ) => {
    createApiCall(
      "importfromsitemap",
      { sitemap, url, publicationId },
      callback
    );
  };

  const getMetadataFromURL = (url: any, callback: any) => {
    createApiCall("getmetadatafromurl", { url }, callback);
  };

  const getPostsByTitleSearchQuery = (
    publicationId: any,
    searchQuery: any,
    callback: any
  ) => {
    createApiCall(
      "getpostsbytitlesearchquery",
      { publicationId, searchQuery },
      callback
    );
  };

  const scrapeTopPages = (publicationId: any, keyword: any, callback: any) => {
    createApiCall("scrapetoppages", { publicationId, keyword }, callback);
  };

  const fetchTopPages = (publicationId: any, keyword: any, callback: any) => {
    createApiCall("fetchtoppages", { publicationId, keyword }, callback);
  };

  const processKeywordsForProgrammaticSEO = (
    data: any,
    openAIKey: any,
    emails: any,
    prompt: any,
    advancedGeneration: any,
    callback: any
  ) => {
    createApiCall(
      "processkeywordsforprogrammaticseo",
      { data, openAIKey, emails, prompt, advancedGeneration },
      callback
    );
  };

  const updateLastUserLogin = (publicationId: any, callback: any) => {
    createApiCall("updatelastuserlogin", { publicationId }, callback);
  };

  const getSendBlogNewsletterStatus = (publicationId: any, callback: any) => {
    createApiCall("getsendblognewsletterstatus", { publicationId }, callback);
  };

  const fetchFirstPageFromBlogLink = (
    publicationId: any,
    blogLink: any,
    keyword: string,
    postId: string,
    callback: any
  ) => {
    createApiCall(
      "fetchfirstpagefrombloglink",
      { publicationId, blogLink, keyword, postId },
      callback
    );
  };

  const updateClientPreferences = (preference: string, callback: any) => {
    createApiCall("updateclientpreferences", { preference }, callback);
  };

  const resetGetStarted = (callback: any) => {
    createApiCall("resetgetstarted", {}, callback);
  };

  const trackSEOUsage = (
    publicationId: any,
    keyword: any,
    postId: any,
    callback: (res: axiosResponse) => void
  ) => {
    createSEOCall(
      "trackseousage",
      { publicationId, keyword, postId },
      callback
    );
  };

  const trackOutlineGenerationUsage = (
    publicationId: string,
    isKeywordSame: boolean,
    callback: (res: axiosResponse) => void
  ) => {
    createSEOCall(
      "trackoutlinegenerationusage",
      { publicationId, isKeywordSame },
      callback
    );
  };

  const identifyCoveredTablestakes = (
    keyword: any,
    postId: any,
    publicationId: any,
    callback: any
  ) => {
    createSEOCall(
      "identifycoveredtablestakes",
      { keyword, postId, publicationId },
      callback
    );
  };

  const getOutlineGeneration = (
    keyword: any,
    outlineType: any,
    publicationId: any,
    topicId: any,
    outlineBrief: string,
    persona: string[],
    features: string[],
    links: string[],
    outlinePages,
    callback: any
  ) => {
    createSEOCall(
      "getoutlinegeneration",
      {
        keyword,
        outlineType,
        publicationId,
        topicId,
        outlineBrief,
        persona,
        features,
        links,
        outlinePages
      },
      callback
    );
  };

  const getURLMetadata = (url: any, callback: any) => {
    createApiCall("getmediatype", { url }, callback);
  };

  const identifyCoveredFAQ = (keyword: any, postId: any, callback: any) => {
    createSEOCall("identifycoveredfaq", { keyword, postId }, callback);
  };

  const updateLinkedInOrgs = (
    publicationId: any,
    linkedInOrgs: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "updatelinkedinorgs",
      { publicationId, linkedInOrgs },
      callback
    );
  };

  const getSeoFilteredPages = (postId: any, keyword: any, callback: any) => {
    createSEOCall("getseofilteredpages", { postId, keyword }, callback);
  };

  const saveSeoFilteredPages = (
    publicationId: any,
    postId: any,
    keyword: any,
    pages: any,
    callback: any
  ) => {
    createSEOCall(
      "saveseofilteredpages",
      { publicationId, postId, keyword, pages },
      callback
    );
  };

  const generateLinkedInCampaign = (
    publicationId: string,
    postId: string,
    campaignId: string,
    numberOfPostsForCampaign: number,
    accountsForCampaign: Array<LinkedInAccount>,
    callback
  ) => {
    createLinkedInApiCall(
      "generatelinkedincampaign",
      {
        postId,
        publicationId,
        campaignId,
        numberOfPostsForCampaign,
        accountsForCampaign
      },
      callback
    );
  };

  const saveLinkedInPermissions = (
    publicationId: any,
    linkedInPermissions: any,
    callback: any
  ) => {
    createLinkedInApiCall(
      "savelinkedinpermissions",
      { publicationId, linkedInPermissions },
      callback
    );
  };

  const exportLinkedinEngagements = (
    publicationId: string,
    filters: any,
    startDate: any,
    endDate: any,
    isThirdPartyLeads: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "exportLinkedinEngagements",
      { publicationId, filters, startDate, endDate, isThirdPartyLeads },
      callback
    );
  };

  const getKeywordTopPagesDetails = (
    keyword: any,
    pages: any,
    callback: any
  ) => {
    createSEOCall("getkeywordtoppagesdetails", { keyword, pages }, callback);
  };

  const saveSecondaryKeywords = (
    secondaryKeywords: string[],
    postId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "savesecondarykeywords",
      { secondaryKeywords, postId },
      callback
    );
  };

  const getLinkedInAnalytics = (
    publicationId: string,
    statsType: string,
    startDate: any,
    endDate: any,
    account: string,
    callback: any
  ) => {
    createLinkedInApiCall(
      "getlinkedinanalytics",
      {
        publicationId,
        statsType,
        startDate,
        endDate,
        account
      },
      callback
    );
  };

  const getLinkedInAnalyticsForAllAccounts = (
    publicationId: string,
    startDate: string,
    endDate: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-analytics-for-all-accounts",
      {
        publicationId,
        startDate,
        endDate
      },
      callback
    );
  };

  const getLinkedInPostStats = (
    publicationId: string,
    searchQuery: string,
    limit: number,
    offset: number,
    dateSort: boolean,
    clickSort: boolean,
    impressionSort: boolean,
    engagementSort: boolean,
    startDate: any,
    endDate: any,
    accountId: string,
    callback: any
  ) => {
    createLinkedInApiCall(
      "getlinkedinpoststats",
      {
        publicationId,
        searchQuery,
        limit,
        offset,
        dateSort,
        startDate,
        endDate,
        clickSort,
        impressionSort,
        engagementSort,
        accountId
      },
      callback
    );
  };

  const getErrorNotifications = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("geterrornotifications", { publicationId }, callback);
  };

  const markErrorNotificationAsRead = (
    publicationId: string,
    notificationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "markerrornotificationasread",
      {
        publicationId,
        notificationId
      },
      callback
    );
  };

  const addMarketingCampaign = (
    name: string,
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "addmarketingcampaign",
      { name, publicationId },
      callback
    );
  };

  const sendSubjectQuoteRequest = (
    publicationId: string,
    postId: string,
    email: string,
    question: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "sendsubjectquoterequest",
      { publicationId, postId, email, question },
      callback
    );
  };

  const saveSubjectQuoteVideoUrl = (
    subjectQuoteRequestId: string,
    videoURL: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "savesubjectquotevideourl",
      { subjectQuoteRequestId, videoURL },
      callback
    );
  };

  const getSubjectQuoteRequest = (
    subjectQuoteRequestId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "getsubjectquoterequest",
      { subjectQuoteRequestId },
      callback
    );
  };

  const uploadSubjectQuoteVideoToYoutube = (
    subjectQuoteRequestId: string,
    publicationId: string,
    title: string,
    description: string,
    videoPrivacyStatus: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "uploadSubjectQuoteVideoToYoutube",
      {
        subjectQuoteRequestId,
        publicationId,
        title,
        description,
        videoPrivacyStatus
      },
      callback
    );
  };

  const downloadSubjectQuoteVideo = (
    subjectQuoteRequestId: string,
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createWorkflowCall(
      "downloadSubjectQuoteVideo",
      {
        subjectQuoteRequestId,
        publicationId
      },
      callback
    );
  };

  const getLinkedInIntentAccounts = (
    publicationId: string,
    filters: any,
    searchTerm: string,
    startDate: any,
    endDate: any,
    sortModel: any,
    limit: number,
    offset: number,
    isThirdPartyLeads: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getlinkedinintentaccounts",
      {
        publicationId,
        filters,
        searchTerm,
        startDate,
        endDate,
        sortModel,
        limit,
        offset,
        isThirdPartyLeads
      },
      callback
    );
  };

  const addToSavedICPFilters = (
    publicationId: string,
    filterName: string,
    filters: unknown,
    isFilterOptionsInEditMode: boolean,
    originalFilterName: string = "",
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "add-to-saved-icp-filters",
      {
        publicationId,
        icpFilter: { ...filters, filterName },
        isFilterOptionsInEditMode,
        originalFilterName
      },
      callback
    );
  };

  const deleteFromSavedICPFilters = (
    publicationId: string,
    filterName: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "delete-from-saved-icp-filters",
      { publicationId, filterName },
      callback
    );
  };

  const getSavedICPFilters = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("get-saved-icp-filters", { publicationId }, callback);
  };

  const setEngagedICPFilter = (
    publicationId: string,
    filters: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "set-engaged-icp-filter",
      { publicationId, engagedICPFilter: filters },
      callback
    );
  };

  const getKeywordMonitoringFilters = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "get-social-keyword-monitoring-filter",
      { publicationId },
      callback
    );
  };

  const setKeywordMonitoringFilters = (
    publicationId: string,
    filters: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "set-social-keyword-monitoring-filter",
      { publicationId, socialKeywordMonitoringFilter: filters },
      callback
    );
  };

  const getKeywordMonitoringPosts = (
    publicationId: string,
    page: number,
    limit: number,
    sortModel: any,
    filterModel: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "get-social-keyword-monitoring-posts",
      { publicationId, page, limit, sortModel, filterModel },
      callback
    );
  };

  const saveAutoDraftOutboundConfigure = (
    publicationId: string,
    autoDraftOutbound: boolean,
    autoDraftOutboundConfigure: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "save-auto-draft-outbound-configure",
      { publicationId, autoDraftOutbound, autoDraftOutboundConfigure },
      callback
    );
  };

  const setKeywordMonitoringPostsCsvEmails = (
    publicationId: string,
    emails: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "set-social-keyword-monitoring-posts-csv-emails",
      { publicationId, emails },
      callback
    );
  };

  const getUserAccountActivities = (
    data: {
      publicationId: string;
      userId: string;
      domain: string;
      limit: number;
      offset: number;
      jobId: string;
    },
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getuseraccountactivities", data, callback);
  };

  const getAccountInfo = (
    publicationId: string,
    userId: string,
    domain: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getAccountInfo",
      { publicationId, userId, domain },
      callback
    );
  };

  const getPublishingDataDetails = (
    postId: string,
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getpublishingdatadetails",
      { postId, publicationId },
      callback
    );
  };

  const getPostVisits = (
    postId: string,
    publicationId: string,
    limit: number,
    offset: number,
    filter: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getpostvisits",
      { postId, publicationId, limit, offset, filter },
      callback
    );
  };

  const refreshPAA = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "refreshpaa",
      {
        publicationId
      },
      callback
    );
  };

  const getPostInfluenceData = (
    publicationId: string,
    postId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getpostinfluencedata", { publicationId, postId }, callback);
  };

  const sendReconnectRequest = (
    publicationId: string,
    userId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "sendReconnectRequest",
      { publicationId, userId },
      callback
    );
  };

  const saveImageCropSizes = (
    publicationId: string,
    coverImage: string,
    thumbnail: string,
    callback: () => void
  ) => {
    createApiCall(
      "saveimagecropsizes",
      {
        publicationId,
        coverImage,
        thumbnail
      },
      callback
    );
  };

  const saveAccountSegment = (
    publicationId: string,
    accountSegment: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveaccountsegment",
      { publicationId, accountSegment },
      callback
    );
  };

  const saveContentPathConditions = (
    publicationId: string,
    contentPathConditions: any[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveContentPathConditions",
      { publicationId, contentPathConditions },
      callback
    );
  };

  const sendBotToMeeting = (
    meetingUrl: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("send-bot-to-meeting", { meetingUrl }, callback);
  };

  const getBotDetails = (
    botId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("get-bot-details", { botId }, callback);
  };

  const answerQuestionFromSalesCall = (
    publicationId: string,
    botId: string,
    questionData: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "answer-question-from-sales-call",
      { publicationId, botId, questionData },
      callback
    );
  };

  const saveIndexPathConditions = (
    publicationId: string,
    indexPathConditions: any[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveindexpathconditions",
      { publicationId, indexPathConditions },
      callback
    );
  };

  const getAccountSegments = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getaccountsegments", { publicationId }, callback);
  };

  const updateLinkedInAccountGroup = (
    publicationId: string,
    accountId: string,
    group: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "updatelinkedinaccountgroup",
      { publicationId, accountId, group },
      callback
    );
  };

  const deleteAccountSegment = (
    publicationId: string,
    accountSegmentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "deleteaccountsegment",
      { publicationId, accountSegmentId },
      callback
    );
  };

  const generateCompanyDescription = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("generate-company-description", { publicationId }, callback);
  };

  const saveFeature = (
    publicationId: string,
    feature: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("savefeature", { publicationId, feature }, callback);
  };

  const getFeatures = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getfeatures", { publicationId }, callback);
  };

  const getQuestions = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getquestions", { publicationId }, callback);
  };

  const deleteFeature = (
    publicationId: string,
    featureId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("deletefeature", { publicationId, featureId }, callback);
  };

  const deleteQuestion = (
    publicationId: string,
    questionId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("deletequestion", { publicationId, questionId }, callback);
  };

  const toggleQuestionNotifications = (
    publicationId: string,
    questionNotification: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "togglequestionnotifications",
      { publicationId, questionNotification },
      callback
    );
  };

  const saveQuestionReportEmails = (
    publicationId: string,
    questionReportEmails: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "savequestionreportemails",
      { publicationId, questionReportEmails },
      callback
    );
  };

  const savePersona = (
    publicationId: string,
    persona: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("savepersona", { publicationId, persona }, callback);
  };

  const getPersonas = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getpersonas", { publicationId }, callback);
  };

  const getSalescallEvents = (
    publicationId: string,
    page: number,
    limit: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getsalescallevents",
      { publicationId, page, limit },
      callback
    );
  };

  const deletePersona = (
    publicationId: string,
    personaId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("deletepersona", { publicationId, personaId }, callback);
  };

  const suggestBuyerTitles = (
    publicationId: string,
    baseTitle: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("suggestbuyertitles", { publicationId, baseTitle }, callback);
  };

  const savePostOutlineInstruction = (
    postId: string,
    outlineBrief: string,
    persona: string,
    features: string[],
    links: string[],
    outlinePages: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "savepostoutlineinstruction",
      {
        postId,
        outlineBrief,
        persona,
        features,
        links,
        outlinePages
      },
      callback
    );
  };

  const saveClearbitDetails = (
    publicationId: string,
    apiKey: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("saveclearbitdetails", { publicationId, apiKey }, callback);
  };

  const saveApolloDetails = (
    publicationId: string,
    apiKey: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("saveapollodetails", { publicationId, apiKey }, callback);
  };

  const getOutputStats = (
    publicationId: string,
    startDate: Moment,
    endDate: Moment,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getoutputstats",
      { publicationId, startDate, endDate },
      callback
    );
  };

  const getTopPagesForKeyword = (
    keyword: string,
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createSEOCall(
      "gettoppagesforkeyword",
      { keyword, publicationId },
      callback
    );
  };

  const getAccountNames = (
    publicationId: string,
    searchTerm: string,
    limit: number,
    offset: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getaccountnames",
      { publicationId, searchTerm, limit, offset },
      callback
    );
  };

  const getAllDealStages = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getalldealstages", { publicationId }, callback);
  };

  const getAllDealOwners = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getalldealowners", { publicationId }, callback);
  };

  const addQuestionToReport = (
    publicationId: string,
    questionId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "addquestiontoreport",
      { publicationId, questionId },
      callback
    );
  };

  const getConnectedOrganizations = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "getconnectedorganizations",
      { publicationId },
      callback
    );
  };

  const getContentIndexed = (
    publicationId: string,
    selectedTypes: optionType[],
    limit: number,
    offset: number,
    searchTerm: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "getcontentindexed",
      { publicationId, selectedTypes, limit, offset, searchTerm },
      callback
    );
  };

  const deleteIndexedPage = (
    publicationId: string,
    url: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("deleteindexedpage", { publicationId, url }, callback);
  };

  const contentIndex = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("contentindex", { publicationId }, callback);
  };

  const generateSocialPostFromInsight = (
    publicationId: string,
    callId: string,
    insightId: string,
    linkedinPostId: string,
    userId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "generatesocialpostfrominsight",
      { publicationId, callId, insightId, linkedinPostId, userId },
      callback
    );
  };

  const updateSalesCallInsights = (
    publicationId: string,
    callId: string,
    insightId: string,
    salesCallInsightsData: SalesCallInsightsData,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "updatesalescallinsights",
      { publicationId, callId, insightId, salesCallInsightsData },
      callback
    );
  };

  const getSocialSellingDetails = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("getsocialsellingdetails", { publicationId }, callback);
  };

  const saveKeywordMonitoringGroups = (
    publicationId: string,
    keywordMonitoringGroups: KeywordMonitoringGroupType[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveKeywordMonitoringGroups",
      { publicationId, keywordMonitoringGroups },
      callback
    );
  };

  const regenerateLinkedInPost = (
    generatedPostId: string,
    socialPostId: string,
    sample: string,
    instructions: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "regeneratelinkedinpost",
      { generatedPostId, socialPostId, sample, instructions },
      callback
    );
  };

  const saveSocialSellingRoles = (
    accountId: string = "",
    vanityName: string,
    publicationId: string,
    roles: string[],
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "savesocialsellingroles",
      { vanityName, publicationId, roles, accountId },
      callback
    );
  };

  const suggestSocialSellingKeywords = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("suggestsocialsellingkeywords", { publicationId }, callback);
  };

  const saveAccountsForMonitoring = (
    publicationId: string,
    vanityNames: string[],
    companies: Companies,
    jobTitles: string[],
    seniorities: Seniority[],
    industries: LinkedinIndustry[],
    geographies: Geography[],
    type: string,
    csvFileUrl: string,
    searchURL,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "saveaccountsformonitoring",
      {
        publicationId,
        vanityNames,
        companies,
        jobTitles,
        seniorities,
        industries,
        geographies,
        type,
        csvFileUrl,
        searchURL
      },
      callback
    );
  };

  const generateCommentsForAccountMonitoringPost = (
    accountMonitoringPostId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "generate-comments-for-account-monitoring-post",
      { accountMonitoringPostId },
      callback
    );
  };

  const getAccountMonitoringPostsByGroup = (
    slackNotificationGroupId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-account-monitoring-posts-by-group",
      { slackNotificationGroupId },
      callback
    );
  };

  const commentOnAccountMonitoringPost = (
    publicationId: string,
    postId: string,
    comment: string,
    autoLike: boolean,
    autoConnect: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "comment-on-account-monitoring-post",
      { publicationId, postId, comment, autoLike, autoConnect },
      callback
    );
  };

  const likeAndConnectAccountMonitoringPost = (
    publicationId: string,
    postId: string,
    autoLike: boolean,
    autoConnect: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "like-account-monitoring-post",
      { publicationId, postId, autoLike, autoConnect },
      callback
    );
  };

  const generateMessageForLinkedinDmAccount = (
    publicationId: string,
    accountId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "generate-message-for-linkedin-dm-account",
      { publicationId, accountId },
      callback
    );
  };

  const getLinkedinDmAccountsByGroup = (
    slackNotificationGroupId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-dm-accounts-by-group",
      { slackNotificationGroupId },
      callback
    );
  };

  const getLinkedInActivityLogForPublication = (
    publicationId: string,
    userFilter: IUsersFilterOptions[],
    activityTypeFilter: IActivityTypeFilterOptions[],
    statusFilter: IStatusFilterOptions[],
    startDate: Moment,
    endDate: Moment,
    page: number,
    rowsPerPage: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-activity-log-for-publication",
      {
        publicationId,
        startDate,
        endDate,
        page,
        rowsPerPage,
        userFilter,
        activityTypeFilter,
        statusFilter
      },
      callback
    );
  };

  const getLinkedInActivityLogForPublicationUsers = (
    publicationId: string,
    userFilter: IUsersFilterOptions[] = [],
    activityTypeFilter: IFilterOptions[] = [],
    statusFilter: IFilterOptions[] = [],
    startDate: Moment,
    endDate: Moment,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-activity-log-for-publication-users",
      {
        publicationId,
        startDate,
        endDate,
        userFilter,
        activityTypeFilter,
        statusFilter
      },
      callback
    );
  };

  const getLinkedInActivityLogFilterOptions = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-linkedin-activity-log-filter-options",
      { publicationId },
      callback
    );
  };

  const scheduleMessageToLinkedinDmAccount = (
    publicationId: string,
    accountId: string,
    outboundMessage: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "schedule-message-to-linkedin-dm-account",
      { publicationId, accountId, outboundMessage },
      callback
    );
  };

  const updateKeywordMonitoringAccountContactedBy = (
    publicationId: string,
    accountIds: Array<GridRowId>,
    markAsContacted: Boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "update-keyword-monitoring-account-contacted-by",
      { publicationId, accountIds, markAsContacted },
      callback
    );
  };

  const updateContactedBy = (
    publicationId: string,
    posts: {
      vanityName: string;
      context: string;
    }[],
    markAsContacted: Boolean,
    reason: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "update-engaged-account-contacted-by",
      { publicationId, posts, markAsContacted, reason },
      callback
    );
  };

  const getDms = (
    publicationId: string,
    status: string,
    startDate: Date,
    endDate: Date,
    limit: number,
    offset: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "get-dms",
      { publicationId, status, startDate, endDate, limit, offset },
      callback
    );
  };

  const addConnectionsToQueue = (
    data,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "addConnectionsToQueue",
      {
        ...data
      },
      callback
    );
  };

  const searchLinkedInLocation = (
    publicationId: string,
    account: string,
    location: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "searchLinkedInLocation",
      {
        publicationId,
        location,
        account
      },
      callback
    );
  };

  const getAllLinkedinIndustries = (callback: (res: AxiosResponse) => void) => {
    createLinkedInApiCall("getAllIndustries", {}, callback);
  };

  const checkIfGoogleMeetHasToBeLinked = (
    publicationId: string,
    email: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "check-if-google-meet-has-to-be-linked",
      { publicationId, email },
      callback
    );
  };

  const toggleMeetingToolIntegrationStatus = (
    publicationId: string,
    meetingToolIntegration: MeetingToolIntegrationEnum,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "toggle-meeting-tool-integration-status",
      { publicationId, integrationType: meetingToolIntegration },
      callback
    );
  };

  const disconnectGmeetAccount = (
    publicationId: string,
    email: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "disconnect-gmeet-account",
      { publicationId, email },
      callback
    );
  };

  const getUploadedAccountFromAccountMonitoring = (
    publicationId: string,
    limit: number,
    offset: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "get-uploaded-account-from-account-monitoring",
      { publicationId, limit, offset },
      callback
    );
  };

  const getAccountMonitoringPosts = (
    publicationId: string,
    page: number,
    limit: number,
    sortModel: any,
    filterModel: any,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "get-account-monitoring-posts",
      {
        publicationId,
        page,
        limit,
        // includePostFromKeywordMonitoring: true,
        sortModel,
        filterModel
      },
      callback
    );
  };

  const actOnAccountActivity = (
    notification: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "act-on-account-activity",
      { notification },
      callback
    );
  };

  const getAccountMonitoringDetails = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "get-account-monitoring-details",
      { publicationId },
      callback
    );
  };

  const getExtensionActivityLogs = (
    publicationId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("get-extension-activity-logs", { publicationId }, callback);
  };

  const collectLeads = (
    publicationId: string,
    posts: { url: string }[],
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("collect-leads", { publicationId, posts }, callback);
  };

  const getLeadPosts = (
    publicationId: string,
    isThirdPartyPost: boolean,
    offset: number,
    limit: number,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-lead-posts",
      { publicationId, isThirdPartyPost, offset, limit },
      callback
    );
  };

  const getPostLeads = (
    publicationId: string,
    postId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createLinkedInApiCall(
      "get-lead-posts",
      { publicationId, postId },
      callback
    );
  };

  const updateAutoCollectLeadsFromKeywords = (
    publicationId: string,
    autoCollectLeads: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "update-auto-collect-leads-from-keywords",
      { publicationId, autoCollectLeads },
      callback
    );
  };

  const updateCampaignAutoDraftOutlineFlag = (
    publicationId: string,
    autoDraftCampaignOutbound: boolean,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "update-campaign-auto-draft-outline-flag",
      { publicationId, autoDraftCampaignOutbound },
      callback
    );
  };

  const updateCampaignOutboundConfig = (
    publicationId: string,
    campaignOutboundConfig: CampaignOutboundConfig | null,
    slackChannel: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "update-campaign-outbound-config",
      { publicationId, campaignOutboundConfig, slackChannel },
      callback
    );
  };

  const saveKeywordMonitoringGroupNotification = (
    publicationId: string,
    groupId: string,
    notification: keywordNotificationSettings,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall(
      "save-keyword-monitoring-group-notification",
      { publicationId, groupId, notification },
      callback
    );
  };

  return {
    getLinkedInAnalytics,
    disconnectGmeetAccount,
    toggleMeetingToolIntegrationStatus,
    checkIfGoogleMeetHasToBeLinked,
    getLinkedInAnalyticsForAllAccounts,
    getLinkedInPostStats,
    fetchFirstPageFromBlogLink,
    registerUser,
    updateTimezone,
    saveLinkedInPermissions,
    exportLinkedinEngagements,
    getClient,
    requestInvite,
    getSessionId,
    saveBillingConfiguration,
    verifyInviteCode,
    savePublication,
    generateLinkedInCampaign,
    getAllPublications,
    savePost,
    getPosts,
    getPost,
    saveAsDraft,
    getPublicationPostImprovements,
    updatePostImprovementSuggestionStatus,
    getPageCountForContentRefreshTabs,
    getPublicationPostCount,
    getPostWebStat,
    getPublicationForReferral,
    addReferralSubscriber,
    getEmbedDetail,
    getUnsubscribeData,
    saveProfile,
    saveCard,
    publishPost,
    addPageView,
    getPublication,
    findClientByMail,
    getPublications,
    saveEmailDetails,
    saveStyleGuide,
    getPublicationById,
    getPublicationPublicData,
    getWebflowAndBlogDetails,
    autoPopulateWebflowCollectionFields,
    getReviewDetails,
    savePublicationPicture,
    saveProfilePicture,
    savePublicationField,
    enableWelcomeEmail,
    saveTitle,
    saveCustomDomain,
    saveEmailDomain,
    saveEmailDetailsField,
    unpublishPost,
    unschedulePost,
    duplicatePost,
    deletePost,
    getEmailDomainStatus,
    getConnectedAccount,
    updateTwitterScheduledDate,
    updateFacebookScheduledDate,
    updateInstagramScheduledDate,
    updateRedditScheduledDate,
    updateLinkedInScheduledDate,
    getLinkedinAccountLocations,
    updatePlaceholderScheduledDate,
    updateScheduledPostDate,
    updateScheduledProjectDate,
    recalculateProjectDates,
    connectToTwitter,
    createTwitterAccessToken,
    connectClientToTwitter,
    createTwitterAccessTokenforClient,
    addAnalytics,
    getSourcesForPost,
    getComments,
    saveComment,
    getReplies,
    saveCommentReply,
    getHeaderDetails,
    getLatestPosts,
    validateHtml,
    refreshToken,
    saveWebhookUrls,
    testWebhookUrl,
    saveFeedbackSettings,
    addCollaborator,
    saveTemplate,
    saveIdeaTemplate,
    deleteIdeaTemplate,
    setDefaultIdeaTemplate,
    getAllTemplates,
    getIdeaTemplates,
    getTemplate,
    deleteTemplate,
    createTemplatePost,
    fetchTemplateData,
    checkIsOwnPublication,
    getSourcesForPublication,
    connectToLinkedIn,
    createLinkedInAccessToken,
    createGrainAccessToken,
    createZoomAccessToken,
    initiateGrainIntegration,
    setLinkedInApprovalEmail,
    disconnectAccount,
    saveFontChanges,
    resetWelcomeEmail,
    getFilteredPosts,
    getPostPictures,
    searchUnsplash,
    markAsDownload,
    enablePostFeedback,
    toggleSendBlogNewsletter,
    toggleFeedbackVote,
    saveFeedbackVotes,
    resetFeedbackVotes,
    openThankYouTemplate,
    resetThankYouTemplate,
    loadThankYouTemplate,
    savePreamble,
    removePreamble,
    saveCheckoutSubscription,
    getCollaborators,
    savePostAuthor,
    addImportRequest,
    savePublicationDeliverability,
    getInstagramData,
    connectToWebflow,
    getWebflowAccessToken,
    saveSelectedWebflowCollection,
    postToWebflowStaging,
    importFromWebflow,
    importFromHubspot,
    getPipelinesFromHubspot,
    setHubspotPipeline,
    getCRMcontactsConfigFromHubspot,
    saveCRMcontactsConfigFromHubspot,
    getPipelineFieldsFromSalesforce,
    setSalesforcePipelineFields,
    getContactFieldsFromSalesforce,
    getSalesforceConfig,
    setSalesforceContactFieldMapping,
    setLinkedInActivityPushToSalesforce,
    importFromWordpress,
    changeFeaturedPost,
    saveReviewDetails,
    getGoogleFonts,
    saveSocialLinks,
    getMapData,
    migrate,
    saveSignupViaEmail,
    saveEmailNotifications,
    subscribeToFreePlan,
    sendTeamInvitation,
    getTeamInviteURL,
    checkIsMobile,
    acceptTeamInvite,
    teamInviteExists,
    getTeammates,
    removeTeammate,
    getAuthorsAndDefaultAuthor,
    saveDefaultAuthor,
    saveDefaultSyntaxHighlightingLanguage,
    getPublicationCreatedOn,
    getCalendarEvents,
    getOverviewDetails,
    saveGetStartedEvents,
    getRequestPostDetails,
    savePostRequest,
    saveCalenderEvent,
    deleteCalenderEvent,
    saveAccentColor,
    getTimeToOpenGraphData,
    getURLMetadata,
    getEmailEngagementStats,
    getCustomFields,
    saveCustomFields,
    saveProjectCustomFields,
    getProjectCustomFields,
    uploadExternalImage,
    getEmbed,
    saveSubscribeText,
    getTopics,
    getTopicsDetail,
    saveTopic,
    createTopicFromPost,
    saveScheduleTweetDraft,
    saveTopicTemplate,
    updateTopicDetails,
    voteTopic,
    archiveTopic,
    moveProjectToIdeas,
    unarchiveTopic,
    deleteTopic,
    changePostToInReview,
    getTopic,
    getDraftTopicDetails,
    getDraftIdeaTemplateDetails,
    getTopicComments,
    sendReviewCommentToAuthor,
    saveTopicComment,
    getDashboardOverview,
    getUpcomingActions,
    getUpcomingPosts,
    getRecentlyPublishedPost,
    getEmailEngagementForDashboard,
    saveAudioPostsToggle,
    saveFooter,
    removeFooter,
    getDashboardPageViews,
    getDashboardPageViewGraph,
    getProjectBoardCards,
    updateTopicStatus,
    saveTopicTitle,
    downloadExport,
    downloadRecipient,
    getPageAccessDetails,
    getConnectedLinkedinAccounts,
    getThirdPartyPosts,
    getApolloSequences,
    getOutreachSequences,
    getApolloEmailAccounts,
    getOutreachEmailAccounts,
    addContactToApolloSequences,
    addContactToOutreachSequences,
    actOnLeads,
    getScrapeLimit,
    getLinkedInConnectedAccountDetails,
    getLinkedInConnectedAccountActivityLog,
    loadConnectedAccounts,
    changeTeammateRole,
    resetContentMapTemplate,
    resetStyleGuide,
    saveTweetText,
    saveAutoPostToSocialToggle,
    savePostSocialContent,
    getContentMap,
    saveContentMapChanges,
    requestApprovalForContentMap,
    approveContentMap,
    sendWPIntegrationRequest,
    connectToFacebook,
    saveFacebookAccessToken,
    getAllStatsNumbers,
    getDashboardEmailsOpen,
    getDashboardEmailsOpenGraph,
    getFreelancerProjects,
    getProjectChecklist,
    saveDownloadLink,
    acceptFreelancerInvite,
    connectToMedium,
    updateAssignmentReviewed,
    updateStyleGuideReviewed,
    seoContentAnalysis,
    assignPostKeywords,
    assignPostKeywordFromSlug,
    checkIfFeaturedSnippetIsCovered,
    startFetchingKeywordData,
    getOrganicPosts,
    getBacklinksForOrganicPost,
    getRelatedKeywordsWithPagination,
    saveExternalSite,
    deleteExternalSite,
    saveExternalSitemap,
    connectToHubSpot,
    createHubSpotAccessToken,
    saveHubspotIntegrationDetails,
    getAllContactsPropertiesHubspotCRM,
    checkPostOriginality,
    getScanDetails,
    getKeywordsAndTrafficForDomain,
    getTopKeywordsForDomain,
    getTopPagesForDomain,
    getTopKeywordsWithPagination,
    getTopPagesWithPagination,
    getDomainRank,
    getDomainBacklinks,
    seoLinksAnalysis,
    getKeywordSimilarity,
    seoLinksScore,
    getUpdatedLinksCount,
    getInternalLinkSuggestions,
    addContentMapFields,
    savePublicationContentMapFields,
    getCollectionFields,
    getCometChatUserDetails,
    checkAndCreateUserByEmail,
    getPublicationDomainBacklinks,
    getPostURLBacklinksData,
    getTeammembersAndApprovers,
    getTwitterDetails,
    saveTweetDetails,
    scheduleTweet,
    approveTweet,
    requestTweetReview,
    getFacebookShareDetails,
    saveFacebookPostDetails,
    shareToFacebook,
    deleteTweet,
    deleteFacebookPost,
    deleteRedditPost,
    createRedditAccessToken,
    connectToReddit,
    connectToWordPress,
    saveWordpressPassword,
    getSubreddits,
    getRedditDetails,
    getLinkedInDetails,
    saveScheduleLinkedInDraft,
    getLinkedinPost,
    getAddCommentsAndLikesRequest,
    postExternalComment,
    postExternalCommentWithoutPost,
    repost,
    repostWithoutPost,
    requestLinkedInReview,
    requestLinkedInReviewMultipleAccount,
    approveLinkedInPost,
    approveLinkedInPostExternal,
    approvePostAsTeammatePost,
    saveRedditPost,
    scheduleRedditPosts,
    importTopicIdeas,
    importFileForIndexing,
    importKeywords,
    deleteSocialMediaFromCalendar,
    getSocialMediaCalendarEvents,
    saveLinkedInShareDetails,
    shareToLinkedIn,
    deleteLinkedInPost,
    setTopicPosition,
    getProposedTopics,
    sendContentMapFeedback,
    generateTopicsFromProgrammaticTopic,
    loadProgrammaticTopics,
    loadGeneratedProgrammaticTopics,
    generateOutlinesFromTopicKeywords,
    discardSuggestedPosts,
    getKeywordStats,
    checkKeywordStats,
    getOrganicPostsForContentOutline,
    getTopPagesHeadings,
    getContentOutlineFromURL,
    getTopPagesHeadingsFromURL,
    getKeywordQuestions,
    getKeywordVariations,
    getKeywordRelated,
    getInstagramDetails,
    saveInstagramShareDetails,
    shareToInstagram,
    deleteInstagramPost,
    saveTemplateFooter,
    saveTemplatePreamble,
    savePublishToSocialFromPreviousPost,
    saveContentMapSettings,
    getBlogPostPreview,
    getTopCalendarEvents,
    turnEmailNotificationOff,
    verifyAndGetMediumDetails,
    saveMediumDetails,
    getMediumDetails,
    saveMediumAuthor,
    saveMediumPost,
    saveDevtoPost,
    getDevtoPost,
    deleteDevtoPost,
    saveDevtoDetails,
    saveHashnodeDetails,
    getHashnodePost,
    saveHashnodePost,
    deleteHashnodePost,
    assignBulkTopics,
    createAssignBulkTopics,
    saveTopicVideoUrl,
    saveIdeaTemplateVideoUrl,
    loomJWS,
    archiveBulkTopics,
    convertAtMentionToOrganization,
    unarchiveBulkTopics,
    undeletePost,
    getTopicStatus,
    getTeammatesForCalendar,
    autoLoginPostCollaborator,
    getSocialPosts,
    getLinkedInAccountsByPublicationId,
    saveSocialDraft,
    deleteSocialPost,
    getKeywordDataLocation,
    saveKeywordDataLocation,
    getAppNotificationCount,
    getAppNotification,
    setAppNotificationRead,
    setAllAppNotificationRead,
    postHeaders,
    replaceSocialMediaText,
    checkUserHaveSavedCard,
    saveBlogPostDestination,
    switchWebflowPostIdForPreview,
    getConnectedBlogs,
    getOrgPostAutoLikeConfig,
    saveOrgPostAutoLikeConfig,
    initializeTermsResearch,
    getExtractedTermsForKeyword,
    postBulkAction,
    getWebflowReferences,
    saveAuthor,
    getContentStages,
    saveContentStages,
    updateContentStages,
    deleteContentStage,
    getTopicForCalendar,
    saveTopicCalenderData,
    saveContentStagesDeadlines,
    toggleOutlineGenerationAI,
    deleteComment,
    updateComment,
    styleCorrectionRequest,
    getStyleCorrectionRequest,
    resolveCorrectionRequest,
    getAIStyleCorrectionRules,
    getRuleDescriptionAndExamples,
    setDefaultCalendarTime,
    saveWidgetTemplate,
    getWidgetTemplates,
    deleteWidgetTemplate,
    checkIfUserHasSavedPaymentMethod,
    connectToGong,
    connectToSalesCall,
    toggleSendCallAnalysisMail,
    toggleSalesCallAnalysisMail,
    checkUserHavePlan,
    getClientWithBillingPortalSession,
    getPostURLListByPublicationId,
    askQuestion,
    updatePostAccessConfig,
    updateCalendarAccessConfig,
    updateIdeasAccessConfig,
    getSearchWidget,
    setupChatGPT,
    getChannelDetails,
    generateArticle,
    getGeneratedArticle,
    getFrameworkHistory,
    updatePaymentFailedNotification,
    generateSlug,
    generateMetaDescription,
    getGongAnalysisData,
    getGongAskedQuestions,
    getAllAskedQuestions,
    deleteAskedQuestion,
    startDraftGenerationFromThemes,
    getGongAnalysisInsightThemes,
    hideBadSuggestion,
    createdIdeaFromInsight,
    getInternalLinks,
    getUnhealthyLinks,
    publishAllPost,
    publishAllPostForUnhealthyLinks,
    refreshInternalLinks,
    refreshUnhealthyLinks,
    acceptInternalLinks,
    undoAcceptedInternalLink,
    rejectInternalLinks,
    acceptUnhealthyLinks,
    undoAcceptedLinkHealth,
    rejectUnhealthyLinks,
    saveSample,
    getSamples,
    deleteSample,
    saveOutboundTemplate,
    getOutboundTemplates,
    deleteOutboundTemplate,
    saveBuyerFilters,
    getCompanyDetails,
    saveCompanyDetails,
    saveProductCategory,
    getProductCategory,
    getStyleGuideRules,
    saveStyleGuideRules,
    createArticleByOutline,
    getReportEEAT,
    getSuggestionsEEAT,
    getRewrittenArticleWithKeywords,
    getFAQ,
    getScheduledPostDates,
    renameImage,
    getKeywordSuggestion,
    getTLDR,
    generateImageCaptionAndAltText,
    getKeywordSuggestionInHeaders,
    generateSocialPostFromLink,
    generateLinkedinPostFromTopic,
    generateSocialPostFromBlog,
    suggestLinkedinTemplatesFromPost,
    extractSpeakerTagFromTranscript,
    generateCompanyDetails,
    generateLinkedinPostsFromTranscript,
    regenerateLinkedinPostFromTranscript,
    saveInstructions,
    getLinkedInAccounts,
    getKeywordSuggestions,
    initializeTopQuestionsExtraction,
    getTopQuestions,
    initializeInformationGainExtraction,
    getInformationGain,
    generateContentFromTopic,
    generateAnswerForPAA,
    deleteFileAfterTimeout,
    boostLinkedInPost,
    saveSummaryTemplate,
    deleteSummaryTemplate,
    getSummaryTemplates,
    getSummaryTranscripts,
    getSearchIntentResults,
    getAllEngagedAccounts,
    getAllEngagedAccountsGraphData,
    getFeaturedSnippet,
    getAggregatedContentRevenue,
    getTotalContentRevenue,
    getTotalLinkedinContentRevenueFromBQ,
    getEEATQualityRating,
    initializeEEATQualityRating,
    generateMetQualityRating,
    deleteWebflowAccessToken,
    importFromSitemap,
    getMetadataFromURL,
    getPostsByTitleSearchQuery,
    processKeywordsForProgrammaticSEO,
    updateLastUserLogin,
    getSendBlogNewsletterStatus,
    fetchTopPages,
    scrapeTopPages,
    updateClientPreferences,
    getCoveredFaqs,
    getCoveredTablestakes,
    resetGetStarted,
    refreshFindAndReplaceKeywords,
    deleteFindAndReplaceKeywords,
    acceptFindAndReplaceKeywords,
    getFindAndReplaceKeywords,
    publishAllPostForFindAndReplaceKeywords,
    trackSEOUsage,
    trackOutlineGenerationUsage,
    getOutlineGeneration,
    identifyCoveredTablestakes,
    identifyCoveredFAQ,
    updateLinkedInOrgs,
    getSeoFilteredPages,
    saveSeoFilteredPages,
    getKeywordTopPagesDetails,
    saveSecondaryKeywords,
    getErrorNotifications,
    markErrorNotificationAsRead,
    addMarketingCampaign,
    getPublishingDataDetails,
    sendSubjectQuoteRequest,
    saveSubjectQuoteVideoUrl,
    getSubjectQuoteRequest,
    uploadSubjectQuoteVideoToYoutube,
    downloadSubjectQuoteVideo,
    getLinkedInIntentAccounts,
    setEngagedICPFilter,
    getKeywordMonitoringFilters,
    setKeywordMonitoringFilters,
    getKeywordMonitoringPosts,
    setKeywordMonitoringPostsCsvEmails,
    saveAutoDraftOutboundConfigure,
    addToSavedICPFilters,
    getSavedICPFilters,
    deleteFromSavedICPFilters,
    getUserAccountActivities,
    getAccountInfo,
    getPostVisits,
    refreshPAA,
    getPostInfluenceData,
    sendReconnectRequest,
    saveAccountSegment,
    saveContentPathConditions,
    sendBotToMeeting,
    getBotDetails,
    answerQuestionFromSalesCall,
    saveIndexPathConditions,
    getAccountSegments,
    updateLinkedInAccountGroup,
    deleteAccountSegment,
    saveFeature,
    getFeatures,
    getQuestions,
    deleteFeature,
    deleteQuestion,
    toggleQuestionNotifications,
    saveQuestionReportEmails,
    savePersona,
    getPersonas,
    getSalescallEvents,
    deletePersona,
    suggestBuyerTitles,
    saveImageCropSizes,
    savePostOutlineInstruction,
    saveClearbitDetails,
    saveApolloDetails,
    getOutputStats,
    getTopPagesForKeyword,
    getAccountNames,
    addQuestionToReport,
    getAllDealOwners,
    getAllDealStages,
    getConnectedOrganizations,
    getContentIndexed,
    deleteIndexedPage,
    contentIndex,
    updateSalesCallInsights,
    generateSocialPostFromInsight,
    getSocialSellingDetails,
    saveKeywordMonitoringGroups,
    regenerateLinkedInPost,
    saveSocialSellingRoles,
    suggestSocialSellingKeywords,
    saveAccountsForMonitoring,
    generateCommentsForAccountMonitoringPost,
    getAccountMonitoringPostsByGroup,
    commentOnAccountMonitoringPost,
    likeAndConnectAccountMonitoringPost,
    generateMessageForLinkedinDmAccount,
    getLinkedinDmAccountsByGroup,
    scheduleMessageToLinkedinDmAccount,
    updateKeywordMonitoringAccountContactedBy,
    updateContactedBy,
    getDms,
    checkForExistingWorkspace,
    askForWorkspaceInvite,
    createWorkspaceInviteForClient,
    getLinkedInActivityLogForPublication,
    getLinkedInActivityLogForPublicationUsers,
    getLinkedInActivityLogFilterOptions,
    initiateGmeetIntegration,
    createGoogleMeetAccessToken,
    getLinkedInActivityLogFilterOptions,
    addConnectionsToQueue,
    searchLinkedInLocation,
    actOnAccountActivity,
    getAllLinkedinIndustries,
    getUploadedAccountFromAccountMonitoring,
    getAccountMonitoringPosts,
    getAccountMonitoringDetails,
    getExtensionActivityLogs,
    collectLeads,
    getLeadPosts,
    getPostLeads,
    updateAutoCollectLeadsFromKeywords,
    updateCampaignAutoDraftOutlineFlag,
    updateCampaignOutboundConfig,
    saveKeywordMonitoringGroupNotification,
    generateCompanyDescription
  };
};

export default {
  create
};
