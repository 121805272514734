import {
  Autocomplete,
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../Components/styles/react-dates-override.css";
import { shadow, space } from "../Config/theme";
import { TimeFiltersEnum } from "../Containers/KeywordMonitoringPostFilters";
import styles from "../Containers/styles/KeywordMonitoringStyles";
import StyledAvatar from "../design/components/StyledAvatar";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import StyledInputLabel from "../design/components/StyledInputLabel";
import StyledUserChip from "../design/components/StyledUserChip";
import { ReactComponent as CloseIcon } from "../Images/icon24/close.svg";
import { LinkedInHandleAccount } from "../Utils/Types";

export type AccountMonitoringPostFilterModelType = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateType: TimeFiltersEnum;
  ownerFilter: LinkedInHandleAccount[];
};

type AccountMonitoringPostFilterProps = {
  filterModel: AccountMonitoringPostFilterModelType;
  onClose: () => void;
  onChange: (newFilters: AccountMonitoringPostFilterModelType) => void;
  publicationId: string;
  linkedinAccounts: LinkedInHandleAccount[];
  classes: Record<keyof ReturnType<typeof styles>, string>;
};

const AccountMonitoringPostFilters: React.FC<
  AccountMonitoringPostFilterProps
> = ({
  filterModel,
  onClose,
  onChange,
  publicationId,
  linkedinAccounts,
  classes
}) => {
  const [dateType, setDateType] = useState(filterModel.dateType);
  const [dateFilter, setDateFilter] = useState({
    startDate: filterModel.startDate,
    endDate: filterModel.endDate
  });
  const [dateFocusedInput, setDateFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);

  const [ownerFilter, setOwnerFilter] = useState<LinkedInHandleAccount[]>(
    filterModel.ownerFilter
  );

  const onSaveClick = () => {
    onChange({
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      dateType,
      ownerFilter
    });
  };

  const onButtonGroupChange = (filterType: TimeFiltersEnum) => {
    if (filterType === dateType) {
      return;
    }

    let startDate = moment();
    let endDate = moment();

    if (filterType === TimeFiltersEnum.LAST_7_DAYS) {
      startDate = moment().subtract(7, "days");
    } else if (filterType === TimeFiltersEnum.LAST_15_DAYS) {
      startDate = moment().subtract(15, "days");
    } else {
      startDate = moment().subtract(30, "days");
    }

    setDateFilter({ startDate, endDate });
    setDateType(filterType);
  };

  return (
    <Dialog
      fullScreen
      open={true}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          width: 448,
          height: "100%",
          right: 0,
          position: "absolute",
          background: "white"
        }
      }}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          "&.MuiDialogTitle-root": {
            typography: "bodyl",
            padding: space.LARGE,
            display: "flex",
            justifyContent: "space-between"
          }
        }}
      >
        <Typography variant="h400">Filter</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          "&.MuiDialogContent-root": {
            display: "flex",
            flexDirection: "column",
            padding: space.LARGE,
            boxShadow: shadow.MEDIUM,
            maxHeight: "100vh",
            overflow: "scroll"
          }
        }}
      >
        <StyledInputLabel label="Date" />
        <ToggleButtonGroup
          fullWidth={true}
          className={classes.durationPicker}
          value={dateType}
        >
          <ToggleButton
            value={TimeFiltersEnum.LAST_7_DAYS}
            onClick={() => {
              onButtonGroupChange(TimeFiltersEnum.LAST_7_DAYS);
            }}
          >
            7 days
          </ToggleButton>

          <ToggleButton
            value={TimeFiltersEnum.LAST_15_DAYS}
            onClick={() => {
              onButtonGroupChange(TimeFiltersEnum.LAST_15_DAYS);
            }}
          >
            15 days
          </ToggleButton>

          <ToggleButton
            value={TimeFiltersEnum.LAST_30_DAYS}
            onClick={() => {
              onButtonGroupChange(TimeFiltersEnum.LAST_30_DAYS);
            }}
          >
            30 days
          </ToggleButton>
        </ToggleButtonGroup>
        <Box
          sx={{
            position: "relative",
            zIndex: 100,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: space.LARGE
          }}
        >
          <DateRangePicker
            startDate={dateFilter.startDate}
            enableOutsideDays={true}
            startDateId="your_unique_start_date_id_2"
            endDate={dateFilter.endDate}
            endDateId="your_unique_end_date_id_2"
            small={true}
            onDatesChange={({ startDate, endDate }) => {
              if (startDate && endDate) {
                const newStartDate = startDate
                  .set("hours", 0)
                  .set("minutes", 0)
                  .set("seconds", 0);

                const newEndDate = endDate
                  .set("hours", 23)
                  .set("minutes", 59)
                  .set("seconds", 59);

                setDateFilter({
                  startDate: newStartDate,
                  endDate: newEndDate
                });
                setDateType(TimeFiltersEnum.DATE_RANGE);
              }
            }}
            focusedInput={dateFocusedInput}
            onFocusChange={(focusedInput) => {
              setDateFocusedInput(focusedInput);
            }}
            openDirection="down"
            horizontalMargin={70}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={() => false}
          />
        </Box>

        <br />

        <StyledInputLabel label="Owners" />
        <Autocomplete
          value={ownerFilter}
          options={linkedinAccounts}
          multiple
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <StyledAvatar src={option.profilePicture} sx={{ mr: 3 }} />
              <Typography variant="bodym">{option.name}</Typography>
            </Box>
          )}
          isOptionEqualToValue={(option, value) =>
            option.userId === value.userId
          }
          onChange={(e, values) => {
            setOwnerFilter(values || []);
          }}
          renderInput={(params) => (
            <StyledInput {...params} size="small" variant="outlined" />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <StyledUserChip
                label={option.name}
                {...getTagProps({ index })}
                avatar={<Avatar src={option?.profilePicture} />}
              />
            ))
          }
          limitTags={2}
          getOptionLabel={(option) => option.name}
        />
      </DialogContent>
      <DialogActions
        sx={{
          "&.MuiDialogActions-root": {
            px: space.LARGE,
            background: "white"
          }
        }}
      >
        <StyledButton
          size="medium"
          variant="primary"
          onClick={onSaveClick}
          fullWidth
        >
          Save
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AccountMonitoringPostFilters);
