import { faEye as faEyeIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridSortDirection
} from "@mui/x-data-grid-pro";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../Components/styles/react-dates-override.css";
import { AvatarSize, space } from "../Config/theme";
import { AccountMonitoringPost } from "../Containers/GenerateLinkedInComments";
import { TimeFiltersEnum } from "../Containers/KeywordMonitoringPostFilters";
import styles from "../Containers/styles/KeywordMonitoringStyles";
import StyledAvatar from "../design/components/StyledAvatar";
import StyledCheckbox from "../design/components/StyledCheckbox";
import StyledInput from "../design/components/StyledInput";
import StyledPagination from "../design/components/StyledPagination";
import StyledPopper from "../design/components/StyledPopper";
import { ReactComponent as FilterIcon } from "../Images/icon20/filterprojects.svg";
import { ReactComponent as SearchIcon } from "../Images/icon20/search.svg";
import { ReactComponent as CloseIcon } from "../Images/icon24/close.svg";
import Api from "../Services/Api";
import colors from "../Themes/Colors";
import { formatForDisplayDateTime } from "../Utils/TimeUtils";
import { Publication } from "../Utils/Types";
import { convertTextToTitleCase } from "../Utils/UserUtils";
import AccountMonitoringPostFilters, {
  AccountMonitoringPostFilterModelType
} from "./AccountMonitoringPostFilters";
import { notify } from "./CustomNotifications";
import LinkedInPreview from "./LinkedInPreview";

const api = Api.create();

const LINKED_IN_BASE_URL = "https://www.linkedin.com";

enum SortModelEnums {
  POSTED_AT = "postedAt",
  ENGAGEMENT = "engagement"
}

interface AccountMonitoringPostsProps {
  classes: Record<keyof ReturnType<typeof styles>, string>;
  publication: Publication | null;
}

const AccountMonitoringPosts: React.FC<AccountMonitoringPostsProps> = ({
  classes,
  publication
}) => {
  const publicationId = publication?._id || "";

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<AccountMonitoringPost[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const [sortModel, setSortModel] = useState<
    | {
        field: string;
        sort: GridSortDirection;
      }
    | undefined
  >();

  const [searchTerm, setSearchTerm] = useState("");
  const [appliedSearchTerm, setAppliedSearchTerm] = useState("");

  const [showFilterModel, setShowFilterModel] = useState(false);
  const [filterModel, setFilterModel] =
    useState<AccountMonitoringPostFilterModelType>({
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
      dateType: TimeFiltersEnum.LAST_30_DAYS,
      ownerFilter: []
    });

  const [mouseHoverRowId, setMouseHoverRowId] = useState<string | null>(null);
  const [linkedinPreviewPopoverAnchorEl, setLinkedinPreviewPopoverAnchorEl] =
    useState<EventTarget | null>(null);
  const [currentLinkedInPost, setCurrentLinkedInPost] =
    useState<AccountMonitoringPost | null>(null);
  const [isMouseOverPopover, setIsMouseOverPopover] = useState(false);

  useEffect(() => {
    getAccountMonitoringPosts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (page === 0) {
      getAccountMonitoringPosts();
    } else {
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationId, sortModel, appliedSearchTerm, filterModel]);

  const getAccountMonitoringPosts = () => {
    setLoading(true);

    api.getAccountMonitoringPosts(
      publicationId,
      page + 1,
      rowsPerPage,
      sortModel,
      {
        searchTerm: appliedSearchTerm,
        startDate: filterModel.startDate.format(),
        endDate: filterModel.endDate.format(),
        ownerFilter: filterModel.ownerFilter.map((owner) => owner.vanityName)
      },
      (res) => {
        if (res.status === 200) {
          setData(res.data.docs);
          setTotalCount(res.data.totalDocs);
          setLoading(false);
        } else {
          setLoading(false);
          notify.show("Failed to load posts", "error");
        }
      }
    );
  };

  const onSearchChange = () => {
    if (searchTerm === appliedSearchTerm) {
      setSearchTerm("");
      setAppliedSearchTerm("");
    } else {
      setAppliedSearchTerm(searchTerm);
    }
  };

  const handleLinkedinPreviewPopoverOpen = (params: any, event: any) => {
    setCurrentLinkedInPost(params.row);
    setLinkedinPreviewPopoverAnchorEl(event.target);
  };

  const closeLinkedinPreviewPopover = (rowId?: string) => {
    setIsMouseOverPopover((isMouseOver) => {
      if (!isMouseOver) {
        setCurrentLinkedInPost((post) => {
          if (post && rowId && post._id !== rowId) {
            return post;
          }

          setLinkedinPreviewPopoverAnchorEl(null);
          return null;
        });
      }
      return isMouseOver;
    });
  };

  const handleLinkedinPreviewPopoverClose = (params: any, event: any) => {
    setTimeout(() => {
      closeLinkedinPreviewPopover(params.row._id);
    }, 400);
  };

  const handleMouseEnterToPopover = () => {
    setIsMouseOverPopover(true);
  };

  const handleMouseLeaveFromPopover = () => {
    setIsMouseOverPopover(false);
    setLinkedinPreviewPopoverAnchorEl(null);
  };

  const getLinkedInPostPreviewPopover = () => {
    return (
      <StyledPopper
        id="account-monitoring-posts-preview-popover"
        isTransparent={true}
        width={400}
        zIndex={2000}
        placement="right-start"
        open={Boolean(linkedinPreviewPopoverAnchorEl)}
        anchorEl={linkedinPreviewPopoverAnchorEl}
        onMouseEnter={handleMouseEnterToPopover}
        onMouseLeave={handleMouseLeaveFromPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        clickAwayCallback={() => closeLinkedinPreviewPopover()}
        disableRestoreFocus
        body={
          currentLinkedInPost ? (
            <LinkedInPreview
              linkedInPost={{
                accountName: currentLinkedInPost.name,
                accountPicture: currentLinkedInPost.profileImage,
                jobTitle: currentLinkedInPost.jobTitle,
                body: currentLinkedInPost.postContent
              }}
              isTemplatePreview
            />
          ) : (
            <Typography>Post not available</Typography>
          )
        }
      />
    );
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openLinkedInUserEngagement = (vanityName: string) => {
    if (!vanityName) return "#";
    return `${LINKED_IN_BASE_URL}/in/${vanityName}`;
  };

  const getTableColumn = () => {
    const cols: GridColDef<AccountMonitoringPost>[] = [
      {
        field: "name",
        headerName: "User",
        minWidth: 360,
        sortable: false,
        renderCell: (params) => {
          const { row } = params;
          return (
            <Box display="flex" gap={space.SMALL}>
              <StyledAvatar
                src={row.profileImage}
                alt={row.name}
                {...AvatarSize.xs}
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <a
                  href={openLinkedInUserEngagement(row.vanityName)}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.userName}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography
                    variant="bodys"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {row.name || "Unknown"}
                  </Typography>
                </a>
                <Typography
                  variant="bodys"
                  sx={{ textTransform: "capitalize" }}
                >
                  {row.jobTitle || row.headline}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
      {
        field: "companyName",
        headerName: "Company",
        minWidth: 240,
        sortable: false,
        cellClassName: classes.capitalize,
        valueGetter: (params) =>
          convertTextToTitleCase(params.row.companyName || "-")
      },
      {
        field: "View Post",
        headerName: "",
        width: 40,
        sortable: false,
        renderCell: (params) => {
          return (
            <div
              className={classes.viewIcon}
              onMouseEnter={(e) => {
                setMouseHoverRowId(params.row._id);
                handleLinkedinPreviewPopoverOpen(
                  { row: params.row, id: params.row._id } as any,
                  e
                );
              }}
              onMouseLeave={(e) => {
                setMouseHoverRowId(null);
                handleLinkedinPreviewPopoverClose(
                  {
                    row: params.row,
                    id: params.row._id
                  } as any,
                  e
                );
              }}
            >
              <FontAwesomeIcon
                icon={faEyeIcon}
                style={{
                  color:
                    mouseHoverRowId === params.row._id
                      ? colors.primary
                      : colors.secondary,
                  transition: "color 0.2s ease"
                }}
              />
            </div>
          );
        }
      },
      {
        field: SortModelEnums.POSTED_AT,
        headerName: "Posted On",
        width: 120,
        sortable: true,
        valueGetter: ({ row: { postedAt, createdAt } }) =>
          formatForDisplayDateTime(postedAt || createdAt)
      },
      {
        field: "owner",
        headerName: "Owner",
        minWidth: 240,
        sortable: false,
        valueGetter: (params) => (params.row.owner ? params.row.owner : "-")
      },
      {
        field: "liked",
        headerName: "Liked",
        width: 60,
        sortable: false,
        align: "center",
        renderCell: ({ row: { likedOn } }) => {
          return (
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
              display={"flex"}
              justifyContent={"center"}
            >
              <StyledCheckbox
                onChange={() => {}}
                labelStyle={{ margin: 0 }}
                disabled={true}
                checked={Boolean(likedOn)}
              />
            </Box>
          );
        }
      },
      {
        field: "commented",
        headerName: "Commented",
        width: 100,
        sortable: false,
        align: "center",
        renderCell: ({ row: { commentData } }) => {
          return (
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
              display={"flex"}
              justifyContent={"center"}
            >
              <StyledCheckbox
                onChange={() => {}}
                labelStyle={{ margin: 0 }}
                disabled={true}
                checked={Boolean(commentData?.commentedOn)}
              />
            </Box>
          );
        }
      }
    ];

    return cols;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div style={{ display: "flex" }}>
          <StyledInput
            size="medium"
            variant="outlined"
            placeholder="Search person or company"
            value={searchTerm}
            style={{ width: "300px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyUp={(e) => {
              e.key === "Enter" &&
                searchTerm !== appliedSearchTerm &&
                onSearchChange();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="large"
                    sx={{ right: space.XXS, position: "absolute" }}
                    onClick={onSearchChange}
                  >
                    {appliedSearchTerm !== "" &&
                    appliedSearchTerm === searchTerm ? (
                      <CloseIcon />
                    ) : (
                      <SearchIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              sx: { paddingRight: space.L }
            }}
          />
        </div>

        <Box display="flex" alignItems="center">
          <IconButton onClick={() => setShowFilterModel(true)} size="large">
            <FilterIcon />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick
          hideFooterRowCount
          hideFooter
          onRowClick={(params, e) => {
            e.preventDefault();
            window.open(params.row.postUrl, "_blank");
          }}
          disableColumnFilter
          rowHeight={38}
          columnHeaderHeight={38}
          density="comfortable"
          rows={data || []}
          columns={getTableColumn()}
          sortModel={sortModel ? [sortModel] : []}
          onSortModelChange={(model) => {
            setSortModel(model[0]);
          }}
          sortingMode={"server"}
          getRowId={(row) => row._id}
          loading={loading}
          sx={{
            mt: space.MEDIUM,
            fontSize: "12px",
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none"
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none"
              }
          }}
        />
        <StyledPagination
          rowsPerPageOptions={[10, 20, 50]}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Box>

      {getLinkedInPostPreviewPopover()}

      {showFilterModel && (
        <AccountMonitoringPostFilters
          filterModel={filterModel}
          publicationId={publicationId}
          linkedinAccounts={publication?.linkedInHandle?.accounts || []}
          onClose={() => setShowFilterModel(false)}
          onChange={(newFilters) => {
            setFilterModel(newFilters);
            setShowFilterModel(false);
          }}
          classes={classes}
        />
      )}
    </>
  );
};

export default withStyles(styles)(AccountMonitoringPosts);
