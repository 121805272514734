import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material";
import { AxiosResponse } from "axios";
import React from "react";
import StyledInput from "../design/components/StyledInput";
import Api from "../Services/Api";
import { socialSellingRoleTypes } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import StyledChip from "../design/components/StyledChip";

const api = Api.create();

export const permissionsOptions = [
  {
    label: "Support",
    type: socialSellingRoleTypes.SUPPORT,
    isRequired: true,
    description: "Engage by liking, commenting, and posting"
  },
  {
    label: "Create",
    type: socialSellingRoleTypes.CREATE,
    description: "Create thought leadership to attract buyers"
  },
  {
    label: "Prospect",
    type: socialSellingRoleTypes.PROSPECT,
    description: "Connect with buyers and collect leads"
  }
];

export type PermissionOptions = {
  label: string;
  type: string;
  description: string;
};

interface SocialSellingRolesProps {
  value?: string[];
  vanityName: string;
  publicationId: string;
  onChange: (roles: string[]) => void;
}

interface SocialSellingRolesState {
  selectedRoles: PermissionOptions[];
}

class SocialSellingRoles extends React.Component<
  SocialSellingRolesProps,
  SocialSellingRolesState
> {
  constructor(props: SocialSellingRolesProps) {
    super(props);
    this.state = {
      selectedRoles:
        permissionsOptions.filter((option) => {
          return props.value?.includes(option.type);
        }) || []
    };
  }

  onRoleChange = (_: unknown, newRoles: PermissionOptions[]) => {
    if (!newRoles.length) {
      newRoles = permissionsOptions.filter((option) => option.isRequired);
    }

    this.setState(
      {
        selectedRoles: newRoles
      },
      () => {
        this.saveSocialSellingRoles();
      }
    );
  };

  saveSocialSellingRoles = () => {
    let { selectedRoles } = this.state;
    let { vanityName, publicationId } = this.props;

    let roles = selectedRoles.map((role: PermissionOptions) => role.type);

    api.saveSocialSellingRoles(
      "",
      vanityName,
      publicationId,
      roles,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.props.onChange(roles);
        } else {
          notify.show("Oops, we were unable to save.", "error");
        }
      }
    );
  };

  render() {
    const { selectedRoles } = this.state;

    return (
      <div style={{ verticalAlign: "middle", width: "100%" }}>
        <Autocomplete
          style={{ alignSelf: "stretch", width: "100%" }}
          multiple
          fullWidth
          sx={{
            "& .MuiAutocomplete-inputRoot": {
              padding: 0
            }
          }}
          limitTags={3}
          value={selectedRoles}
          onChange={this.onRoleChange}
          isOptionEqualToValue={(
            option: PermissionOptions,
            value: PermissionOptions
          ) => {
            return option.type === value.type;
          }}
          options={permissionsOptions}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <StyledInput {...params} size="medium" />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <StyledChip
                  key={key}
                  label={option.label}
                  {...tagProps}
                  disabled={option.isRequired || false}
                />
              );
            })
          }
        />
      </div>
    );
  }
}

export default SocialSellingRoles;
