// @ts-nocheck
import moment from "moment";
import momentTimeZone from "moment-timezone";
import momentBusinessdays from "moment-business-days";
import { handleSingular } from "../Utils/UserUtils";
export function formatForDisplayDate(date: any) {
  if (date) {
    return moment(date).format("MMM Do YYYY");
  } else {
    return "No date";
  }
}

export function formatForDisplayDateWithTimeZone(date: any) {
  if (date) {
    return momentTimeZone(date).format("MMM Do YYYY");
  } else {
    return "No date";
  }
}

export function formatForTimeWithTimeZone(date: any, timezone: string = "") {
  try {
    var timeZoneArea = timezone || momentTimeZone.tz.guess();

    return momentTimeZone(date)
      .tz(timeZoneArea)
      .format("MMM Do YYYY [at] h:mm a z");
  } catch (error) {
    // If any issue occurs, retry with the default timezone
    if (timezone) {
      return formatForTimeWithTimeZone(date);
    }

    return date;
  }
}

export function getCurrentBrowserTimezone() {
  return momentTimeZone.tz.guess();
}

export function formatTimeWithTimeZoneForEventInfo(date: any) {
  var timeZoneArea = momentTimeZone.tz.guess();

  var timeZone = momentTimeZone.tz.zone(timeZoneArea).abbr(date);
  return momentTimeZone(date).format("h:mm a [" + timeZone + "] MMM Do YYYY");
}

export function formatDateWithTimeZoneForEventInfo(date: any) {
  return momentTimeZone(date).format("YYYY-MM-DD");
}

export function formatForDisplayDateTime(date: any) {
  return moment(date).format("MMM Do YYYY");
}

export function formatDurationInSeconds(duration: any) {
  let d = Number(duration);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
}

export function formatForDisplayDateTimeString(date: any) {
  return moment(date).format("h:mm A [on] MMM Do YYYY");
}

export function formatForDisplayDateTimeStringWithTimezone(
  date: any,
  timezone: string = ""
) {
  timezone = timezone || momentTimeZone.tz.guess();
  return moment(date).tz(timezone).format("h:mm A [on] MMM Do YYYY z");
}

export function formatForGoogleThumbnailPreview(date: any) {
  return moment(date).format("MMM DD, YYYY");
}

export function formatForHistoryPanel(date: any) {
  return moment(date).format("MMM Do YYYY hh:mm a");
}

export function getHoursFrom(hours: any, date: any) {
  return moment(date).add(hours, "hours").toDate();
}

export function getMinutesFrom(minutes: any, date: any) {
  return moment(date).add(minutes, "minutes").toDate();
}

export function isFuture(date: any) {
  return moment().isBefore(date);
}

export function getTomorrow() {
  return moment().add(24, "hours");
}

export function getToday() {
  return moment().format("MMM Do YYYY");
}

export function getDisplayDate(date: any, timezone = "UTC") {
  return momentTimeZone(date).tz(timezone).format("MMM Do YYYY");
}

export function formatTime(time: any) {
  if (time) {
    return moment(time).format("h:mm a");
  }
  return moment().format("h:mm a");
}

export function getRelativeTime(date: any) {
  return moment(date).fromNow();
}

export function formatDate(date: any) {
  return moment(date).format("h:mm a MMM Do");
}

export function formatDateWithYear(date: any) {
  return moment(date).format("h:mm a MMM Do YYYY");
}

export function formatDateWithOnlyYear(date: any) {
  return moment(date).format("MMM Do YYYY");
}

export function getDifferenceFromNow(date: any) {
  return moment().diff(moment(date), "days");
}

export function getDaysDifference(date: any) {
  return moment(date).diff(moment(), "days");
}

export function getMinutesDifference(date: any) {
  return moment(date).diff(moment(), "minutes");
}

export function getTimeLeftInDaysHoursMinutes(minutes: any) {
  // return moment.utc(moment.duration(minutes, "minutes").asMilliseconds()).format('D [Days, ]HH[ Hours and ]mm[ minutes]')
  let duration = moment.duration(minutes, "minutes");

  let daysLeft = minutes / 1440;
  if (daysLeft >= 1) {
    daysLeft = Math.round(daysLeft);
    return daysLeft + (daysLeft === 1 ? " day" : " days");
  }
  let hoursLeft = (minutes / 60) % 24;
  if (hoursLeft >= 1) {
    hoursLeft = Math.round(hoursLeft);
    return hoursLeft + (hoursLeft === 1 ? " hour" : " hours");
  }
  let minutesLeft = Math.ceil(minutes % 60);
  return minutesLeft + (minutesLeft === 1 ? " minute" : " minutes");
}

export function getTimelineValue(timestamp: any) {
  let pastTime = moment(timestamp);
  let currentTime = moment().startOf("day");
  let yesterday = moment().subtract(1, "days").startOf("day");
  var daysLeft = currentTime.diff(pastTime, "days");
  if (pastTime.isSame(yesterday, "d")) {
    return "Yesterday";
  } else if (daysLeft >= 1) {
    daysLeft = Math.round(daysLeft) + 1;
    return daysLeft + " days ago";
  }
  return pastTime.format("h:mm a");
  /* let hoursLeft = currentTime.diff(pastTime, "hours");
  if (hoursLeft >= 1) {
    hoursLeft = Math.round(hoursLeft);
    return hoursLeft + " hour" + handleSingular(hoursLeft) + " ago";
  }
  let minutesLeft = currentTime.diff(pastTime, "minutes");
  return minutesLeft + " minute" + handleSingular(minutesLeft) + " ago";*/
}

export function isAfterNow(date: any) {
  return moment(date).isAfter(moment.now());
}

export function reduceMonths(date: any, months: any) {
  var newDate = moment(date);
  return newDate.subtract(months, "M");
}

export function reduceDays(date: any, days: any) {
  let newDate = moment(date);
  return newDate.subtract(days, "d");
}

export function reduceBusinessDays(date: any, days: any) {
  return momentBusinessdays(date).businessSubtract(days)._d;
}

export function addBusinessDays(date: any, days: any) {
  return momentBusinessdays(date).businessAdd(days)._d;
}

export function getDay(date: any) {
  return moment(date).format("Do");
}

export function getDateAndMonth(date: any) {
  return moment(date).format("MMM Do");
}

export function getDayOfWeek(date: any) {
  return moment(date).weekday();
}

export function subtractDays(date: any, days: any) {
  return moment(date).subtract(days).toDate();
}

export function formatTimeForCalendar(date: any) {
  return moment(date).format("hh:mm a");
}

// non-empty abbreviations that do not begin with -/+
const ABBR_REGEX = /^[^-+]/;

export function getTimezoneMetadata(timezone: any, date = new Date()) {
  const timestamp = date.getTime();
  const zone = momentTimeZone.tz.zone(timezone);
  const zonedDate = momentTimeZone.tz(timestamp, timezone);
  const offset = zonedDate.utcOffset();
  const offsetAsString = zonedDate.format("Z");

  const abbr = zone.abbr(timestamp);
  const abbreviation = ABBR_REGEX.test(abbr) ? abbr : undefined;

  return {
    abbreviation,
    offset,
    offsetAsString,

    population: zone.population,
    timezone
  };
}

export const TimezoneDisplayFormat = {
  /** Abbreviation format: `"HST"` */
  ABBREVIATION: "abbreviation",
  /** Composite format: `"Pacific/Honolulu (HST) -10:00"` */
  COMPOSITE: "composite",
  /** Name format: `"Pacific/Honolulu"` */
  NAME: "name",
  /** Offset format: `"-10:00"` */
  OFFSET: "offset"
};

export function formatTimezone(timezone: any, displayFormat: any) {
  if (!timezone || !momentTimeZone.tz.zone(timezone)) {
    return undefined;
  }

  const { abbreviation, offsetAsString } = getTimezoneMetadata(timezone);
  switch (displayFormat) {
    case TimezoneDisplayFormat.ABBREVIATION:
      return abbreviation !== undefined ? abbreviation : offsetAsString;
    case TimezoneDisplayFormat.NAME:
      return timezone;
    case TimezoneDisplayFormat.OFFSET:
      return offsetAsString;
    case TimezoneDisplayFormat.COMPOSITE:
      return `${timezone}${
        abbreviation ? ` (${abbreviation})` : ""
      } ${offsetAsString}`;
  }
}

/**
 * Get a list of all timezone items.
 *
 * @param date the date to use when determining timezone offsets
 */
export function getTimezoneItems(date: any) {
  return momentTimeZone.tz
    .names()
    .map((timezone) => getTimezoneMetadata(timezone, date))
    .sort((a, b) => a.offset - b.offset)
    .map(toTimezoneItem);
}

export function getInitialTimezoneItems(date: any, includeLocalTimezone: any) {
  const populous = getPopulousTimezoneItems(date);
  const local = getLocalTimezoneItem(date);
  return includeLocalTimezone && local !== undefined
    ? [local, ...populous]
    : populous;
}

export function getLocalTimezoneItem(date: any) {
  const timezone = momentTimeZone.tz.guess();
  if (timezone !== undefined) {
    const timestamp = date.getTime();
    const zonedDate = momentTimeZone.tz(timestamp, timezone);
    const offsetAsString = zonedDate.format("Z");
    return {
      iconName: "locate",
      key: `${timezone}-local`,
      label: offsetAsString,
      text: "Current timezone",
      timezone
    };
  } else {
    return undefined;
  }
}

export function getPopulousTimezoneItems(date: any) {
  const timezones = momentTimeZone.tz
    .names()
    .filter((timezone) => /\//.test(timezone) && !/Etc\//.test(timezone));

  const timezoneToMetadata = timezones.reduce((store, zone) => {
    store[zone] = getTimezoneMetadata(zone, date);
    return store;
  }, {});

  const maxPopPerOffset = timezones.reduce((maxPop, zone) => {
    const data = timezoneToMetadata[zone];

    const currentMax = maxPop[data.offsetAsString];
    if (
      currentMax == null ||
      data.population > timezoneToMetadata[currentMax].population
    ) {
      maxPop[data.offsetAsString] = zone;
    }
    return maxPop;
  }, {});
  return Object.keys(maxPopPerOffset)

    .map((k) => timezoneToMetadata[maxPopPerOffset[k]])
    .sort((tz1, tz2) => tz1.offset - tz2.offset)
    .map(toTimezoneItem);
}

function toTimezoneItem({ abbreviation, offsetAsString, timezone }: any) {
  return {
    key: timezone,
    label: offsetAsString,
    text: timezone + (abbreviation ? ` (${abbreviation})` : ""),
    timezone
  };
}

export function isSameDate(date1: any, date2: any) {
  date1 = moment(date1).format("YYYY-MM-DD");
  date2 = moment(date2).format("YYYY-MM-DD");
  return date1 === date2;
}

export function isPastDate(date: any) {
  return moment(date) < moment();
}

export function isAfter(date1: any, date2: any) {
  return moment(date1).isAfter(moment(date2));
}

export function isBefore(date1: any, date2: any) {
  return moment(date1).isBefore(moment(date2));
}

export function getTimestampByTimezone(date: any, timeZoneArea: any) {
  timeZoneArea = timeZoneArea || momentTimeZone.tz.guess();

  var timeZone = momentTimeZone.tz.zone(timeZoneArea).abbr(date);

  return `${moment(date)
    .tz(timeZoneArea)
    .format("MMM Do YYYY [at] h:mm a")} ${timeZone}`;
}

export function convertSecondsToXmYs(seconds: any) {
  try {
    const duration = moment.duration(seconds, "seconds");
    const minutes = duration.minutes();
    const remainingSeconds = duration.seconds();
    if (!minutes) {
      return `${remainingSeconds} seconds`;
    }
    return `${minutes} minutes ${remainingSeconds} seconds`;
  } catch (error) {
    console.log(`Error in convertSecondsToXmYs for seconds ${seconds}`, error);
    return `0 seconds`;
  }
}

export function convertTimestampToSeconds(timestamp: string) {
  // Check the format of the timestamp
  const parts = timestamp.split(":");

  let seconds = 0;

  if (parts.length === 2) {
    // Format is MM:SS
    const minutes = parseInt(parts[0], 10);
    const secs = parseInt(parts[1], 10);
    seconds = minutes * 60 + secs;
  } else if (parts.length === 3) {
    // Format is HH:MM:SS
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const secs = parseInt(parts[2], 10);
    seconds = hours * 3600 + minutes * 60 + secs;
  } else {
    console.error("Invalid timestamp format");
  }

  return seconds;
}

export function generateObjectIdFromCurrentTime() {
  // Get the current timestamp in seconds since the Unix epoch
  const timestamp = Math.floor(new Date().getTime() / 1000);

  // Convert the timestamp to a hexadecimal string
  const timestampHex = timestamp.toString(16);

  // Create a 4-byte hexadecimal string representing the machine identifier (you can use a random value for this)
  const machineIdentifier = "00000000";

  // Create a 5-byte hexadecimal string representing the process identifier (you can use a random value for this)
  const processIdentifier = "00000";

  // Create a 3-byte hexadecimal string representing a random value
  const randomValue = Math.floor(Math.random() * 16777215).toString(16);

  // Concatenate the components to form the ObjectId
  return timestampHex + machineIdentifier + processIdentifier + randomValue;
}

export function getNotificationTimeFormat(time: stirng) {
  const currentTime = moment();
  const incomingTime = moment(time);
  const hoursDiff = currentTime.diff(incomingTime, "hours");
  if (hoursDiff < 24) {
    return getRelativeTime(time);
  }
  return formatDate(time);
}
