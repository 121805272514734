import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";
import { space } from "../../Config/theme";
import colors, { designColors } from "../../Themes/Colors";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      color: colors.fontPrimary,
      overflowX: "hidden"
    },
    innerContainer: {
      paddingBottom: 80,
      color: colors.fontPrimary
    },
    cardSubtitle: {
      color: colors.fontSecondary
    },
    userName: {
      color: colors.userName,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      }
    },
    capitalize: {
      textTransform: "capitalize"
    },
    viewIcon: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
      "& svg": {
        transition: "color 0.2s ease",
        color: colors.secondary,
        "&:hover": {
          color: colors.primary
        }
      }
    },
    buttonGroupStyle: {
      display: "flex",
      flexDirection: "row",
      marginBottom: space.LARGE,
      alignItems: "center",
      marginTop: space.MEDIUM
    },
    durationPicker: {
      "& .MuiButton-outlinedPrimary": {
        color: colors.statsDatePickerOutlineColor,
        border: `1px solid ${colors.statsDatePickerOutlineBorder}`
      },
      "& .MuiButton-containedPrimary": {
        backgroundColor: colors.statsDatePickerOutlineColor
      }
    },
    datePickerBarText: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "16px",
      margin: `0px ${space.MEDIUM}`
    },
    popper: {
      bottom: 0,
      left: "55% !important",
      width: "50%",
      top: "unset !important",
      zIndex: 1,
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("md")]: {
        left: "30% !important",
        width: "70%",
        transform: "translate(-30%, -30%)"
      }
    },
    bulkActionPaper: {
      padding: "10px 16px",
      borderRadius: 6,
      background: designColors.primary[50],
      color: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }
  });
export default styles;
