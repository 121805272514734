import React from "react";
import {
  Grid,
  Typography,
  Divider,
  Button,
  InputBase,
  IconButton
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { StyledDialog, StyledButton } from '../design/components/StyledDialog';
import CopyToClipboard from "react-copy-to-clipboard";
import { MeetingToolIntegrationEnum } from "../Utils/Types";
import { space } from "../Config/theme";
import StyledDialog from "../design/components/StyledDialog";
import StyledButton from "../design/components/StyledButton";
import colors from "../Themes/Colors";
import StyledSwitch from "../design/components/StyledSwitch";
import { FileCopy } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

interface MeetingToolAccountsDialogProps {
  open: boolean;
  meetingToolAccountType: MeetingToolIntegrationEnum;
  meetingToolIntegrations: any;
  MEETING_TOOL_NAMES: Record<string, string>;
  classes: any;
  getAvatar: (accountType: string, account: any) => React.ReactNode;
  disconnectGmeetAccount: (email: string) => void;
  handleGoogleMeetSwitchChange: () => void;
  isEnabled: boolean;
  gmeetExternalConnectUrl: string;
  onClose: () => void;
}

const MeetingToolAccountsDialog: React.FC<MeetingToolAccountsDialogProps> = ({
  open,
  meetingToolAccountType,
  meetingToolIntegrations,
  MEETING_TOOL_NAMES,
  classes,
  getAvatar,
  disconnectGmeetAccount,
  handleGoogleMeetSwitchChange,
  isEnabled,
  gmeetExternalConnectUrl,
  onClose
}) => {
  return (
    <StyledDialog
      open={open}
      maxWidth="md"
      title={
        <Typography variant="h400">
          Connected {MEETING_TOOL_NAMES[meetingToolAccountType]} Accounts
        </Typography>
      }
      body={
        <Grid container direction="row" justifyContent="space-between">
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="bodym">
              Ask individual content creation users to connect Google Meet
            </Typography>
            <StyledSwitch
              checked={isEnabled}
              onChange={handleGoogleMeetSwitchChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider
              className={classes.marginTop20}
              style={{ borderColor: colors.horizontalRule }}
            />
          </Grid>

          <Grid container direction="column">
            {meetingToolIntegrations &&
              meetingToolIntegrations[meetingToolAccountType]?.accounts?.map(
                (account: any) => (
                  <Grid
                    container
                    key={account.clientId}
                    direction="row"
                    className={classes.connectedAccountsContainer}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item sm={9} className={classes.accountNameContainer}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: space.SMALL
                        }}
                      >
                        {getAvatar(MeetingToolIntegrationEnum.GMEET, account)}
                        <Typography variant="bodym">{account.email}</Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      sm={3}
                      style={{ display: "flex" }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => disconnectGmeetAccount(account.email)}
                        disabled={!isEnabled}
                      >
                        Disconnect
                      </Button>
                    </Grid>
                  </Grid>
                )
              )}
          </Grid>
          {meetingToolAccountType === MeetingToolIntegrationEnum.GMEET && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 20
              }}
            >
              <Grid item xs={12}>
                <Divider
                  style={{
                    marginBottom: space.SMALL
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h200" paragraph>
                  Connection Link
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="bodym" paragraph>
                  Share this link with your teammates whose recorded calls you
                  want to process for content generation
                </Typography>
              </Grid>

              <Grid container xs={12} alignItems="center">
                <Grid item xs={11}>
                  <InputBase
                    style={{
                      backgroundColor: grey[100],
                      color: colors.fontSecondary,
                      borderRadius: 5,
                      padding: space.SMALL,
                      fontFamily: "Inconsolata",
                      width: "100%"
                    }}
                    value={gmeetExternalConnectUrl}
                    contentEditable={false}
                    onFocus={(event) => event.target.select()}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={1} style={{ paddingLeft: space.XXS }}>
                  <CopyToClipboard onCopy={true} text={gmeetExternalConnectUrl}>
                    <IconButton size="large">
                      <FileCopy />
                    </IconButton>
                  </CopyToClipboard>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
      closeCallback={onClose}
    />
  );
};

export default MeetingToolAccountsDialog;
