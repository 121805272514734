// @ts-nocheck
// a library to wrap and simplify api calls
import apisauce from "./apisauce";
import firebase from "firebase/app";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

// our "constructor"
const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": SERVER_URL,
      "Access-Control-Allow-Methods": "GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    // 10 second timeout...
    timeout: 180000
  });

  const createApiCall = async (endpoint: any, params: any, callback: any) => {
    params["idToken"] =
      (await firebase.auth().currentUser?.getIdToken()) || null;
    api.post("/collaborationcomments/" + endpoint, params).then((res) => {
      callback(res);
    });
  };

  const addCollaboratorComment = (
    entity: any,
    text: any,
    isReply: any,
    commentId: any,
    selectedText: any,
    timezone: any,
    files: any,
    status: any,
    id: any,
    callback: any
  ) => {
    createApiCall(
      "addcollaboratorcomment",
      {
        entity,
        text,
        isReply,
        commentId,
        selectedText,
        timezone,
        files,
        status,
        id
      },
      callback
    );
  };

  const getCollaboratorComment = (
    entity: any,
    commentId: any,
    callback: any
  ) => {
    createApiCall("getcollaboratorcomment", { entity, commentId }, callback);
  };

  const resolveCollaboratorComment = (
    entity: any,
    commentId: any,
    callback: any
  ) => {
    createApiCall(
      "resolvecollaboratorcomment",
      { entity, commentId },
      callback
    );
  };

  const markCommentActive = (entity: any, commentId: any, callback: any) => {
    createApiCall("markcommentactive", { entity, commentId }, callback);
  };

  const getAllComments = (entity: any, callback: any) => {
    createApiCall(
      "getallcomments",
      {
        entity
      },
      callback
    );
  };

  const addCollaboratorLoomVideo = (
    entityId: any,
    loomTempId: any,
    loomURL: any,
    selectedText: any,
    timezone: any,
    callback: any
  ) => {
    createApiCall(
      "addcollaboratorloomvideo",
      { entityId, loomTempId, loomURL, selectedText, timezone },
      callback
    );
  };

  const deleteComment = (commentId: any, callback: any) => {
    createApiCall("deletecomment", { commentId }, callback);
  };

  const updateComment = (
    commentId: any,
    commentText: any,
    files: any,
    status: any,
    callback: any
  ) => {
    createApiCall(
      "updateComment",
      { commentId, commentText, files, status },
      callback
    );
  };

  const deleteEmptyComment = (
    commentId: string,
    callback: (res: AxiosResponse) => void
  ) => {
    createApiCall("deleteemptycomment", { commentId }, callback);
  };

  return {
    addCollaboratorComment,
    getCollaboratorComment,
    resolveCollaboratorComment,
    getAllComments,
    markCommentActive,
    addCollaboratorLoomVideo,
    deleteComment,
    updateComment,
    deleteEmptyComment
  };
};

export default {
  create
};
