// @ts-nocheck
import SwitchUnstyled, {
  switchUnstyledClasses
} from "@mui/core/SwitchUnstyled";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/system";
import * as React from "react";
import { designColors } from "../../Themes/Colors";

const switchSizes = {
  small: {
    width: 34,
    height: 22,
    thumb: 12,
    transform: 12,
    padding: 3,
    margin: 7
  },
  medium: {
    width: 42,
    height: 28,
    thumb: 16,
    transform: 16,
    padding: 4,
    margin: 6
  },
  large: {
    width: 52,
    height: 32,
    thumb: 20,
    transform: 20,
    padding: 4,
    margin: 6
  }
};

const Root = styled("span")(({ size }) => {
  let { root, disabled, track, thumb, focusVisible, checked, input } =
    switchUnstyledClasses;

  let switchSize = switchSizes[size] || switchSizes.medium;
  let transformPixel =
    switchSize.width - switchSize.thumb - switchSize.padding * 2 - 4;

  return {
    ["&." + root]: {
      fontSize: 0,
      boxSizing: "border-box",
      position: "relative",
      width: switchSize.width,
      height: switchSize.height,
      minWidth: switchSize.width,
      minHeight: switchSize.height,
      margin: `${switchSize.margin}px`,
      padding: `${switchSize.padding}px`,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        background: designColors.primary[0],
        borderRadius: "20px"
      },
      ["&." + disabled]: {
        background: "transparent",
        borderRadius: "20px"
      }
    },
    ["&." + disabled]: {
      ["." + track]: {
        background: designColors.grayScale[5]
      },
      ["." + thumb]: {
        background: designColors.grayScale[60]
      }
    },
    ["& ." + track]: {
      background: designColors.grayScale[80],
      borderRadius: "20px",
      display: "block",
      height: `calc(100% - ${switchSize.padding * 2}px)`,
      width: `calc(100% - ${switchSize.padding * 2}px)`,
      position: "absolute"
    },
    ["& ." + thumb]: {
      display: "block",
      width: switchSize.thumb,
      height: switchSize.thumb,
      left: 2,
      borderRadius: "50%",
      backgroundColor: designColors.white,
      position: "relative",
      transition: "all 200ms ease"
    },
    ["&." + focusVisible + "." + thumb]: {
      backgroundColor: designColors.primary[100]
    },
    ["&." + checked]: {
      ["." + thumb]: {
        transform: `translateX(${transformPixel}px)`,
        backgroundColor: designColors.white
      },
      ["." + track]: {
        background: designColors.primary[100]
      },
      ["&." + disabled]: {
        ["." + track]: {
          background: designColors.primary[10]
        }
      }
    },
    ["& ." + input]: {
      cursor: "inherit",
      position: "absolute",
      width: `calc(100% - ${switchSize.padding}px)`,
      height: `calc(100% - ${switchSize.padding}px)`,
      top: 0,
      left: 0,
      opacity: 0,
      zIndex: 1,
      margin: 0
    }
  };
});

/**
 *
 * @prop {Object} labelStyle - Override label style
 * @prop {Object} containerStyle - Override form control style
 */
export default function StyledSwitch({
  labelStyle,
  label = "",
  name = "",
  containerStyle = {},
  labelPlacement = "start",
  ...props
}: any) {
  return (
    <FormControlLabel
      control={
        <SwitchUnstyled
          component={Root}
          name={"sample name"}
          {...props}
          slotProps={{ input: { name } }}
        />
      }
      labelPlacement={labelPlacement}
      label={label}
      sx={{
        "&.MuiFormControlLabel-root": {
          margin: 0
        },
        "&.MuiFormControlLabel-root .MuiFormControlLabel-label": labelStyle || {
          marginRight: label ? "75px" : "0px"
        }
      }}
      style={containerStyle}
    />
  );
}
